import React, {Component} from 'react';
import {withNavigation} from "../../../../utils/WithPlusClass";
import PageTitle from "../../../../components/title/PageTitle";

import defaultAvatar from "../../../../asset/defaultDepartmentLogo.svg"

import styles from "./index.module.css"
import EdgeBorder from "../../../../components/box/EdgeBorder";
import {message} from "antd";
import AccountApi from "../../../../api/account/AccountApi";
import RenderEmptyData from "../../../../components/empty/RenderEmptyData";
import OrganizationApi from "../../../../api/organization/OrganizationApi";
import WorkorderApi from "../../../../api/workorder/WorkorderApi";

/**
 * @author       : AngelBeats
 * @date         : 2023-03-15 16:03:28
 * @description  : 机构详情页面
 */
class DepartmentDetail extends Component {

    state = {
        departmentAccountList: [],
        organizationDetail: {},
        organizationAccountCount: "-",
        organizationFinishWorkorderCount: "-",
        organizationOnlineAccountCount: "-",
        organizationAvgScore: "-"
    }

    componentDidMount() {
        if (this.props?.location?.state?.departmentId === undefined || !this.props?.location?.state?.departmentId) {
            message.error("无单位ID!")
            return;
        }
        this.requestDepartmentAllAccount();
        this.requestOrganizationDetail();
        this.requestOrganizationAccountCount();
        this.requestOrganizationFinishWorkorderCount();
        this.requestAllAccount();
        this.requestOrganizationAvgScore();
    }

    /**
     * 跳转工程师详情
     * @param item
     */
    toAccountDetail = (item) => {
        this.props.navigate("/home/engineer-detail", {state: {accountId: item.id}})
    }

    /**
     * 获取单位下的所有用户
     */
    requestDepartmentAllAccount = () => {
        AccountApi.requestDepartmentAllAccount(this.props.location.state.departmentId).then(async (res) => {
            if (res.data.length > 0) {
                let accountIds = res.data.map(item => item.id).toString();
                res.data.forEach(item => {
                    item["workorderCount"] = 0;
                    item["point"] = 0;
                })
                await this.requestAccountWorkorderNumber(accountIds, res.data);
                await this.requestAccountPoint(accountIds, res.data);
                this.setState({departmentAccountList: res.data});
            }

        }).catch(err => {
            console.error("request department all account failed", err)
        })
    }

    /**
     * 批量查询用户工单数
     * @param accountIds
     * @param accountData
     */
    requestAccountWorkorderNumber = async (accountIds, accountData) => {
        const {data} = await WorkorderApi.findBatchWorkorderCount({userIds: accountIds}).catch(err => {
            console.error("request account workorder number failed", err)
        })
        accountData.forEach(account => {
            data.forEach(item => {
                if (account.id === item.name) {
                    account.workorderCount = item.value
                }
            })
        })
    }

    /**
     * 批量查询用户积分
     * @param accountIds
     * @param accountData
     */
    requestAccountPoint = async (accountIds, accountData) => {
        const {data} = await AccountApi.findBatchAccountPoint({userIds: accountIds}).catch(err => {
            console.error("request Account point failed", err)
        })
        accountData.forEach(account => {
            data.forEach(item => {
                if (account.id === item.name) {
                    account.point = item.value
                }
            })
        })
    }

    /**
     * 查询机构详情
     */
    requestOrganizationDetail = () => {
        OrganizationApi.findOrganizationDetail({id: this.props.location.state.departmentId}).then(res => {
            this.setState({organizationDetail: res.data})
        }).catch(err => {
            console.error("request department detail failed", err)
        })
    }

    /**
     * 查询机构账户数量
     */
    requestOrganizationAccountCount = () => {
        AccountApi.findAccountNumber({departmentId: this.props.location.state.departmentId}).then(res => {
            this.setState({organizationAccountCount: res.data})
        }).catch(err => {
            console.error("request organization count failed", err)
        })
    }

    /**
     * 请求机构完成工单数量
     */
    requestOrganizationFinishWorkorderCount = () => {
        WorkorderApi.findWorkorderNumber({
            handlerDepartmentId: this.props.location.state.departmentId,
            progress: 17
        }).then(res => {
            this.setState({organizationFinishWorkorderCount: res.data})
        }).catch(err => {
            console.error("request organization finish workorder count failed", err)
        })
    }

    /**
     * 请求机构下所有用户 并求出在线用户数量
     */
    requestAllAccount = () => {
        AccountApi.findAllAccount({departmentId: this.props.location.state.departmentId}).then(res => {
            this.setState({organizationOnlineAccountCount: res.data.filter(item => item.situation !== 0).length})
        }).catch(err => {
            console.error("request all account failed", err)
        })
    }

    /**
     * 查询机构平均积分
     */
    requestOrganizationAvgScore = () => {
        OrganizationApi.findOrganizationAvgScore(this.props.location.state.departmentId).then(res => {
            this.setState({organizationAvgScore: res.data})
        }).catch(err => {
            console.error("request organization avg score failed", err)
        })
    }

    /**
     * 补齐盒子 使布局平滑
     */
    fillUpBox = () => {
        if (this.state.departmentAccountList.length % 4 === 1) {
            return <>
                <div className={styles.fill_box}/>
                <div className={styles.fill_box}/>
                <div className={styles.fill_box}/>
            </>
        }
        if (this.state.departmentAccountList.length % 4 === 2) {
            return <>
                <div className={styles.fill_box}/>
                <div className={styles.fill_box}/>
            </>
        }
        if (this.state.departmentAccountList.length % 4 === 3) {
            return <div className={styles.fill_box}/>
        }
    }

    render() {
        const {
            organizationDetail,
            organizationAccountCount,
            organizationFinishWorkorderCount,
            organizationOnlineAccountCount,
            organizationAvgScore
        } = this.state

        return (<div className={styles.department_detail_page}>
            <PageTitle title="赣州市信创数字化区域监控中心" backIcon/>
            <div className={styles.department_detail_content}>
                <div className={styles.department_info_box}>
                    <div className={styles.department_logo_name}>
                        <img src={organizationDetail?.logo ?? defaultAvatar} alt="" className={styles.department_logo}/>
                        <span className={styles.department_name}>{organizationDetail?.name}</span>
                    </div>
                    <div className={styles.department_index_box}>
                        <span className={styles.department_index_value}>
                            <img src={require("../../../../asset/department/detail/department_detail_area_icon.png")}
                                 alt=""
                                 className={styles.department_index_icon}/>
                            所在区域：{organizationDetail?.province + "-" + organizationDetail?.city + "-" + organizationDetail?.county}
                        </span>
                        <span className={styles.department_index_value}>
                            <img src={require("../../../../asset/department/detail/engineer_num_icon.png")} alt=""
                                 className={styles.department_index_icon}/>
                            工程师数量：{organizationAccountCount}/位
                        </span>
                        <span className={styles.department_index_value}>
                            <img
                                src={require("../../../../asset/department/detail/department_detail_workorder_icon.png")}
                                alt=""
                                className={styles.department_index_icon}/>
                            已完成服务工单：{organizationFinishWorkorderCount}
                        </span>
                        <span className={styles.department_index_value}>
                            <img src={require("../../../../asset/department/detail/department_detail_score_icon.png")}
                                 alt=""
                                 className={styles.department_index_icon}/>
                            工程师平均积分：{organizationAvgScore}
                        </span>
                        <span className={styles.department_index_value}>
                            <img src={require("../../../../asset/department/detail/department_engineer_icon.png")}
                                 alt=""
                                 className={styles.department_index_icon}/>
                            当前在线工程师：{organizationOnlineAccountCount}/位
                        </span>
                        <span className={styles.department_index_value}>
                            <img src={require("../../../../asset/department/detail/department_detail_address_icon.png")}
                                 alt=""
                                 className={styles.department_index_icon}/>
                            详细地址：{organizationDetail?.address === "" ? "-" : organizationDetail?.address}
                        </span>
                    </div>
                </div>
                <div className={styles.department_engineer_box}>
                    {this.state.departmentAccountList.length > 0 ? this.state.departmentAccountList.map(item => <div
                        className={styles.department_engineer_item}
                        onClick={() => this.toAccountDetail(item)} key={item.id}>
                        <img src={item?.avatar ? item.avatar : require("../../../../asset/defaultAvatar.jpeg")} alt=""
                             className={styles.department_engineer_avatar}/>
                        <div className={styles.department_engineer_name_work}>
                            <span className={styles.department_engineer_name}>
                                {item.name}
                            </span>
                            <span className={styles.department_engineer_total_score}>工程师总积分：{item.point}分</span>
                            <span className={styles.department_engineer_workorder}>工单：{item.workorderCount}</span>
                            <span className={styles.department_engineer_phone}>电话：{item.cellphone}</span>
                        </div>
                        <EdgeBorder/>
                    </div>) : <RenderEmptyData/>}
                    {this.fillUpBox()}
                </div>
            </div>
        </div>)
    }
}

export default withNavigation(DepartmentDetail)
