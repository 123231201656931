import React, {useEffect, useState} from 'react';

import styles from "./css/index.module.css"
import DeviceApi from "../../../../api/device/DeviceApi";
import WorkorderApi from "../../../../api/workorder/WorkorderApi";

/**
 * @author       : AngelBeats
 * @date         : 2023-03-16 17:38:19
 * @description  : 侧边栏头部
 */
const SidebarTitle = (props) => {

    // 设备总数
    const [deviceNumber, setDeviceNumber] = useState(0);
    //设备总维护次数
    const [deviceRepairNumber, setDeviceRepairNumber] = useState(0);
    // 设备故障维护次数/百台
    const [deviceRepairNumberAvg, setDeviceRepairNumberAvg] = useState(0);

    useEffect(() => {
        requestDeviceNumber();
        requestDeviceRepairNumber();
    }, [])

    /**
     * 获取设备总数
     */
    const requestDeviceNumber = () => {
        DeviceApi.findDeviceTotal().then(res => {
            setDeviceNumber(res.data)
        }).catch(err => {
            console.error("request device number failed", err)
        })
    }

    /**
     * 获取设备总维护数量
     */
    const requestDeviceRepairNumber = () => {
        WorkorderApi.findWorkorderNumber({logicType: 'smp'}).then(res => {
            setDeviceRepairNumber(res.data)
        }).catch(err => {
            console.error("request device repair number", err)
        })
    }

    return (<div className={styles.device_title}>
        <div className={styles.device_title_item_box}>
            <img src={require("../../../../asset/device/device_num_icon.png")} alt=""
                 className={styles.device_num_img}/>
            <div className={styles.device_title_item}>
                <span className={styles.device_title_item_value}>{deviceNumber}</span>
                <span className={styles.device_title_item_label}>当前设备总数</span>
            </div>
        </div>
        <div className={styles.device_title_item_box}>
            <img src={require("../../../../asset/device/device_repair_num_icon.png")} alt=""
                 className={styles.device_num_img}/>
            <div className={styles.device_title_item}>
                <span
                    className={styles.device_title_item_value}>{deviceRepairNumber}</span>
                <span className={styles.device_title_item_label}>当前设备总维护次数</span>
            </div>
        </div>
        <div className={styles.device_title_item_box}>
            <img src={require("../../../../asset/device/device_ave_repair_num_icon.png")} alt=""
                 className={styles.device_num_img}/>
            <div className={styles.device_title_item}>
                <span className={styles.device_title_item_value}>{deviceNumber === 0 ? <span
                    style={{fontSize: 10}}>未录入设备</span> : (deviceRepairNumber / deviceNumber).toFixed(3)}</span>
                <p className={styles.device_title_item_label}
                   style={{transform: "scale(0.65) translateX(-24px)"}}>设备维护故障次数/台</p>
            </div>
        </div>
    </div>);
};

export default SidebarTitle;
