import {get} from "../../https/index";

const AccountApi = {
    /**
     * 请求工程师位置信息
     * @returns {Promise<AxiosResponse<any>>}
     */
    requestAccountLocation: () => get("/user/account/location/list"),

    /**
     * 查询用户积分
     * @param accountId
     */
    queryUserScore: accountId => get(`/user/account/${accountId}/points`),

    /**
     * 查询所有用户机构
     * @returns {Promise<AxiosResponse<any>>}
     */
    requestAccountAllDepartment: () => get("/user/departments"),

    /**
     * 查询单位下的所有用户
     * @param departmentId
     * @returns {Promise<AxiosResponse<any>>}
     */
    requestDepartmentAllAccount: departmentId => get(`/user/department/${departmentId}/accounts`),

    /**
     * 查询服务机构数量
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    findOrganizationNumber: params => get("/user/department/statistics/count", params),

    /**
     * 查询账号总数量
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    findAccountNumber: params => get("/user/account/statistics/count", params),

    /**
     * 查询所有用户
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    findAllAccount: params => get("/user/accounts", params),

    /**
     * 查询工程师详情
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    findAccountDetail: params => get("/user/account", params),

    /**
     * 查询用户积分及排名
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    findAccountScoreAndRank: params => get(`/user/account/${params.accountId}/points-rank`),

    /**
     * 查询用户雷达图
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    findAccountRadarMap: params => get(`/user/account/${params.accountId}/capability/radar-map`),

    /**
     * 查询用户勋章
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    findAccountBadges: params => get(`/user/account/${params.accountId}/badges/${params.year}/${params.month}`),

    /**
     * 批量查询用户积分
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    findBatchAccountPoint: params => get("/user/users-point", params)
}

export default AccountApi;
