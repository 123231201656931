import React, {Component} from 'react';
import styles from "./css/situation.module.css";
import EdgeBorder from "../../../../../components/box/EdgeBorder";
import DeviceApi from "../../../../../api/device/DeviceApi";
import WorkorderApi from "../../../../../api/workorder/WorkorderApi";

/**
 * @author       : AngelBeats
 * @date         : 2023-03-14 16:47:04
 * @description  :
 */
export default class DetailPersonSituation extends Component {

    state = {
        createDeviceNumber: 0,
        replaceLabelNumber: 0,
        knowledgeNumber: 0,
        relatedDeviceWorkorderNumber: 0,
        relatedDeviceWorkorder: 0,
        moveDeviceNumber: 0,
    }

    componentDidMount() {
        this.requestAccountCreateDeviceNumber();
        this.requestAccountReplaceLabelNumber();
        this.requestAccountKnowledgeNumber();
        this.requestAccountRelatedDeviceWorkorderNumber();
        this.requestAccountMoveDeviceNumber();
    }

    /**
     * 获取用户录入设备数
     */
    requestAccountCreateDeviceNumber = () => {
        DeviceApi.findDeviceTotal({creator: this.props.accountId}).then(res => {
            this.setState({createDeviceNumber: res.data})
        }).catch(err => {
            console.error("request account create device number failed", err)
        })
    }

    /**
     * 获取用户替换标签数
     */
    requestAccountReplaceLabelNumber = () => {
        DeviceApi.findAccountReplaceLabelNumber(this.props.accountId).then(res => {
            this.setState({replaceLabelNumber: res.data})
        }).catch(err => {
            console.error("request account replace label number failed", err)
        })
    }

    /**
     * 获取用户发布知识库数
     */
    requestAccountKnowledgeNumber = () => {
        DeviceApi.findAccountKnowledgeNumber(this.props.accountId).then(res => {
            this.setState({knowledgeNumber: res.data})
        }).catch(err => {
            console.error("request account knowledge number failed", err)
        })
    }

    /**
     * 获取用户处理关联设备工单数
     */
    requestAccountRelatedDeviceWorkorderNumber = () => {
        WorkorderApi.findWorkorderNumber({
            handlerId: this.props.accountId,
            logicType: "smp",
            resource: true
        }).then(res => {
            this.requestAccountRelatedDeviceWorkorder(res.data);
            this.setState({relatedDeviceWorkorderNumber: res.data})
        }).catch(err => {
            console.error("request account relate device workorde number failed", err)
        })
    }

    /**
     * 获取用户关联设备工单率
     */
    requestAccountRelatedDeviceWorkorder = (count = 0) => {
        WorkorderApi.findWorkorderNumber({handlerId: this.props.accountId}).then(res => {
            this.setState({relatedDeviceWorkorder: res.data === 0 ? "0%" : (count * 100 / res.data).toFixed(2) + "%"})
        }).catch(err => {
            console.error("request account related device workorder failed", err)
        })
    }

    /**
     * 获取用户迁移设备数
     */
    requestAccountMoveDeviceNumber = () => {
        DeviceApi.findAccountMoveDeviceNumber(this.props.accountId).then(res => {
            this.setState({moveDeviceNumber: res.data})
        }).catch(err => {
            console.error("request account move device number failed", err)
        })
    }

    render() {
        const {
            createDeviceNumber,
            replaceLabelNumber,
            knowledgeNumber,
            relatedDeviceWorkorderNumber,
            relatedDeviceWorkorder,
            moveDeviceNumber
        } = this.state

        return (<div className={styles.person_work_finish_situation_view}>
            <div className={styles.person_input_device_num}>
                <span className={styles.person_input_device_num_value}>{createDeviceNumber}</span>
                <span className={styles.person_input_device_num_label}>录入设备数</span>
            </div>
            <div className={styles.person_replace_label_num}>
                <span className={styles.person_replace_label_num_value}>{replaceLabelNumber}</span>
                <span className={styles.person_replace_label_num_label}>替换标签数</span>
            </div>
            <div className={styles.person_knowledge_num}>
                <span className={styles.person_knowledge_num_value}>{knowledgeNumber}</span>
                <span className={styles.person_knowledge_num_label}>知识库贡献数</span>
            </div>
            <div className={styles.person_handle_related_device_workorder}>
                <span
                    className={styles.person_handle_related_device_workorder_value}>{relatedDeviceWorkorderNumber}</span>
                <span className={styles.person_handle_related_device_workorder_label}>处理关联设备工单</span>
            </div>
            <div className={styles.person_related_device_workorder_num}>
                <span className={styles.person_related_device_workorder_num_value}>{relatedDeviceWorkorder}</span>
                <span className={styles.person_related_device_workorder_num_label}>关联设备工单率</span>
            </div>
            <div className={styles.person_migrate_device_num}>
                <span className={styles.person_migrate_device_num_value}>{moveDeviceNumber}</span>
                <span className={styles.person_migrate_device_num_label}>迁移设备数</span>
            </div>

            <EdgeBorder/>
        </div>)
    }
}
