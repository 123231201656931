import React, {Component} from 'react';
import styles from "./index.module.css"
import * as echarts from "echarts";
import WorkorderApi from "../../../../../api/workorder/WorkorderApi";

/**
 * @author       : AngelBeats
 * @date         : 2023-03-13 18:46:15
 * @description  : 工程师排行
 */
export default class EngineerOrder extends Component {

    engineerOrderChart;

    state = {
        engineerOrderChartXData: [],
        engineerOrderChartYData: []
    }

    componentDidMount() {
        this.requestWorkorderRankStatistics();
    }

    /**
     * 查询近30天工单排行
     */
    requestWorkorderRankStatistics = () => {
        WorkorderApi.findWorkorderRankStatistics().then(res => {
            console.log(res)
            this.setState({
                engineerOrderChartXData: res.data.map(item => item.value).reverse(),
                engineerOrderChartYData: res.data.map(item => item.name).reverse()
            }, this.init)
        }).catch(err => {
            console.error("request workorder rank statistics failed", err)
        })
    }

    option = () => ({
        backgroundColor: '#141845',
        title: null,
        // tooltip: {
        //     trigger: 'axis',
        //     formatter: "{b} <br> 数量: {c}"
        // },
        grid: {
            left: '2%',
            right: '4%',
            bottom: '2%',
            top: '8%',
            containLabel: true
        },
        xAxis: {
            splitLine: {
                show: false
            },
            type: 'value',
            min: 0,
            axisLine: {
                lineStyle: {
                    color: '#fff'
                }
            }
        },
        yAxis: {
            splitLine: {
                show: false
            },
            type: 'category',
            data: this.state.engineerOrderChartYData,
            axisLine: {
                show: false,
                onZero: false,
                lineStyle: {
                    color: '#fff'
                }
            },
            axisTick: {
                show: false
            },
            axisLabel: {
                show: true,
                interval: 0,
                rotate: 0,
                margin: 10,
                inside: false,
                textStyle: {
                    //color: '#fff',
                    fontWeight: '50'
                }
            }
        },
        series: [{
            type: 'bar',
            barWidth: '4',
            label: {
                normal: {
                    show: true,
                    // formatter: '{c}',
                    formatter: function (v) {
                        let val = v.data;
                        if (val === 0) {
                            return '';
                        }
                        return val;
                    },
                    color: '#fff'
                }
            },
            itemStyle: {
                emphasis: {
                    barBorderRadius: 7
                },
                normal: {
                    barBorderRadius: 4,
                    color: new echarts.graphic.LinearGradient(
                        0, 0, 1, 0,
                        [{
                            offset: 0,
                            color: '#387DE7'
                        },
                            {
                                offset: 1,
                                color: '#2CBEF3'
                            }

                        ]
                    )
                }
            },
            showBackground: true,
            backgroundStyle: {
                color: "#2A40A5"
            },
            data: this.state.engineerOrderChartXData
        }]
    });

    init = () => {
        this.engineerOrderChart = echarts.init(document.getElementById('engineer_order_main'));
        this.engineerOrderChart.setOption(this.option())
    }

    render() {
        return (<div className={styles.engineer_order_div}>
            <h3 className={styles.engineer_order_title}>工程师完成工单统计</h3>
            <div id="engineer_order_main" className={styles.engineer_order_main}/>
        </div>)
    }
}
