import React from 'react';
import {withNavigation} from "../../../utils/WithPlusClass";
import styles from "./index.module.css";
import PageTitle from "../../../components/title/PageTitle";
import ManuFacturerItem from "./components/ManuFacturerItem";
import Slider from 'react-slick';
import ManuFacturerApi from "../../../api/manufacturer";

class ManuFacturer extends React.Component {

    state = {
        manufacturers: [],
        pointData: [],
        newfeveiceData:[]
    }

    componentDidMount() {
        this.getPoint();
    }

    getManufacturers = () => {
        ManuFacturerApi.manufacturers({statistic:1}).then(res => {
            this.setState({
                manufacturers:res.data.records
            },() => {
                let { pointData,manufacturers} = this.state
                let alreadyId = manufacturers.map(item => {
                    return item.id
                })
                let newfeveiceData = pointData.filter(item => {
                    return alreadyId.includes(item.refId)
                })
                this.setState({
                    newfeveiceData:newfeveiceData
                })
            })
        })
    }
    getPoint = () => {
        ManuFacturerApi.point({type:0}).then(res => {
            this.setState({
                pointData:res.data
            },() => {
                this.getManufacturers()

            })
        })
    }

    render() {
        const settings = {
            dots: false, // 是否显示分页器
            infinite: false, // 是否循环播放
            slidesToShow: 3, // 一次显示多少个
            slidesToScroll: 1, // 一次滚动几个
            autoplay: false, // 是否自动播放
            autoplaySpeed: 5000, // 自动播放速度
            pauseOnHover: true,// 鼠标悬停
            arrows: false, // 是否显示箭头

        };
        return (
            <div className={styles.manu_facturer_page}>
                <PageTitle title="赣州市信创数字化设备监控中心" backIcon/>
                <div className={styles.manu_facturer_box} >
                    <Slider {...settings}>
                        { this.state.newfeveiceData.map((item, index) =>{return <ManuFacturerItem PointData={item} key={index} />} ) }
                        {/*<ManuFacturerItem title="超越申泰" type={0}/>*/}

                    </Slider>
                </div>
            </div>
        )
    }

}
export default withNavigation(ManuFacturer);