import React, {Component} from 'react';

import styles from "./css/index.module.css"
import * as echarts from "echarts";
import AccountApi from "../../../../../api/account/AccountApi";
import RenderAccountWorkState from "../../../../../components/account/RenderAccountWorkState";
import defaultAvatar from "../../../../../asset/defaultAvatar.jpeg"
import WorkorderApi from "../../../../../api/workorder/WorkorderApi";
import EdgeBorder from "../../../../../components/box/EdgeBorder";
import RenderEmptyData from "../../../../../components/empty/RenderEmptyData";
import IntegralDescription from "../../modal/IntegralDescription";
import MedalDescription from "../../modal/MedalDescription";

/**
 * @author       : AngelBeats
 * @date         : 2023-03-14 10:48:33
 * @description  : 工程师详情页面左侧内容
 */
export default class DetailLeftContent extends Component {

    engineerRadarChart;

    integralDescriptionRef = React.createRef();
    medalDescriptionRef = React.createRef();

    state = {
        accountDetail: {},
        accountScoreAndRank: {},
        accountWorkorderNumber: 0,
        accountRadarMapData: [],
        accountRadarMapValue: [],
        accountBadges: []

    }

    componentDidMount() {
        this.init();
        this.requestAccountInfo();
        this.requestAccountScoreAndRank();
        this.requestAccountWorkorderNumber();
        this.requestAccountRadarMap();
        this.requestAccountBadges();
    }

    /**
     * 获取个人信息
     */
    requestAccountInfo = () => {
        AccountApi.findAccountDetail({accountId: this.props.accountId}).then(res => {
            console.log(res)
            this.setState({accountDetail: res.data})
        }).catch(err => {
            console.error("request account info failed", err)
        })
    }

    /**
     * 查询用户积分及排名
     */
    requestAccountScoreAndRank = () => {
        AccountApi.findAccountScoreAndRank({accountId: this.props.accountId}).then(res => {
            this.setState({accountScoreAndRank: res.data})
        }).catch(err => {
            console.error("request account score and rank failed", err)
        })
    }

    /**
     * 查询用户完成工单数
     */
    requestAccountWorkorderNumber = () => {
        WorkorderApi.findWorkorderNumber({handlerId: this.props.accountId, progress: 17}).then(res => {
            this.setState({accountWorkorderNumber: res.data})
        }).catch(err => {
            console.error("request account workorder number failed", err)
        })
    }

    /**
     * 获取用户当月勋章
     */
    requestAccountBadges = () => {
        let date = new Date();
        let year = date.getFullYear();
        let month = date.getMonth() + 1
        AccountApi.findAccountBadges({accountId: this.props.accountId, month, year}).then(res => {
            this.setState({accountBadges: res.data})
        }).catch(err => {
            console.error("request account badges failed", err)
        })
    }

    /**
     * 渲染用户勋章
     * @returns {unknown[]}
     */
    renderAccountBadges = () => this.state.accountBadges.map(item => {
        if (item.name === "设备管理大师") {
            if (item.level === 1) {
                return <div className={`${styles.engineer_medal_item} ${styles.engineer_medal_gold}`} key={item.id}>
                        <span>
                            <img src={require("../../../../../asset/engineer/medal/sbglds_gold_icon.png")} alt=""
                                 className={styles.engineer_medal_icon}/> {item.name}
                        </span>
                </div>
            }
            if (item.level === 2) {
                return <div className={`${styles.engineer_medal_item} ${styles.engineer_medal_silver}`} key={item.id}>
                        <span>
                            <img src={require("../../../../../asset/engineer/medal/sbglds_silver_icon.png")} alt=""
                                 className={styles.engineer_medal_icon}/> {item.name}
                        </span>
                </div>
            }

            if (item.level === 3) {
                return <div className={`${styles.engineer_medal_item} ${styles.engineer_medal_copper}`} key={item.id}>
                        <span>
                            <img src={require("../../../../../asset/engineer/medal/sbglds_copper_icon.png")} alt=""
                                 className={styles.engineer_medal_icon}/> {item.name}
                        </span>
                </div>
            }
            return;
        }

        if (item.name === "维修达人") {
            if (item.level === 1) {
                return <div className={`${styles.engineer_medal_item} ${styles.engineer_medal_gold}`} key={item.id}>
                        <span>
                            <img src={require("../../../../../asset/engineer/medal/whdr_gold_icon.png")} alt=""
                                 className={styles.engineer_medal_icon}/> {item.name}
                        </span>
                </div>
            }
            if (item.level === 2) {
                return <div className={`${styles.engineer_medal_item} ${styles.engineer_medal_silver}`} key={item.id}>
                        <span>
                            <img src={require("../../../../../asset/engineer/medal/whdr_silver_icon.png")} alt=""
                                 className={styles.engineer_medal_icon}/> {item.name}
                        </span>
                </div>
            }

            if (item.level === 3) {
                return <div className={`${styles.engineer_medal_item} ${styles.engineer_medal_copper}`} key={item.id}>
                        <span>
                            <img src={require("../../../../../asset/engineer/medal/whdr_copper_icon.png")} alt=""
                                 className={styles.engineer_medal_icon}/> {item.name}
                        </span>
                </div>
            }
            return;
        }

        if (item.name === "系统维护达人") {
            if (item.level === 1) {
                return <div className={`${styles.engineer_medal_item} ${styles.engineer_medal_gold}`} key={item.id}>
                        <span>
                            <img src={require("../../../../../asset/engineer/medal/xtwhdr_gold_icon.png")} alt=""
                                 className={styles.engineer_medal_icon}/> {item.name}
                        </span>
                </div>
            }
            if (item.level === 2) {
                return <div className={`${styles.engineer_medal_item} ${styles.engineer_medal_silver}`} key={item.id}>
                        <span>
                            <img src={require("../../../../../asset/engineer/medal/xtwhdr_silver_icon.png")} alt=""
                                 className={styles.engineer_medal_icon}/> {item.name}
                        </span>
                </div>
            }

            if (item.level === 3) {
                return <div className={`${styles.engineer_medal_item} ${styles.engineer_medal_copper}`} key={item.id}>
                        <span>
                            <img src={require("../../../../../asset/engineer/medal/xtwhdr_copper_icon.png")} alt=""
                                 className={styles.engineer_medal_icon}/> {item.name}
                        </span>
                </div>
            }
            return;
        }

        if (item.name === "设备学者") {
            if (item.level === 1) {
                return <div className={`${styles.engineer_medal_item} ${styles.engineer_medal_gold}`} key={item.id}>
                        <span>
                            <img src={require("../../../../../asset/engineer/medal/sbxz_gold_icon.png")} alt=""
                                 className={styles.engineer_medal_icon}/> {item.name}
                        </span>
                </div>
            }
            if (item.level === 2) {
                return <div className={`${styles.engineer_medal_item} ${styles.engineer_medal_silver}`} key={item.id}>
                        <span>
                            <img src={require("../../../../../asset/engineer/medal/sbxz_silver_icon.png")} alt=""
                                 className={styles.engineer_medal_icon}/> {item.name}
                        </span>
                </div>
            }

            if (item.level === 3) {
                return <div className={`${styles.engineer_medal_item} ${styles.engineer_medal_copper}`} key={item.id}>
                        <span>
                            <img src={require("../../../../../asset/engineer/medal/sbxz_copper_icon.png")} alt=""
                                 className={styles.engineer_medal_icon}/> {item.name}
                        </span>
                </div>
            }
            return;
        }

        if (item.name === "模范工程师") {
            if (item.level === 1) {
                return <div className={`${styles.engineer_medal_item} ${styles.engineer_medal_gold}`} key={item.id}>
                        <span>
                            <img src={require("../../../../../asset/engineer/medal/mfgcs_gold_icon.png")} alt=""
                                 className={styles.engineer_medal_icon}/> {item.name}
                        </span>
                </div>
            }
            if (item.level === 2) {
                return <div className={`${styles.engineer_medal_item} ${styles.engineer_medal_silver}`} key={item.id}>
                        <span>
                            <img src={require("../../../../../asset/engineer/medal/mfgcs_silver_icon.png")} alt=""
                                 className={styles.engineer_medal_icon}/> {item.name}
                        </span>
                </div>
            }

            if (item.level === 3) {
                return <div className={`${styles.engineer_medal_item} ${styles.engineer_medal_copper}`} key={item.id}>
                        <span>
                            <img src={require("../../../../../asset/engineer/medal/mfgcs_copper_icon.png")} alt=""
                                 className={styles.engineer_medal_icon}/> {item.name}
                        </span>
                </div>
            }
        }

        return;
    })

    /**
     * 勋章盒子填充
     */
    fillUpBox = () => {
        const {accountBadges} = this.state;

        if (accountBadges.length % 3 === 1) {
            return <>
                <div className={styles.engineer_medal_item}/>
                <div className={styles.engineer_medal_item}/>
            </>
        }

        if (accountBadges.length % 3 === 2) {
            return <div className={styles.engineer_medal_item}/>
        }

    }

    /**
     * 查询用户雷达图
     */
    requestAccountRadarMap = () => {
        AccountApi.findAccountRadarMap({accountId: this.props.accountId}).then(res => {
            console.log(res)
            let accountRadarMapData = [];
            let accountRadarMapValue = [];
            for (const item in res.data) {
                accountRadarMapData.push({name: item, max: 1});
                let value = res.data[item];
                accountRadarMapValue.push(value > 1 ? 1 : value.toFixed(2))
            }
            this.setState({accountRadarMapData, accountRadarMapValue}, () => {
                this.engineerRadarChart.setOption(this.option());
            })
        }).catch(err => {
            console.error("request account radar map failed", err)
        })
    }

    /**
     * 配置项
     */
    option = () => ({
        // backgroundColor: '#0D2753',
        tooltip: {
            //雷达图的tooltip不会超出div，也可以设置position属性，position定位的tooltip 不会随着鼠标移动而位置变化，不友好
            confine: true,
            enterable: true, //鼠标是否可以移动到tooltip区域内
        },
        radar: {
            name: {
                textStyle: {
                    color: '#FFFFFF',
                    fontSize: 14,
                },
            },
            shape: 'polygon',
            center: ['50%', '50%'],
            radius: '80%',
            startAngle: 120,
            scale: true,
            axisLine: {
                lineStyle: {
                    color: 'rgba(5, 213, 255, .8)',
                },
            },
            splitLine: {
                show: true,
                lineStyle: {
                    width: 1,
                    color: 'rgba(5, 213, 255, .8)', // 设置网格的颜色
                },
            },
            indicator: this.state.accountRadarMapData,
            splitArea: {
                show: false,
            },
        },
        grid: {
            position: 'center',
        },
        polar: {
            center: ['50%', '50%'], // 默认全局居中
            radius: '0%',

        },
        angleAxis: {
            min: 0,
            interval: 5,
            clockwise: false,
            axisTick: {
                show: false,
            },
            axisLabel: {
                show: false,
            },
            axisLine: {
                show: false,
            },
            splitLine: {
                show: false,
            },
        },
        radiusAxis: {
            min: 0,
            interval: 20,
            splitLine: {
                show: false,
            },
        },
        series: [
            {
                name: '个人雷达图',
                type: 'radar',
                symbol: 'circle', // 拐点的样式，还可以取值'rect','angle'等
                symbolSize: 10, // 拐点的大小
                itemStyle: {
                    color: '#05D5FF',
                },
                areaStyle: {
                    color: '#05D5FF',
                    opacity: 0.5,
                },
                lineStyle: {
                    width: 2,
                    color: '#05D5FF',
                },
                label: {
                    show: false,
                    formatter: (params) => {
                        return params.value;
                    },
                    color: '#fff',
                },
                data: [
                    {
                        value: this.state.accountRadarMapValue,
                    },
                ],
            },
        ],
    })

    /**
     * 初始化雷达图
     */
    init = () => {
        this.engineerRadarChart = echarts.init(document.getElementById('engineer_radar_chart'));
    }

    /**
     * 查看积分说明
     */
    previewIntegralDescription = () => {
        if (this.medalDescriptionRef.current.getIsShow()) {
            this.medalDescriptionRef.current.close();
        }
        this.integralDescriptionRef.current.show();
    }

    /**
     * 查看勋章说明
     */
    previewMedalDescription = () => {
        if (this.integralDescriptionRef.current.getIsShow()) {
            this.integralDescriptionRef.current.close();
        }
        this.medalDescriptionRef.current.show();
    }

    render() {
        const {accountDetail, accountScoreAndRank, accountWorkorderNumber} = this.state
        return (<div>
            <div className={styles.engineer_info_view}>
                <img src={accountDetail?.avatar ? accountDetail?.avatar : defaultAvatar} alt=""
                     className={styles.engineer_avatar}/>
                <div className={styles.engineer_info_right}>
                    <div className={styles.engineer_name_view}>
                        <span className={styles.engineer_name}>{accountDetail.name}</span>
                        <span>
                            <img
                                src={require("../../../../../asset/engineer/working_icon.png")}
                                alt=""
                                className={styles.work_state_icon}/>
                            {RenderAccountWorkState(accountDetail.situation)}
                        </span>
                    </div>
                    <div className={styles.engineer_department_view}>
                        <img
                            src={accountDetail?.departments && accountDetail.departments[0]?.logo ? accountDetail.departments[0]?.logo : require("../../../../../asset/engineer/detail/engineer_department_logo.png")}
                            alt=""
                            className={styles.engineer_department_logo}/>
                        {accountDetail?.departments ? accountDetail?.departments[0]?.name : null}
                    </div>
                    <div className={styles.engineer_phone_view}>
                        <span>电话:   </span>
                        <span>{accountDetail?.cellphone ?? "-"}</span>
                    </div>
                    <div className={styles.engineer_address_view}>
                        <span>所在地：</span>
                        <span>{accountDetail?.province + "-" + accountDetail?.city + "-" + accountDetail?.county}</span>
                    </div>
                </div>
                <img src={require("../../../../../asset/engineer/detail/engineer_address_icon.png")}
                     className={styles.engineer_address_icon} alt=""/>

                <EdgeBorder/>
            </div>
            <div className={styles.engineer_total_score}>
                <div className={styles.engineer_order_and_score}>
                    <div className={styles.engineer_score_order}>
                        <span
                            className={styles.engineer_score_detail}>{accountScoreAndRank?.points > 0 ? accountScoreAndRank?.points : 0}</span>
                        <p className={styles.engineer_order_detail}>{`排名第${accountScoreAndRank?.rank}位`}</p>
                    </div>
                    <div className={styles.engineer_score}>
                        <span className={styles.engineer_score_title}>工程师总积分</span>
                        <p className={styles.engineer_finish_workorder_num}>完成工单数: {accountWorkorderNumber}</p>
                        <div className={styles.score_medal_descriptions}>
                            <div className={styles.integral_description_box}>
                                 <span className={styles.score_descriptions} onClick={this.previewIntegralDescription}>
                                积分说明
                                 </span>
                                <IntegralDescription ref={this.integralDescriptionRef}/>
                            </div>
                            <div className={styles.integral_description_box}>
                                <span className={styles.medal_descriptions} onClick={this.previewMedalDescription}>
                                查看勋章说明
                                 </span>
                                <MedalDescription ref={this.medalDescriptionRef}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.engineer_medal_view}>
                    {this.renderAccountBadges()}
                    {this.fillUpBox()}
                    {this.state.accountBadges.length > 0 ? null : <RenderEmptyData title="暂无勋章" width="100"/>}
                </div>
                <EdgeBorder/>
            </div>
            <div className={styles.engineer_radar_chart_view}>
                <div id="engineer_radar_chart" className={styles.engineer_radar_chart}/>
                {this.state.accountRadarMapValue.length > 0 ? null :
                    <div className={styles.department_left_chart_empty}>
                        <RenderEmptyData/>
                    </div>}
                <EdgeBorder/>
            </div>
        </div>)
    }
}
