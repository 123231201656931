import React from 'react';
import PropTypes from "prop-types"
import styles from "./index.module.css";
import {RightOutlined} from "@ant-design/icons";
import { useNavigate } from 'react-router-dom';
import Logos from "./logos";
const FacturerTitle = ({title,id}) => {
    const navigate = useNavigate();
    return(
        <div className={styles.facturertitle}>

            <Logos title={title}/>
            {id && <span className={styles.tolink} onClick={() => {
                navigate('/home/fevicetypescores', {state: {id: id}});
            }}> <RightOutlined/></span>}
            <span className={styles.topLeft}></span>
            <span className={styles.topRight}></span>
            <span className={styles.bottomLeft}></span>
            <span className={styles.bottomRight}></span>

        </div>
    )
}

FacturerTitle.propTypes = {
    title: PropTypes.string,
    id: PropTypes.string,
}

export default FacturerTitle;