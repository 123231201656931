/**
 * 渲染用户状态
 * @param state
 * @returns {string}
 * @constructor
 */
const RenderAccountWorkState = (state) => {
    const result = {
        0: "休息中",
        1: "工作中",
        2: "忙碌中"
    }
    return result[state] ?? "未知"
}

export default RenderAccountWorkState;
