import React, {Component} from 'react';
import styles from "./css/dialog.module.css"
import WorkorderApi from "../../../../api/workorder/WorkorderApi";
import RenderWorkorderServiceType from "../../../../components/workorder/RenderWorkorderServiceType";
import * as echarts from "echarts";

/**
 * @author       : AngelBeats
 * @date         : 2023-04-11 09:00:36
 * @description  : 问题统计弹窗
 */
export default class DeviceQuestionStatistics extends Component {

    deviceQuestionStatisticsChart;

    state = {
        deviceQuestionStatisticsDataSource: [],
        chartMaxCount: [],
        open: false
    }

    componentDidMount() {

    }

    /**
     * 查询单位工单问题分布情况
     */
    requestDepartmentWorkorderQuestion = () => {
        WorkorderApi.findDepartmentWorkorderQuestion().then(res => {
            let maxCount = 0;
            let chartData = []
            res.data.forEach((item, index) => {
                maxCount += item.count;
                chartData.push(item)
                // if (index < 8) {
                // }
            })
            this.setState({
                deviceQuestionStatisticsDataSource: chartData.map(item => {
                    if (item.resourceName === "" || item.resourceType === "") {
                        return {
                            name: RenderWorkorderServiceType(item.type),
                            value: item.count
                        }
                    } else {
                        return {
                            name: item.resourceName + "-" + item.resourceType,
                            value: item.count
                        }
                    }
                }),
                chartMaxCount: res.data.map(() => maxCount)
            }, () => {
                this.deviceQuestionStatisticsChart.setOption(this.options())
            })
        }).catch(err => {
            console.error("request department workorder question failed", err)
        })
    }

    /**
     * chart配置项
     */
    options = () => {
        return {
            grid: [
                {
                    left: 70,
                    top: 10,
                    right: 10,
                    bottom: 10,
                    // containLabel: true,
                },
            ],
            xAxis: [
                {
                    gridIndex: 0,
                    type: 'value',
                    axisLabel: {show: false},
                    axisLine: {show: false},
                    splitLine: {show: false},
                    axisTick: {show: false},
                },
            ],
            yAxis: [
                {
                    type: 'category',
                    inverse: true, // 倒叙，false会从小到大排
                    axisLine: {
                        show: false // 不显示坐标轴轴线
                    },
                    axisTick: {
                        show: false // 不显示刻度线
                    },
                    offset: -20,
                    zlevel: 100,
                    position: 'left',
                    axisLabel: {
                        color: '#FFFFFF',
                        align: 'left',
                        fontSize: 10,
                        display: "inline-block",
                        verticalAlign: "middle",
                        transform: "translateY(-10px)"
                    },
                    data: this.state.deviceQuestionStatisticsDataSource.map((item) => item.name)
                },
                {
                    gridIndex: 0,
                    type: 'category',
                    inverse: true,
                    position: 'left',
                    data: this.state.deviceQuestionStatisticsDataSource,
                    axisTick: {show: false},
                    axisLine: {show: false},
                    splitLine: {show: false},
                    axisLabel: {
                        width: 80,
                        padding: [0, 0, 0, -60],
                        align: 'left',
                        formatter: (_, index) => {
                            const id = index + 1;
                            if (id > 0) {
                                return `{img|}{count|${id}}`;
                            }
                        },
                        rich: {
                            count: {
                                padding: [6, 0, 0, 0],
                                width: 25,
                                height: 23,
                                color: '#fff',
                                align: 'center',
                                fontSize: 15,
                                fontFamily: 'DIN',
                                fontWeight: 500,
                            },
                            img: {
                                padding: [0, 0, 0, 0],
                                width: 25,
                                height: 23,
                                color: '#fff',
                                align: 'center',
                                fontSize: 15,
                                fontFamily: 'DIN',
                                fontWeight: 500,
                                backgroundColor: {
                                    image: require("../../../../asset/department/department_chart_icon.png")
                                }
                            },
                            name: {
                                width: 80,
                                fontSize: 15,
                                align: 'left',
                                color: '#fff',
                                fontFamily: 'Source Han Sans CN',
                                fontWeight: 500,
                            },
                        },
                    },
                },
                {
                    gridIndex: 0,
                    type: 'category',
                    position: 'right',
                    inverse: true,
                    data: this.state.deviceQuestionStatisticsDataSource.map((item) => item.name),
                    axisTick: {show: false},
                    axisLine: {show: false},
                    splitLine: {show: false},
                    zlevel: 999999,
                    axisLabel: {
                        padding: [4, 45, 0, 0],
                        align: 'right',
                        formatter: (_, index) => {
                            if (this.state.deviceQuestionStatisticsDataSource[index].value) {
                                return `{value|${this.state.deviceQuestionStatisticsDataSource[index].value}}`;
                            }
                        },
                        rich: {
                            value: {
                                color: '#fff',
                                fontSize: 10,
                            },
                        },
                    },
                },
            ],
            series: [
                {
                    type: 'bar',
                    xAxisIndex: 0,
                    yAxisIndex: 0,
                    barWidth: 20,
                    data: this.state.deviceQuestionStatisticsDataSource.map((item) => item.value),
                    silent: true,
                    emphasis: {
                        itemStyle: {
                            borderRadius: [0, 20, 20, 0],
                        }
                    },
                    itemStyle: {
                        borderRadius: [0, 0, 0, 0],
                        color: new echarts.graphic.LinearGradient(
                            0,
                            0,
                            1,
                            0,
                            [
                                {
                                    offset: 0,
                                    color: '#0E487A', // 0% 处的颜色
                                },
                                {
                                    offset: 1,
                                    color: '#0CABFD', // 100% 处的颜色
                                },
                            ],
                            false
                        ),
                    },
                },
                {
                    name: '背景',
                    type: 'bar',
                    barWidth: 20,
                    barGap: '-100%',
                    data: this.state.chartMaxCount,
                    itemStyle: {
                        color: 'rgba(11,62,118, 0.5)',
                        // borderRadius: [30, 30, 30, 30],
                    },
                },
            ],
        }

    }

    /**
     * 初始化chart
     */
    init = () => {
        this.deviceQuestionStatisticsChart = echarts.init(document.getElementById("device_question_statistics_chart"));
    }

    /**
     * 开启弹窗
     */
    show = () => this.setState({open: true}, () => {
        this.init();
        this.requestDepartmentWorkorderQuestion();
    })

    /**
     * 关闭弹窗
     */
    close = () => this.setState({open: false}, () => {
        this.deviceQuestionStatisticsChart.dispose()
    })

    render() {
        return (<>
            {
                this.state.open ? <div className={styles.dialog_view} onClick={this.close}>
                    <div className={styles.question_dialog_title}>问题统计</div>
                    <div className={styles.device_question_statistics_chart_box} onClick={(e) => e.stopPropagation()}>
                        <div id="device_question_statistics_chart" className={styles.device_question_statistics_chart}/>
                    </div>
                </div> : null
            }
        </>)
    }
}
