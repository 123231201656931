import React, {Component} from 'react';
import styles from "./index.module.css"
import * as echarts from "echarts";
import WorkorderApi from "../../../../../api/workorder/WorkorderApi";
import EventBus from "../../../../../utils/EventBus";

/**
 * @author       : AngelBeats
 * @date         : 2023-03-20 15:55:03
 * @description  : 工单监控右侧内容
 */
export default class WorkorderRightContent extends Component {

    repairPathChart;
    finishSpeedChart;
    trendChart;

    state = {
        workorderRepairWay: [],
        workorderFinishTimeX: [],
        workorderFinishTimeY: [],
        workorderTrendX: [],
        workorderTrendY: [],
    }

    componentDidMount() {
        this.init();
        EventBus.on("toggleCountyData", ({cityName}) => {
            console.log(cityName)
            if (cityName === "赣州市") {
                this.requestAllData();
                return;
            }
            this.requestAllData(cityName);
        })
        this.requestAllData();
    }

    componentWillUnmount() {
        this.repairPathChart.dispose();
        this.finishSpeedChart.dispose();
        this.trendChart.dispose();
    }

    /**
     * 请求所有数据
     * @param cityName
     */
    requestAllData = (cityName = null) => {
        this.requestWorkorderRepairWay(cityName);
        this.requestWorkorderFinishTime(cityName);
        this.requestWorkorderTrend(cityName)
    }

    /**
     * 获取报修渠道数据
     * @param county
     */
    requestWorkorderRepairWay = (county = null) => {
        WorkorderApi.findWorkorderRepairWay({city: "赣州市", county}).then(res => {
            console.log(res)

            let workorderRepairWay = []
            res.data.forEach(item => {
                if (!!item.name) {
                    workorderRepairWay.push({value: item.value, name: item.name})
                }
            })
            this.setState({workorderRepairWay}, () => {
                // 初始化保修渠道统计图
                this.repairPathChart.setOption(this.repairChartOptions());
            })

        }).catch(err => {
            console.error("request workorder repair failed", err)
        })

    }

    /**
     * 获取工单时效统计数据
     * @param county
     */
    requestWorkorderFinishTime = (county = null) => {
        WorkorderApi.findWorkorderFinishTime({city: "赣州市", county}).then(res => {
            this.setState({
                workorderFinishTimeX: res.data.map(item => item.name),
                workorderFinishTimeY: res.data.map(item => item.value)
            }, () => {
                this.finishSpeedChart.setOption(this.finishSpeedChartOptions())
            })
        }).catch(err => {
            console.error("request workorder finish time failed", err)
        })
    }

    /**
     *  获取工单趋势统计数据
     * @param county
     */
    requestWorkorderTrend = (county = null) => {
        WorkorderApi.findWorkorderTrend({city: "赣州市", county}).then(res => {
            console.log(res)
            this.setState({
                workorderTrendX: res.data.map(item => item.name).reverse(),
                workorderTrendY: res.data.map(item => item.value).reverse(),
            }, () => {
                this.trendChart.setOption(this.trendChartOptions())
            })
        }).catch(err => {
            console.error("request workorder trend failed", err)
        })
    }

    /**
     * 报修渠道chart配置项
     */
    repairChartOptions = () => {
        let mColor = ['#3D7ADB', '#f7c32b', '#3BD1D8'];
        let rich = {
            // label标题样式
            a: {
                color: '#FFFFFF',
                align: 'center',
                fontSize: 12,
                padding: [10, -30, 10, -20],
            },
            // label百分比样式
            b: {
                color: '#FFFFFF',
                fontSize: 12,
                align: 'center',
            },
            // label数值样式
            c: {
                color: '#FFFFFF',
                fontSize: 12,
                padding: [5, -10, 0, 20],
                align: 'center',
            },
            // 中心总数样式
            total: {
                color: '#FFFFFF',
                fontSize: 16,
                align: 'center',
            },
        };
        return {
            tooltip: {
                trigger: 'item',
                extraCssText: 'box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);',
                backgroundColor: 'rgba(255,255,255,0.8)',
                textStyle: {
                    color: '#333',
                },
                formatter: params => {
                    let total = 0; //总和
                    this.state.workorderRepairWay.forEach(function (value, index, array) {
                        if (value.value !== undefined) {
                            total += value.value;
                        }
                    });
                    let percent = total === 0 ? 0 : (params.value / total) * 100;
                    let html = [];
                    html.push('<div class = "echart-tip-box">');
                    html.push('<div>' + params.name + '</div>');
                    html.push('<div> 数量：' + params.value + '</div>');
                    html.push('<div> 报修率：' + percent.toFixed(2) + '%</div>');
                    html.push('</div>');
                    return html.join('');
                },
            },
            series: [
                {
                    name: '测试测试',
                    type: 'pie',
                    radius: ['30', '40'],
                    center: ['50%', '50%'],
                    color: mColor,
                    label: {
                        formatter: '{b|{d}%}\n {a|{b}}',
                        padding: [0, 0, -10, -10],
                        rich: rich,
                    },
                    labelLine: {
                        length: 15,
                        length2: 45,
                    },
                    data: this.state.workorderRepairWay,
                },
            ],
        };
    }

    /**
     * 工单完成时效chart配置项
     */
    finishSpeedChartOptions = () => {
        let barTopColor = "#006AFF";
        let barBottomColor = "#9AC4FF";
        return {
            title: {
                show: false,
                text: '工单完成时效',
                top: 20,
                left: 'center',
                color: '#00eaff',
                fontSize: 18,
                fontWeight: 'normal'
            },
            tooltip: {
                trigger: 'axis',
                formatter: '工单完成时效<br>{b}：{c}',
                backgroundColor: 'rgba(18, 57, 60, .8)', //设置背景颜色
                textStyle: {
                    color: '#fff'
                },
                borderColor: "rgba(18, 57, 60, .8)",
                axisPointer: {
                    type: 'shadow',
                    shadowStyle: {
                        color: 'rgba(0, 11, 34, .4)',
                    }
                }
            },
            grid: {
                top: '14%',
                bottom: '1%',
                left: '2%',
                right: '2%',
                containLabel: true
            },
            xAxis: {
                data: this.state.workorderFinishTimeX,
                axisTick: {
                    show: false
                },
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: '#1C82C5'
                    }
                },
                axisLabel: {
                    show: true,
                    rotate: 20,
                    margin: 30,
                    fontSize: 10,
                    color: '#DEEBFF',
                    align: 'center'
                },
                interval: 0
            },
            yAxis: {
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: 'rgba(28, 130, 197, .3)'
                    }
                },
                axisTick: {
                    show: false
                },
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: '#1C82C5'
                    }
                },
                axisLabel: {
                    show: true,
                    margin: 10,
                    fontSize: 12,
                    color: '#DEEBFF',
                },
            },
            series: [{
                name: '工单完成时效',
                type: 'pictorialBar',
                symbolSize: [20, 10],
                symbolOffset: [0, -5],
                z: 12,
                itemStyle: {
                    color: function (params) {
                        return barBottomColor;
                    }
                },
                label: {
                    show: false,
                    position: 'top',
                    fontSize: 16
                },
                symbolPosition: 'end',
                data: this.state.workorderFinishTimeY,
            }, {
                name: '工单完成时效',
                type: 'pictorialBar',
                symbolSize: [20, 10],
                symbolOffset: [0, 5],
                z: 12,
                itemStyle: {
                    color: function (params) {
                        return barTopColor;
                    }
                },
                data: this.state.workorderFinishTimeY,
            }, {
                type: 'bar',
                itemStyle: {
                    color: function (params) {
                        return new echarts.graphic.LinearGradient(
                            0, 0, 0, 1,
                            [{
                                offset: 1,
                                color: barTopColor
                            },
                                {
                                    offset: 0,
                                    color: barBottomColor
                                }
                            ]
                        );
                    },
                    opacity: .9,
                    label: {
                        show: true, //开启显示
                        position: 'top', //在上方显示
                        color: '#FFFFFF',
                        fontSize: 16
                    }
                },
                z: 16,
                silent: true,
                barWidth: 20,
                barGap: '-100%',
                data: this.state.workorderFinishTimeY,
            }]
        };
    }

    /**
     * 工单趋势chart配置
     */
    trendChartOptions = () => {
        let xData = ["1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "12月"];
        let data = [500, 990, 700, 1200, 900, 1000, 800, 1200, 1300, 1400, 1500, 1350]

        return {
            grid: {
                top: "10%",
                right: "0%",
                bottom: "18%",//也可设置left和right设置距离来控制图表的大小
            },
            tooltip: {
                trigger: "axis",
                backgroundColor: 'rgba(17,95,182,0.6)',
                textStyle: {
                    color: "#fff"
                },
                formatter: (pa) => {
                    let oneDotHtml = '<span style="display:inline-block;margin-right:5px;width:10px;height:10px;background-color:#1480C2"></span>'
                    return (
                        pa[0].name + '<br/>'
                        + oneDotHtml + pa[1].seriesName + '：' + pa[1].value
                    );
                }
            },
            dataZoom: [{
                show: false,
                xAxisIndex: 0,
                type: 'slider',
                startValue: 0,
                endValue: this.end
            }],
            xAxis: {
                data: this.state.workorderTrendX,
                axisLine: {
                    show: true, //隐藏X轴轴线
                    lineStyle: {
                        color: '#85B1B4'
                    }
                },
                axisTick: {
                    show: false //隐藏X轴刻度
                },
                axisLabel: {
                    show: true,
                    fontSize: 9,
                    padding: [5, 0, 0, 0],
                    color: "#fff" //X轴文字颜色
                },
            },
            yAxis: [
                {
                    type: "value",
                    nameTextStyle: {
                        color: "#fff",
                        fontSize: 14
                    },
                    splitLine: {
                        show: false,
                        lineStyle: {
                            color: 'rgba(33,35,98,1)'
                        }
                    },
                    axisTick: {
                        show: false
                    },
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#85B1B4'
                        }
                    },
                    axisLabel: {
                        show: true,
                        fontSize: 10,
                        color: "#fff"
                    },
                },
                {
                    type: "value",
                    position: "right",
                    splitLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    axisLine: {
                        show: false
                    },
                    axisLabel: {
                        show: false,
                        color: "#85B1B4"
                    }
                },
            ],
            series: [
                {
                    name: "折线",
                    type: "line",
                    yAxisIndex: 1, //使用的 y 轴的 index，在单个图表实例中存在多个 y轴的时候有用
                    smooth: false, //平滑曲线显示
                    showAllSymbol: true, //显示所有图形。
                    symbol: "emptyCircle", //标记的图形为实心圆
                    symbolSize: 4, //标记的大小
                    itemStyle: {
                        //折线拐点标志的样式
                        color: "#00FFFF",
                    },
                    lineStyle: {
                        color: "#00FFFF",

                    },
                    data: this.state.workorderTrendY
                },
                {
                    name: "工单数量",
                    type: "bar",
                    barWidth: '30%',
                    showBackground: false,
                    itemStyle: {
                        color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [{
                            offset: 0,
                            color: "#409AF7"
                        }, {
                            offset: 1,
                            color: "#006AFF"
                        }
                        ])
                    },
                    label: {
                        show: false,
                        color: '#FFF'
                    },
                    data: this.state.workorderTrendY
                }
            ]
        };
    }

    init = () => {
        this.repairPathChart = echarts.init(document.getElementById("workorder_repair_path_chart"));

        this.finishSpeedChart = echarts.init(document.getElementById("workorder_finish_speed_chart"));

        this.trendChart = echarts.init(document.getElementById("workorder_trend_chart"));
    }

    render() {
        return (<div className={styles.workorder_right_content}>
            <div className={`${styles.workorder_chart_box} ${styles.workorder_repair_path_box}`}>
                <div className={styles.workorder_chart_title}>
                    <span>报修渠道</span>
                </div>
                <div id="workorder_repair_path_chart" className={styles.workorder_chart_size}/>
            </div>
            <div className={`${styles.workorder_chart_box} ${styles.workorder_finish_time}`}>
                <div className={styles.workorder_chart_title}>
                    <span>工单完成时效</span>
                </div>
                <div id="workorder_finish_speed_chart" className={styles.workorder_chart_size}/>
            </div>
            <div className={`${styles.workorder_chart_box} ${styles.workorder_trend}`}>
                <div className={styles.workorder_chart_title}>
                    <span>工单数量趋势</span>
                </div>
                <div id="workorder_trend_chart" className={styles.workorder_chart_size}/>
            </div>
        </div>)
    }
}
