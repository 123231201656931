import React, {Component} from 'react';
import styles from "./index.module.css";

import close from "../../../../asset/engineer/integral_modal_close.svg"

/**
 * @author       : AngelBeats
 * @date         : 2023-04-25 14:49:09
 * @description  : 勋章说明
 */
class MedalDescription extends Component {
    state = {
        open: false
    }

    show = () => {
        this.setState({open: true})
    }

    close = () => {
        this.setState({open: false})
    }

    getIsShow = () => {
        return this.state.open;
    }

    render() {
        return (<>
            {
                this.state.open ?
                    <div className={styles.integral_box}>
                        <div className={styles.integral_close}>
                            <img src={close} alt="" className={styles.close} onClick={this.close}/>
                        </div>
                        <div className={styles.integral_content}>
                            <h3 className={styles.integral_title}>总体规则：</h3>

                            <span>一共有“设备管理大师”、“维修达人”、“系统维护达人”、“设备学者”、“模范工程师”，每种勋章有金银铜3块，一共15块勋章，每块每月只会授予一个人。</span>
                            <span>5种勋章都有自己的得分计算方式，本月得分最多的工程师得到金色勋章，第二的工程师得到银色勋章，第三得铜色勋章，如果两个工程师得分相同，以先得分的工程师得到相应勋章。</span>
                            <span>工程师的勋章得分为0或负时没有勋章。</span>
                            <span>在月底之前，这些勋章归属会因为得分而动态调整，这个变动是实时的。</span>
                            <span>在月底最后一天23:59时，系统进行勋章存档，本月的勋章归属不会再发生变化。</span>
                            <span>“设备管理大师”：得分为工程师本月在设备管理上的得分（录入设备、迁移设备、替换标签）。</span>
                            <span>“设备学者”：得分为工程师本月在知识库提交上的得分。</span>
                            <span>“维修达人”：得分为工程师本月处理信创工单的数量。</span>
                            <span>“系统维护达人”：得分为工程师本月处理运维工单数量。</span>
                            <span>“模范工程师”：得分为工程师本月在工单上的得分 - 工单数量。</span>

                        </div>
                    </div>
                    : null
            }
        </>);
    }
}

export default MedalDescription;
