import React, {Component} from 'react';
import styles from "./index.module.css"
import "./StorehouseMapDialog.css"
import PageTitle from "../../../components/title/PageTitle";
import EdgeBorder from "../../../components/box/EdgeBorder";
import StorehouseApi from "../../../api/storehouse/StorehouseApi";
import storehouseImg from "../../../asset/storehouse/storehouse_img.png"
import defaultDepartmentLogo from "../../../asset/defaultDepartmentLogo.svg";
import {message} from "antd";
import RenderEmptyData from "../../../components/empty/RenderEmptyData";

/**
 * @author       : AngelBeats
 * @date         : 2023-03-16 11:19:24
 * @description  : 仓库地图
 */
export default class StorehouseMap extends Component {

    storeHouseMap;

    /**
     * 定义弹窗
     * @type {*}
     */
    infoWindow = new global.AMap.InfoWindow({offset: new global.AMap.Pixel(-5, -10)});

    state = {
        storehouseList: [],
        stockList: [],
        storehouseDetail: {}
    }

    componentDidMount() {
        this.storeHouseMap = new global.AMap.Map("storehouse-map-container", {
            pitch: 0,
            viewMode: "3D",
            terrain: true,
            zoom: 15,
            resizeEnable: true,
            zooms: [4.6, 18],
            center: [114.973172, 25.817861],
            mapStyle: "amap://styles/darkblue"
        });
        this.requestAllStorehouse();
        this.requestAllStockList();
    }

    /**
     * 根据经纬度查询位置
     */
    findAddress = (item) => {
        this.requestAllStockList(item.id)
        if ([item?.longitude, item?.latitude].includes(undefined)) {
            message.error("暂无该仓库坐标信息!")
            return;
        }
        this.infoWindow.close()
        this.storeHouseMap.setZoomAndCenter(18, [item?.longitude, item?.latitude]);
    }

    /**
     * 自定义仓库 maker 点
     * @param item
     */
    renderStorehouseMaker = item => {
        return `<div class="maker-box">
                              <img src="${storehouseImg}" style="width: 50px;">
                              <div class="department-maker-name">
                                <img src="${item?.files.length > 0 ? item?.files[0].path : defaultDepartmentLogo}" alt="" class="department-maker-logo" style="border-radius: 50%">
                                <span>${item?.name}</span>
                              </div>
                        </div>`
    }

    /**
     * 渲染仓库详情弹窗
     * @param item
     * @param storehouseDetail
     * @param stocksNum
     * @param stocksWorth
     */

    renderStorehouseDetailDialog = (item, storehouseDetail, stocksNum, stocksWorth) => {
        return `<div class="storehouse_dialog_box">
                        <div class="storehouse_dialog_title">
                           ${item?.name}
                        </div>
                        <div class="department_work_item_box">
                            <span class="storehouse_dialog_item"> <img src="${require("../../../asset/storehouse/storehouse_department_icon.png")}" alt="" class="department_engineer_icon">所属机构：${storehouseDetail?.department?.name}</span>
                            <span class="storehouse_dialog_item"><img src="${require("../../../asset/storehouse/storehouse_department_icon.png")}" class="department_engineer_icon" alt="">库管员：${storehouseDetail?.managers?.map(item => item.name) ?? "-"}</span>
                            <span class="storehouse_dialog_item"><img src="${require("../../../asset/storehouse/storehouse_auth_engineer_icon.png")}" class="department_engineer_icon" alt="">授权工程师：${storehouseDetail?.engineers?.map(item => item.name) ?? "-"}</span>
                            <span class="storehouse_dialog_item"><img src="${require("../../../asset/storehouse/storehouse_stock_icon.png")}" class="department_engineer_icon" alt="">备件数量：${stocksNum}/件</span>
                            <span class="storehouse_dialog_item"><img src="${require("../../../asset/storehouse/storehouse_stock_worth.png")}" class="department_engineer_icon" alt="">备件总价值：${stocksWorth ?? "0"}/万</span>
                            <img src="${item?.files.length > 0 ? item?.files[0].path : defaultDepartmentLogo}" class="storehouse_dialog_img">
                        </div>
                </div>`
    }

    /**
     * 请求所有仓库数据 并地图标点
     */
    requestAllStorehouse = () => {
        StorehouseApi.requestAllStorehouse().then(async (res) => {
            console.log(res)
            this.setState({storehouseList: res.data});

            let markerList = [];
            let lnglats = [];

            res.data.forEach(item => {
                lnglats.push(item)
                if (item.longitude !== null && item.latitude !== null) {
                    let marker = new global.AMap.Marker({
                        position: [item.longitude, item.latitude],
                        content: this.renderStorehouseMaker(item),
                        offset: [-30, -20]
                    })
                    markerList.push(marker)
                }
            })
            this.storeHouseMap.add(markerList)

            const markerClick = async (e, item) => {
                this.infoWindow.setContent(e.target.content);
                await this.requestStorehouseDetail(item.id)
                this.infoWindow.open(this.storeHouseMap, e.target.getPosition());
            }
            for (let i = 0; i < lnglats.length; i++) {
                if (lnglats[i].location !== null) {
                    let marker = new global.AMap.Marker({
                        position: [lnglats[i].longitude, lnglats[i].latitude],
                        map: this.storeHouseMap,
                        content: this.renderStorehouseMaker(lnglats[i]),
                        offset: [-30, -20]
                    });
                    const storehouseDetail = await this.requestStorehouseDetail(lnglats[i].id);
                    const stocksNum = await this.requestStorehouseStocksNum(lnglats[i].id);
                    const stocksWorth = await this.requestStorehouseStocksWorth(lnglats[i].id);
                    marker.content = this.renderStorehouseDetailDialog(lnglats[i], storehouseDetail, stocksNum, stocksWorth);
                    marker.on('click', (e) => markerClick(e, lnglats[i]));
                }
            }

        }).catch(err => {
            console.error("request all storehouse failed", err)
        })
    }

    /**
     * 查询仓库详情
     * @param id
     */
    requestStorehouseDetail = async (id) => {
        const {data} = await StorehouseApi.findStorehouseDetail(id).catch(err => {
            console.error("request storehouse detail failed", err)
        })
        return data;
    }

    /**
     * 查询仓库库存数
     * @param id
     * @returns {Promise<*>}
     */
    requestStorehouseStocksNum = async (id) => {
        const {data} = await StorehouseApi.findStockNumber({repository: id}).catch(err => {
            console.error("request storehouse stocks num failed", err)
        })
        return data
    }

    /**
     * 查询库存价值
     * @param id
     * @returns {Promise<string>}
     */
    requestStorehouseStocksWorth = async (id) => {
        const {data} = await StorehouseApi.findStockWorth({repository: id}).catch(err => {
            console.error("request storehouse stocks worth failed", err)
        })
        return (data / 10000).toFixed(3)
    }

    /**
     * 查询所有备件
     */
    requestAllStockList = (repository) => {
        StorehouseApi.findAllStockList({repository}).then(res => {
            this.setState({stockList: res.data})
        }).catch(err => {
            console.error("request all stock list failed", err)
        })
    }

    render() {
        return (<div className={styles.storehouse_map_page}>
            <PageTitle title="赣州市信创数字化仓库监控中心" backIcon/>
            <div className={styles.storehouse_map_box}>
                <div className={styles.map} id="storehouse-map-container">
                    <EdgeBorder/>
                </div>
                <div className={styles.storehouse_map_sidebar}>
                    <div className={styles.storehouse_list_box}>
                        <div>
                            <span className={styles.storehouse_list_title}>仓库列表</span>
                        </div>
                        <div className={styles.storehouse_list_item_box}>
                            {this.state.storehouseList.length > 0 ? this.state.storehouseList.map((item, index) => <div
                                className={styles.storehouse_list_item}
                                key={item.id}
                                onClick={() => this.findAddress(item)}>
                                <div className={styles.storehouse_item_index_name}>
                                    <span
                                        className={styles.storehouse_item_index}>{(index + 1).toString().padStart(2, 0)}</span>
                                    <span className={styles.storehouse_item_name}>{item.name}</span>
                                </div>
                                <div className={styles.storehouse_department_num}>
                                    <div className={styles.storehouse_img_address}>
                                        <img src={require("../../../asset/storehouse/storehouse_department_icon.png")}
                                             alt="" className={styles.storehouse_img}/>
                                        <span
                                            className={styles.storehouse_address}>所属机构：{item?.department?.name ?? "-"}</span>
                                    </div>
                                    <div className={styles.storehouse_img_stock}>
                                        <img src={require("../../../asset/storehouse/storehouse_stock_icon.png")} alt=""
                                             className={styles.storehouse_img}/>
                                        <span className={styles.storehouse_stock}>备件总数：{item?.stock}/件</span>
                                    </div>
                                </div>
                            </div>) : <RenderEmptyData/>}
                        </div>
                        <EdgeBorder/>
                    </div>
                    <div className={styles.stock_list_box}>
                        <div className={styles.stock_list_title}>
                            <span className={styles.stock_list_title_name}>备件名称</span>
                            <span className={styles.stock_list_title_type}>型号</span>
                            <span className={styles.stock_list_title_num}>数量</span>
                        </div>
                        <div className={styles.stock_list_item_box}>
                            {this.state.stockList.length > 0 ? this.state.stockList.map(item => <div
                                className={styles.stock_list_item} key={item.id}>
                                <span className={styles.stock_list_item_name}>{item?.stocktype?.name}</span>
                                <span className={styles.stock_list_item_type}>{item?.stocktype?.model}</span>
                                <span className={styles.stock_list_item_num}>{item?.count ?? 0}</span>
                            </div>) : <RenderEmptyData/>}

                        </div>
                    </div>
                </div>
            </div>
        </div>)
    }
}
