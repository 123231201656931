import React, {Component} from 'react';
import styles from "./index.module.css"
import WorkorderApi from "../../../../../api/workorder/WorkorderApi";

/**
 * @author       : AngelBeats
 * @date         : 2023-03-22 10:36:53
 * @description  : 单位页面右侧内容
 */
export default class DepartmentRightContent extends Component {

    state = {
        departmentId: "",
        workorderTotalNum: 0,
        workorderTheDispatchNum: 0,
        workorderTheReceiveNum: 0,
        workorderTheFinishNum: 0,
        workorderFinishNum: 0,
        workorderEvaluatedNum: 0,
        workorderTheEvaluatedNum: 0,
        workorderTimeoutNumber: 0
    }

    componentDidMount() {
        this.setState({departmentId: this.props.departmentId}, () => {
            this.requestAll();
        });
    }

    componentWillReceiveProps(newProps) {
        if (newProps.departmentId !== this.state.departmentId) {
            this.setState({departmentId: newProps.departmentId}, () => {
                this.requestAll();
            })
        }
    }

    /**
     * 发起所有请求
     */
    requestAll = () => {
        this.requestWorkorderTheDispatchNum();
        this.requestWorkorderTheReceiveNum();
        this.requestWorkorderTheFinishNum();
        this.requestWorkorderFinishNum();
        this.requestWorkorderEvaluatedNum();
        this.requestWorkorderTheEvaluatedNum();
        this.requestWorkorderTimeoutNum();
    }

    /**
     * 获取工单总数
     */
    requestWorkorderTotalNum = () => {
        WorkorderApi.findWorkorderNumber({clientDepartmentId: this.state.departmentId}).then(res => {
            this.setState({workorderTotalNum: res.data})
        }).catch(err => {
            console.error("request workorder total num failed", err)
        })
    }

    /**
     * 获取工单待指派数
     */
    requestWorkorderTheDispatchNum = () => {
        WorkorderApi.findUnassignedWorkorderNumber({
            clientDepartmentId: this.state.departmentId,
            converted: 0
        }).then(res => {
            this.setState({workorderTheDispatchNum: res.data})
        }).catch(err => {
            console.error("request workorder the dispatch num failed", err)
        })
    }

    /**
     * 获取待接收工单
     */
    requestWorkorderTheReceiveNum = () => {
        WorkorderApi.findWorkorderNumber({clientDepartmentId: this.state.departmentId, progress: 5}).then(res => {
            this.setState({workorderTheReceiveNum: res.data})
        }).catch(err => {
            console.error("request workorder the receive failed", err)
        })
    }

    /**
     * 获取待完成工单
     */
    requestWorkorderTheFinishNum = () => {
        WorkorderApi.findWorkorderNumber({clientDepartmentId: this.state.departmentId, progress: 13}).then(res => {
            this.setState({workorderTheFinishNum: res.data})
        }).catch(err => {
            console.error("request workorder the finish failed", err)
        })
    }

    /**
     * 获取已完成工单
     */
    requestWorkorderFinishNum = () => {
        WorkorderApi.findWorkorderNumber({clientDepartmentId: this.state.departmentId, progress: 17}).then(res => {
            this.setState({workorderFinishNum: res.data})
        }).catch(err => {
            console.error("request workorder finish failed", err)
        })
    }

    /**
     * 获取已评价工单
     */
    requestWorkorderEvaluatedNum = () => {
        WorkorderApi.findWorkorderNumber({clientDepartmentId: this.state.departmentId, feedback: true}).then(res => {
            this.setState({workorderEvaluatedNum: res.data})
        }).catch(err => {
                console.error("request workorder evaluated num failed", err)
            }
        )
    }
    /**
     * 获取未评价工单
     */
    requestWorkorderTheEvaluatedNum = () => {
        WorkorderApi.findWorkorderNumber({clientDepartmentId: this.state.departmentId, feedbackScore: 0}).then(res => {
            this.setState({workorderTheEvaluatedNum: res.data})
        }).catch(err => {
                console.error("request workorder evaluated num failed", err)
            }
        )
    }

    /**
     * 获取超期工单
     */
    requestWorkorderTimeoutNum = () => {
        WorkorderApi.findWorkorderNumber({clientDepartmentId: this.state.departmentId, delay: 1}).then(res => {
            this.setState({workorderTimeoutNumber: res.data})
        }).catch(err => {
            console.error("request workorder timeout failed", err)
        })
    }

    render() {

        const {
            workorderTotalNum,
            workorderTheDispatchNum,
            workorderTheReceiveNum,
            workorderTheFinishNum,
            workorderFinishNum,
            workorderEvaluatedNum,
            workorderTheEvaluatedNum,
            workorderTimeoutNumber
        } = this.state;

        return (<div className={styles.department_right_box}>
            <div className={styles.department_right_item}>
                <i className={styles.department_right_item_value}>{workorderFinishNum}</i>
                <span className={styles.department_right_item_label}>已完成数</span>
            </div>
            <div className={`${styles.department_right_item} ${styles.department_right_item_eva}`}>
                <i className={styles.department_right_item_value}>{workorderEvaluatedNum}</i>
                <span className={styles.department_right_item_label}>已评价数</span>
            </div>
            <div className={`${styles.department_right_item} ${styles.department_right_item_dispatch}`}>
                <i className={styles.department_right_item_value}>{workorderTheDispatchNum}</i>
                <span className={styles.department_right_item_label}>待指派</span>
            </div>
            <div className={`${styles.department_right_item} ${styles.department_right_item_receive}`}>
                <i className={styles.department_right_item_value}>{workorderTheReceiveNum}</i>
                <span className={styles.department_right_item_label}>待接收数</span>
            </div>
            <div className={`${styles.department_right_item} ${styles.department_right_item_the_finish}`}>
                <i className={styles.department_right_item_value}>{workorderTheFinishNum}</i>
                <span className={styles.department_right_item_label}>待完成数</span>
            </div>
            <div className={`${styles.department_right_item} ${styles.department_right_item_the_eva}`}>
                <i className={styles.department_right_item_value}>{workorderTheEvaluatedNum}</i>
                <span className={styles.department_right_item_label}>未评价数</span>
            </div>
            <div className={`${styles.department_right_item} ${styles.department_right_item_timeout}`}>
                <i className={styles.department_right_item_value}>{workorderTimeoutNumber}</i>
                <span className={styles.department_right_item_label}>超期工单数</span>
            </div>
        </div>)
    }
}
