import React from 'react';
import PropTypes from "prop-types"

import styles from "./index.module.css"
import {useNavigate} from "react-router";
import {throttleValve} from "../../utils/tool";

/**
 * @author       : AngelBeats
 * @date         : 2023-03-13 09:08:22
 * @description  : 页面头部
 */
const PageTitle = ({title, backIcon, backFun, exit}) => {

    const navigate = useNavigate()

    /**
     * 返回
     */
    const back = () => {
        if (throttleValve()) return;
        if (backFun) {
            backFun();
            return;
        }
        navigate(-1);
    }

    return (<div className={styles.title_div}>
        <span className={styles.title_content}>{title ?? "赣州市信创数字化工作调度中心"}</span>
        {
            backIcon ?
                <div className={styles.back_img} onClick={back}>
                    {
                        exit ?
                            <img src={require("../../asset/exit.png")} alt=""/> :
                            <img src={require("../../asset/back.png")} alt=""/>
                    }
                    <span>{exit ? "退出" : "返回"}</span>
                </div> : null

        }

    </div>);
};

PageTitle.propTypes = {
    title: PropTypes.string,
    backIcon: PropTypes.bool,
    backFun: PropTypes.func
}

export default PageTitle;
