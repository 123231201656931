import {get, post} from "../../https/index";

const DeviceApi = {
    /**
     * 获取各省份设备
     */
    province: () => get("/resource/device/count/province"),
    /**
     * 获取市数据
     * @param params
     */
    queryCity: (params = "") => get("/resource/device/count/city", params),
    /**
     * 获取区县设备信息
     * @param params
     */
    queryCounty: (params) => get(`/resource/device/count/county`, params),
    /**
     * 查询指定区县内的设备经纬度
     * @param params
     */
    queryCountyInside: (params) => get(`/resource/device/lnglats`, params),
    /**
     * 获取指定经纬度下的设备
     */
    findLnglatDevice: (params) => get("/resource/devices/page", params),

    /**
     * 获取所有设备
     * @returns {Promise<AxiosResponse<any>>}
     */
    requestAllDevice: (params) => get("/resource/device/details/list", params),

    /**
     * 查询设备详情
     * @param id
     * @returns {Promise<AxiosResponse<any>>}
     */
    findDeviceDetail: id => get(`/resource/device/${id}`),

    /**
     * 查询设备日志信息
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    findDeviceLogs: params => get("/resource/device/logs", params),

    /**
     * 查询设备所有工单
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    // TODO 接口待替换
    findAllDeviceWorkorder: params => get("/workorder/workorders/page", params),

    /**
     * 查询单位下的所有设备
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    findAllDepartmentDevice: params => get("/resource/device/details/list", params),

    /**
     * 根据设备查询设备工单
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    findDeviceWorkorder: params => post("/workorder/workorder/list", params),

    /**
     * 查询设备总数
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    findDeviceTotal: params => get("/resource/device/count", params),

    /**
     * 查询用户发布知识库数量
     * @param accountId
     * @returns {Promise<AxiosResponse<any>>}
     */
    findAccountKnowledgeNumber: accountId => get(`/resource/account/${accountId}/knowledge/count`),

    /**
     * 查询用户替换标签数
     * @param accountId
     * @returns {Promise<AxiosResponse<any>>}
     */
    findAccountReplaceLabelNumber: accountId => get(`/resource/account/${accountId}/device-label/replace-count`),

    /**
     * 查询用户迁移设备数量
     * @param accountId
     * @returns {Promise<AxiosResponse<any>>}
     */
    findAccountMoveDeviceNumber: accountId => get(`/resource/account/${accountId}/device/move-count`),

    /**
     * 批量查询单位设备
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    findAllDeviceCount: params => post("/resource/customer-departments/count", params)

};

export default DeviceApi;
