import React, {Component} from 'react';
import styles from "./index.module.css";

import close from "../../../../asset/engineer/integral_modal_close.svg"

/**
 * @author       : AngelBeats
 * @date         : 2023-04-25 14:48:48
 * @description  : 积分说明
 */
class IntegralDescription extends Component {

    state = {
        open: false
    }

    show = () => {
        this.setState({open: true})
    }

    close = () => {
        this.setState({open: false})
    }

    getIsShow = () => {
        return this.state.open;
    }

    render() {
        return (<>
            {
                this.state.open ?
                    <div className={styles.integral_box}>
                        <div className={styles.integral_close}>
                            <img src={close} alt="" className={styles.close} onClick={this.close}/>
                        </div>
                        <div className={styles.integral_content}>
                            <h3 className={styles.integral_title}>总体规则：</h3>

                            <span>系统中所有涉及到工单处理工作的账号都有积分。</span>
                            <span>积分为加分减分制，达到加分条件时触发加分，达到扣分条件时触发减分，如果总积分为负时显示0分。</span>
                            <span>积分在数据库以浮点方式存储，但在手机端不出现小数点，舍去小数只显示整数。</span>
                            <span>积分没有上限，根据时间积累可以无限得分。</span>
                            <span>积分获取规则见下面积分细则。</span>
                            <span>每天首次登录工程师端APP得3分。</span>
                            <span>每完成一个工单得1分（驳回或转派不得分）。</span>
                            <span>如果当前工单关联了设备，额外得0.5分。</span>
                            <span>如果工单完成情况合规（按时接单1个小时内接单，按时完成，在完成并回执工单时有合理的工单完成描述和贴图），额外得1分。</span>
                            <span>工单完成后，如果得到“非常满意”评价得1分，如果得到“满意”评价不得分，如果得到“不满意”评价得-3分。 </span>
                            <span>【即是说，一个工单最多可以得3.5积分，最差可以得-2分】</span>
                            <span>贡献知识库，每一条得3分。</span>
                            <span>如果自己把知识库条目删掉了，或者知识库条目不符合要求，被管理员撤掉了，扣3分。</span>
                            <span>录入一个设备得5分，迁移一个设备得3.5分，替换标签得1.5分。</span>
                            <span>特殊奖励得分，由超级管理员输入奖励积分原因，并且输入获取积分数量，进行额外积分奖励。
                                此得分必须在工程师得分明细里面，明确留有记录：“XX年X月X日，由超级管理员XXX，由【XXXXXXXXXX】原因，奖励积分XX分。”</span>
                            <span>特殊惩罚扣分，由超级管理员输入扣除积分原因，并且输入扣除积分数量，进行额外扣分惩罚。此扣分必须在工程师得分明细里面，明确留有记录：“XX年X月X日，由超级管理员XXX，由【XXXXXXXXXX】原因，扣除积分XX分。”</span>
                        </div>
                    </div>
                    : null
            }
        </>);
    }
}

export default IntegralDescription;
