import React, {Component} from 'react';

import {withNavigation} from "../../../utils/WithPlusClass";
import PageTitle from "../../../components/title/PageTitle";

import styles from "./index.module.css"
import DepartmentLeftContent from "./components/left/DepartmentLeftContent";
import DepartmentCenterContent from "./components/center/DepartmentCenterContent";
import DepartmentRightContent from "./components/right/DepartmentRightContent";
import WorkorderApi from "../../../api/workorder/WorkorderApi";

/**
 * @author       : AngelBeats
 * @date         : 2023-03-21 17:07:13
 * @description  : 单位页面
 */
class DepartmentPage extends Component {

    workorderLeftContentRef = React.createRef();

    state = {
        newWorkorder: [],
        newRequestWorkorder: [],
        lastRequestTime: ""
    }
    timer = null;

    componentDidMount() {
        this.requestNewWorkorder();
    }

    componentWillReceiveProps(newProps) {
        clearInterval(this.timer);
        this.requestNewWorkorder(null, "赣州市", newProps.useParams.departmentId);
    }

    componentWillUnmount() {
        clearInterval(this.timer)
    }

    /**
     * 查询最新工单
     */
    requestNewWorkorder = (county = null, city = "赣州市", departmentId = this.props.useParams.departmentId) => {
        WorkorderApi.findNewWorkorder({
            logicType: "smp",
            customerDepartmentId: departmentId
        }).then(res => {
            let d = new Date();
            let time = `${d.getFullYear()}-${(d.getMonth() + 1).toString().padStart(2, 0)}-${d.getDate().toString().padStart(2, 0)} ${d.getHours().toString().padStart(2, 0)}:${d.getMinutes().toString().padStart(2, 0)}:${d.getSeconds().toString().padStart(2, 0)}`
            this.setState({newWorkorder: res.data, lastRequestTime: time})
            this.timer = setInterval(() => {
                this.requestRefreshNewWorkorder(county, city);
            }, 15000)
        }).catch(err => {
            console.error(err)
        })
    }

    /**
     * 查询最新工单
     */
    requestRefreshNewWorkorder = (county, city) => {
        WorkorderApi.findNewWorkorder({
            county,
            city,
            createTime: this.state.lastRequestTime,
            customerDepartmentId: this.props.useParams.departmentId
        }).then(res => {
            let d = new Date();
            let time = `${d.getFullYear()}-${(d.getMonth() + 1).toString().padStart(2, 0)}-${d.getDate().toString().padStart(2, 0)} ${d.getHours().toString().padStart(2, 0)}:${d.getMinutes().toString().padStart(2, 0)}:${d.getSeconds().toString().padStart(2, 0)}`

            if (res.data.length > 0) {
                this.workorderLeftContentRef.current.addWorkorderMonitorTransition();
            }

            this.setState({
                newRequestWorkorder: [...res.data, ...this.state.newRequestWorkorder],
                lastRequestTime: time
            }, () => {
                setTimeout(() => {
                    this.workorderLeftContentRef.current.removeWorkorderMonitorTransition();
                    this.setState({
                        newWorkorder: [...this.state.newRequestWorkorder, ...this.state.newWorkorder],
                        newRequestWorkorder: []
                    })
                }, 8000)
            })
        }).catch(err => {
            console.error(err)
        })
    }

    /**
     * 返回
     */
    back = () => {
        if (this.props.location?.state?.showBack) {
            this.props.navigate("/customer/login");
            localStorage.removeItem("Token");
            return;
        }
        this.props.navigate(-1)
    }

    render() {
        return (<div className={styles.department_page}>
            <PageTitle backIcon exit={this.props.location?.state?.showBack} backFun={this.back} title="赣州市信创数字化区域监控中心"/>
            <div className={styles.department_content}>
                <DepartmentLeftContent newWorkorder={this.state.newWorkorder}
                                       newRequestWorkorder={this.state.newRequestWorkorder}
                                       departmentId={this.props.useParams.departmentId}
                                       ref={this.workorderLeftContentRef}/>
                <DepartmentCenterContent departmentId={this.props.useParams.departmentId}/>
                <DepartmentRightContent departmentId={this.props.useParams.departmentId}/>
            </div>
        </div>)
    }
}

export default withNavigation(DepartmentPage)
