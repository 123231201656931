import React, {Component} from 'react';
import styles from "./css/sidebar.module.css"
import {withNavigation} from "../../../../utils/WithPlusClass";
import EventBus from "../../../../utils/EventBus";
import {throttleValve} from "../../../../utils/tool";
import RenderWorkorderProgress from "../../../../components/workorder/RenderWorkorderProgress";
import deviceListBg from "../../../../asset/device/device_list_btn_bg.png"
import deviceQuestionBg from "../../../../asset/device/device_question_btn_bg.png"
import RenderLoading from "../../../../components/loading/RenderLoading";

/**
 * @author       : AngelBeats
 * @date         : 2023-03-17 08:50:18
 * @description  : 设备侧边栏列表
 */
class DeviceMapSidebarList extends Component {

    state = {
        isShowDeviceList: true,
    }

    /**
     * 查看设备列表
     */
    previewDeviceList = () => {
        if (throttleValve()) return;
        if (this.state.isShowDeviceList) return;
        this.setState({
            isShowDeviceList: true
        })
        const deviceListId = document.getElementById("device_list_id");
        const deviceQuestionId = document.getElementById("device_question_id");
        deviceListId.style.backgroundImage = `url(${deviceListBg})`
        deviceQuestionId.style.backgroundImage = `url(${deviceQuestionBg})`
        EventBus.emit("toggleDeviceMap", {type: "list"})
    }

    /**
     * 查看问题监控
     */
    previewQuestionList = () => {
        if (throttleValve()) return;
        if (!this.state.isShowDeviceList) return;
        this.setState({
            isShowDeviceList: false
        })
        const deviceListId = document.getElementById("device_list_id");
        const deviceQuestionId = document.getElementById("device_question_id");
        deviceListId.style.backgroundImage = `url(${deviceQuestionBg})`
        deviceQuestionId.style.backgroundImage = `url(${deviceListBg})`
        EventBus.emit("toggleDeviceMap", {type: "question"})
    }

    /**
     * 跳转设备详情页面
     * @param id
     */
    previewDetail = id => {
        this.props.navigate("/home/device-detail", {state: {deviceId: id}})
    }

    /**
     *  跳转工单详情页面
     * @param item
     */
    previewWorkorderDetail = item => {
        this.props.navigate("/home/workorder/detail", {state: {workorderId: item.id}})
    }

    /**
     * 重置列表数据
     */
    refreshList = () => {
        if (throttleValve()) return;
        if (this.state.isShowDeviceList) {
            this.props.refreshDeviceList();
            return;
        }
        this.props.refreshDeviceQuestionList();
    }

    render() {
        return (<div className={styles.sidebar_list_box}>
            <div className={styles.list_btn_box}>
                <div className={styles.device_list_btn_box} onClick={this.previewDeviceList} id="device_list_id">
                    <span className={styles.device_list_btn}>设备列表</span>
                </div>
                <div className={styles.device_question_btn_box} onClick={this.previewQuestionList}
                     id="device_question_id">
                    <span className={styles.device_question_btn}>问题监控</span>
                </div>
                <img src={require("../../../../asset/device/device_refresh_btn.png")} alt=""
                     className={styles.device_refresh_btn} onClick={this.refreshList}/>
            </div>
            {
                this.state.isShowDeviceList ?
                    <div className={styles.device_list_box}>
                        {
                            this.props.deviceList.map(item =>
                                <div className={styles.device_list_item} key={item.id}
                                     onClick={() => this.previewDetail(item.id)}>
                                    <div className={styles.device_department_client}>
                                        <span className={styles.device_list_item_style}>
                                            所属客户：{item.customer?.name}
                                        </span>
                                        <span
                                            className={styles.device_list_item_style}>
                                            所属单位：{item.customer?.department?.name}
                                        </span>
                                    </div>
                                    <div className={styles.device_name_repair}>
                                        <span className={styles.device_list_item_style}>设备名称：{item.name}</span>
                                        <span className={styles.device_list_item_style}>维护次数：{item.count}</span>
                                    </div>
                                    <div className={styles.device_type}>
                                        <span className={styles.device_list_item_style}
                                              style={{width: "100%"}}>设备型号：{item.model}</span>
                                    </div>
                                </div>)
                        }
                        <RenderLoading isLoading={this.props.deviceListLoading} height="75vh"/>
                    </div> :
                    <div className={styles.device_list_box}>
                        {this.props.deviceQuestionList.map(item => <div
                            className={`${styles.device_list_item} ${styles.device_question_list_item}`} key={item.id}
                            onClick={() => this.previewWorkorderDetail(item)}>
                            <div className={styles.device_department_client}>
                                <span
                                    className={`${styles.device_list_item_style}  ${styles.device_question_item_style}`}>发起单位：{item?.clientDepartmentName ?? "-"}</span>
                                <span
                                    className={`${styles.device_list_item_style}  ${styles.device_question_item_style}`}>发起客户：{item?.clientName ?? "-"}</span>
                            </div>
                            <div className={styles.device_name_repair}>
                                <span
                                    className={`${styles.device_list_item_style} ${styles.device_question_item_style}`}>设备名称：{item?.resourceName ?? "-"}</span>
                                <span
                                    className={`${styles.device_list_item_style} ${styles.device_question_item_style}`}>当前状态：{RenderWorkorderProgress(item.progress)}</span>
                            </div>
                            <div className={styles.device_name_repair}>
                                <span
                                    className={`${styles.device_list_item_style} ${styles.device_question_item_style}`}>设备型号：{item?.resourceType ?? "-"}</span>
                                <span
                                    className={`${styles.device_list_item_style} ${styles.device_question_item_style}`}>接单工程师：{item?.handlerName}</span>
                            </div>
                            <div
                                className={`${styles.device_question_descriptions} ${styles.device_question_item_style} ${styles.device_list_item_style}`}>
                                <span>问题描述：{item?.description === "" ? "-" : item?.description}</span>
                            </div>
                        </div>)}
                        <RenderLoading isLoading={this.props.questionLoading} height="75vh"/>
                    </div>
            }
        </div>)
    }
}

export default withNavigation(DeviceMapSidebarList)
