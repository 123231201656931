import {get} from "../../https";

const CustomerApi = {

    /**
     * 查询客户总数
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    findCustomerTotal: params => get("/customer/customer/statistics/count", params),

    /**
     * 查询客户详细信息
     * @param customerId
     * @returns {Promise<AxiosResponse<any>>}
     */
    findCustomer: customerId => get(`/customer/customer/${customerId}`)
}

export default CustomerApi;
