import {useLocation, useNavigate, useParams} from "react-router";
import React, {forwardRef} from "react"

/**
 * 增强类组件
 * @param Component
 * @returns {function(*)}
 */
export const withNavigation = (Component) => {
    class StrengthenClass extends React.Component {
        render() {
            const {forwardedRef, navigate, location, useParams, ...rest} = this.props;
            // 将自定义的 prop 属性 “forwardedRef” 定义为 ref
            return <Component ref={forwardedRef} {...rest} navigate={navigate} location={location}
                              useParams={useParams}/>;
        }
    }

    return forwardRef((props, ref) => <StrengthenClass {...props} navigate={useNavigate()} location={useLocation()}
                                                       forwardedRef={ref} useParams={useParams()}/>);
};
