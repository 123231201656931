import React, {Component} from 'react';
import styles from "./index.module.css"
import PageTitle from "../../../components/title/PageTitle";
import WorkorderNumerical from "../workorder/components/numerical/WorkorderNumerical";
import EventBus from "../../../utils/EventBus";
import WorkorderApi from "../../../api/workorder/WorkorderApi";
import AreaLeftContent from "./components/left/AreaLeftContent";
import AreaCenterContent from "./components/center/AreaCenterContent";
import AreaRightContent from "./components/right/AreaRightContent";

/**
 * @author       : AngelBeats
 * @date         : 2023-03-20 13:42:30
 * @description  : 工作调度页面
 */
export default class AreaMonitor extends Component {

    state = {
        newWorkorder: []
    }

    componentDidMount() {
        EventBus.on("toggleCountyData", ({cityName}) => {
            console.log(cityName)
        });
        this.requestNewWorkorder();
    }

    /**
     * 查询最新工单
     */
    requestNewWorkorder = (county = null, city = "赣州市") => {
        WorkorderApi.findNewWorkorder({county, city}).then(res => {
            this.setState({newWorkorder: res.data})
        }).catch(err => {
            console.error(err)
        })
    }

    render() {
        return (<div className={styles.workorder_page}>
            <PageTitle title="赣州市信创数字化区域监控中心" backIcon/>
            <div className={styles.workorder_page_content}>
                <WorkorderNumerical/>
                <div className={styles.left_center_right}>
                    <AreaLeftContent/>
                    <AreaCenterContent/>
                    <AreaRightContent/>
                </div>
            </div>
        </div>)
    }
}
