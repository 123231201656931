import React from 'react';
import PropTypes from "prop-types"
import styles from "./index.module.css"
const TextNum = ({Title,Num}) => {

    return(
        <div className={styles.text_num}>
            <div className={styles.num}>{Num}</div>
            <div className={styles.title}>{Title}</div>
        </div>
    )
}

TextNum.propTypes = {
    Title: PropTypes.string,
    Num: PropTypes.string,
}

export default TextNum;