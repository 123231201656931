import React, {Component} from 'react';

import styles from "./css/center.module.css"
import WorkorderApi from "../../../../../api/workorder/WorkorderApi";
import EdgeBorder from "../../../../../components/box/EdgeBorder";

/**
 * @author       : AngelBeats
 * @date         : 2023-03-14 10:49:27
 * @description  : 工程师详情页面中间内容
 */
export default class DetailCenterContent extends Component {

    state = {
        toBeReceivedWorkorderNumber: 0,
        toBeHandleWorkorderNumber: 0,
        timeoutWorkorderNumber: 0,
        workorderNumberToThirtyDay: 0,
        workorderTimeToThirtyDay: 0,
        workStateTimeToThirtyDay: 0,
        ongoingWorkorder: {},
    }

    componentDidMount() {
        this.requestAccountToBeReceivedWorkorderNumber();
        this.requestAccountToBeHandleWorkorderNumber();
        this.requestAccountTimeoutWorkorderNumber();
        this.requestAccountOngoingWorkorder();
        this.requestAccountWorkorderNumberToThirtyDay();
        this.requestAccountWorkorderTimeToThirtyDay();
        this.requestAccountWorkStateTimeToThirtyDay();
    }

    /**
     * 获取用户待接收工单数
     */
    requestAccountToBeReceivedWorkorderNumber = () => {
        WorkorderApi.findWorkorderNumber({handlerId: this.props.accountId, progress: 5}).then(res => {
            this.setState({toBeReceivedWorkorderNumber: res.data})
        }).catch(err => {
            console.error("request account to be received workorder number failed", err)
        })
    }

    /**
     * 获取用户待处理工单数
     */
    requestAccountToBeHandleWorkorderNumber = () => {
        WorkorderApi.findWorkorderNumber({handlerId: this.props.accountId, progress: 9}).then(res => {
            this.setState({toBeHandleWorkorderNumber: res.data})
        }).catch(err => {
            console.error("request account to be handle workorder number failed", err)
        })
    }

    /**
     * 获取用户过期工单数
     */
    requestAccountTimeoutWorkorderNumber = () => {
        WorkorderApi.findDelayedWorkorder({handlerId: this.props.accountId, delay: 1}).then(res => {
            this.setState({timeoutWorkorderNumber: res.data})

        }).catch(err => {
            console.error("request account timeout workorder number failed", err)
        })
    }

    /**
     * 请求用户正在进行的工单
     */
    requestAccountOngoingWorkorder = () => {
        WorkorderApi.ongoingWorkorder(this.props.accountId).then(res => {
            this.setState({ongoingWorkorder: res.data})
        }).catch(err => {
            console.error("request account ongoing workorde failed", err)
        })
    }

    /**
     * 获取用户近三十天处理工单数
     */
    requestAccountWorkorderNumberToThirtyDay = () => {
        WorkorderApi.findAccountWorkorderNumberToThirtyDay(this.props.accountId).then(res => {
            this.setState({workorderNumberToThirtyDay: res.data})
        }).catch(err => {
            console.error("request account workorde number to thirty day failed", err)
        })
    }

    /**
     * 获取用户近三十天处理工单时长
     */
    requestAccountWorkorderTimeToThirtyDay = () => {
        WorkorderApi.findAccountWorkorderTimeToThirtyDay(this.props.accountId).then(res => {
            this.setState({workorderTimeToThirtyDay: (res.data / 3600).toFixed(2)})
        }).catch(err => {
            console.error("request account workorder time to thirty day failed", err)
        })
    }

    /**
     * 获取用户近三十天工作状态时长
     */
    requestAccountWorkStateTimeToThirtyDay = () => {
        WorkorderApi.findAccountWorkStateTimeToThirtyDay(this.props.accountId).then(res => {
            this.setState({workStateTimeToThirtyDay: (res.data / 3600).toFixed(2)})
        }).catch(err => {
            console.error("request account workorder time to thirty day failed", err)
        })
    }

    render() {
        const {
            toBeReceivedWorkorderNumber,
            toBeHandleWorkorderNumber,
            timeoutWorkorderNumber,
            ongoingWorkorder,
            workorderNumberToThirtyDay,
            workorderTimeToThirtyDay,
            workStateTimeToThirtyDay
        } = this.state
        return (<div className={styles.engineer_center_view}>
            <div className={styles.index_card_view}>
                <div className={styles.the_receive_workorder}>
                    <span className={styles.index_card_num}>{toBeReceivedWorkorderNumber}</span>
                    <span className={styles.index_card_label}>待接收工单</span>
                </div>
                <div className={styles.the_handle_workorder}>
                    <span className={styles.index_card_num}>{toBeHandleWorkorderNumber}</span>
                    <span className={styles.index_card_label}>待处理工单</span>
                </div>
                <div className={styles.on_time_workorder}>
                    <span className={styles.index_card_num}>{timeoutWorkorderNumber}</span>
                    <span className={styles.index_card_label}>超时工单</span>
                </div>
            </div>
            <div className={styles.engineer_work_detail_view}>
                <div className={styles.engineer_work_detail_item}>
                    <img src={require("../../../../../asset/engineer/detail/engineer_address_img.png")} alt=""
                         className={styles.engineer_work_item_img}/>
                    <div className={styles.engineer_work_detail_content}>
                        <span
                            className={styles.engineer_work_detail_content_title}>{ongoingWorkorder === null ? "暂无工单" : ongoingWorkorder.clientDepartmentAddress}</span>
                        <span className={styles.engineer_work_detail_content_value}>正在处理工单</span>
                    </div>
                </div>
                <div className={styles.engineer_work_detail_item}>
                    <img src={require("../../../../../asset/engineer/detail/work_time_icon.png")} alt=""
                         className={styles.engineer_work_item_img}/>
                    <div className={styles.engineer_work_detail_content}>
                        <span className={styles.engineer_work_detail_content_title}>{workStateTimeToThirtyDay}(小时)</span>
                        <span className={styles.engineer_work_detail_content_value}>近30天工作状态时长</span>
                    </div>
                </div>
                <div className={styles.engineer_work_detail_item}>
                    <img src={require("../../../../../asset/engineer/detail/workorder_num_icon.png")} alt=""
                         className={styles.engineer_work_item_img}/>
                    <div className={styles.engineer_work_detail_content}>
                        <span className={styles.engineer_work_detail_content_title}>{workorderNumberToThirtyDay}</span>
                        <span className={styles.engineer_work_detail_content_value}>近30天处理工单数</span>
                    </div>
                </div>
                <div className={styles.engineer_work_detail_item}>
                    <img src={require("../../../../../asset/engineer/detail/workorder_time_icon.png")} alt=""
                         className={styles.engineer_work_item_img}/>
                    <div className={styles.engineer_work_detail_content}>
                        <span className={styles.engineer_work_detail_content_title}>{workorderTimeToThirtyDay}(小时)</span>
                        <span className={styles.engineer_work_detail_content_value}>近30天处理工单时长</span>
                    </div>
                </div>
                <EdgeBorder/>
            </div>
        </div>)
    }
}
