import React from 'react';
import styles from "./index.module.css";
import PropTypes from "prop-types"
const Logos = ({title}) => {

    const logo = {
        "光电通": <img src={require("../../../../../asset/gdtlogo.png")} className={styles.logo} alt="光电通" />,
        "超越申泰": <img src={require("../../../../../asset/caoyuelogo.png")} className={styles.logo} alt="超越申泰" />,
        "北京计算机技术及应用研究所": <img src={require("../../../../../asset/hantianlogo.png")} className={styles.logo} alt="北京计算机技术及应用研究所" />,
    }
    return (
        <>
            { logo[title] || <div className={styles.logotext}>
                {title}
            </div> }
        </>
    )
}
Logos.propTypes = {
    title: PropTypes.string,
 }
export default Logos;