import React, {Component} from 'react';
import styles from "./index.module.css"
import EdgeBorder from "../../../../../components/box/EdgeBorder";
import * as echarts from "echarts";
import RenderWorkorderProgress from "../../../../../components/workorder/RenderWorkorderProgress";
import {withNavigation} from "../../../../../utils/WithPlusClass";
import WorkorderApi from "../../../../../api/workorder/WorkorderApi";
import RenderWorkorderServiceType from "../../../../../components/workorder/RenderWorkorderServiceType";
import RenderEmptyData from "../../../../../components/empty/RenderEmptyData";

/**
 * @author       : AngelBeats
 * @date         : 2023-03-22 09:34:59
 * @description  : 单位页面左侧内容
 */
class DepartmentLeftContent extends Component {

    departmentChart;

    state = {
        departmentId: "",
        departmentWorkorderQuestionDataSource: [],
        chartMaxCount: []
    }

    componentDidMount() {
        this.setState({departmentId: this.props.departmentId}, () => {
            this.requestDepartmentWorkorderQuestion();
        })
    }

    componentWillReceiveProps(newProps) {
        if (newProps.departmentId !== this.state.departmentId) {
            this.setState({departmentId: newProps.departmentId}, () => {
                this.requestDepartmentWorkorderQuestion();
            })
        }
    }

    // componentWillUnmount() {
    //     this.departmentChart.dispose()
    // }

    /**
     * 查询单位工单问题分布情况
     */
    requestDepartmentWorkorderQuestion = () => {
        WorkorderApi.findDepartmentWorkorderQuestion({clientDepartmentId: this.state.departmentId}).then(res => {
            this.init();
            let maxCount = 0;
            res.data.forEach(item => {
                maxCount += item.count
            })
            this.setState({
                departmentWorkorderQuestionDataSource: res.data.map(item => {
                    if (item.resourceName === "" || item.resourceType === "") {
                        return {
                            name: RenderWorkorderServiceType(item.type),
                            value: item.count
                        }
                    } else {
                        return {
                            name: item.resourceName + "-" + item.resourceType,
                            value: item.count
                        }
                    }
                }),
                chartMaxCount: res.data.map(() => maxCount)
            }, () => {
                if (res.data.length !== 0) {
                    this.departmentChart.setOption(this.options());
                    let autoHeight = this.state.departmentWorkorderQuestionDataSource.length * 25 + 50;
                    this.departmentChart.getDom().style.height = autoHeight + "px";
                    this.departmentChart.resize();
                } else {
                    this.departmentChart.dispose();
                }
            })
        }).catch(err => {
            console.error("request department workorder question failed", err)
        })
    }

    /**
     * chart配置项
     */
    options = () => {
        const YDataText = this.state.departmentWorkorderQuestionDataSource.map(item => {
            if (item.name.length > 22) {
                return `${item.name.slice(0, 22)}...`
            }
            return item.name
        })
        return {
            grid: [
                {
                    left: 70,
                    top: 10,
                    right: 10,
                    bottom: 10,
                    // containLabel: true,
                },
            ],
            xAxis: [
                {
                    gridIndex: 0,
                    type: 'value',
                    axisLabel: {show: false},
                    axisLine: {show: false},
                    splitLine: {show: false},
                    axisTick: {show: false},
                },
            ],
            yAxis: [
                {
                    type: 'category',
                    inverse: true, // 倒叙，false会从小到大排
                    axisLine: {
                        show: false // 不显示坐标轴轴线
                    },
                    axisTick: {
                        show: false // 不显示刻度线
                    },
                    offset: -20,
                    zlevel: 100,
                    position: 'left',
                    axisLabel: {
                        color: '#FFFFFF',
                        align: 'left',
                        fontSize: 10,
                        display: "inline-block",
                        verticalAlign: "middle",
                        transform: "translateY(-10px)"
                    },
                    data: YDataText
                },
                {
                    gridIndex: 0,
                    type: 'category',
                    inverse: true,
                    position: 'left',
                    data: this.state.departmentWorkorderQuestionDataSource,
                    axisTick: {show: false},
                    axisLine: {show: false},
                    splitLine: {show: false},
                    axisLabel: {
                        width: 80,
                        padding: [0, 0, 0, -60],
                        align: 'left',
                        formatter: (_, index) => {
                            const id = index + 1;
                            if (id > 0) {
                                return `{img|}{count|${id}}`;
                            }
                        },
                        rich: {
                            count: {
                                padding: [6, 0, 0, 0],
                                width: 25,
                                height: 23,
                                color: '#fff',
                                align: 'center',
                                fontSize: 15,
                                fontFamily: 'DIN',
                                fontWeight: 500,
                            },
                            img: {
                                padding: [0, 0, 0, 0],
                                width: 25,
                                height: 23,
                                color: '#fff',
                                align: 'center',
                                fontSize: 15,
                                fontFamily: 'DIN',
                                fontWeight: 500,
                                backgroundColor: {
                                    image: require("../../../../../asset/department/department_chart_icon.png")
                                }
                            },
                            name: {
                                width: 80,
                                fontSize: 15,
                                align: 'left',
                                color: '#fff',
                                fontFamily: 'Source Han Sans CN',
                                fontWeight: 500,
                            },
                        },
                    },
                },
                {
                    gridIndex: 0,
                    type: 'category',
                    position: 'right',
                    inverse: true,
                    data: this.state.departmentWorkorderQuestionDataSource.map((item) => item.name),
                    axisTick: {show: false},
                    axisLine: {show: false},
                    splitLine: {show: false},
                    zlevel: 999999,
                    axisLabel: {
                        padding: [4, 45, 0, 0],
                        align: 'right',
                        formatter: (_, index) => {
                            if (this.state.departmentWorkorderQuestionDataSource[index].value) {
                                return `{value|${this.state.departmentWorkorderQuestionDataSource[index].value}}`;
                            }
                        },
                        rich: {
                            value: {
                                color: '#fff',
                                fontSize: 10,
                            },
                        },
                    },
                },
            ],
            series: [
                {
                    type: 'bar',
                    xAxisIndex: 0,
                    yAxisIndex: 0,
                    barWidth: 20,
                    data: this.state.departmentWorkorderQuestionDataSource.map((item) => item.value),
                    silent: true,
                    cursor: "default",
                    emphasis: {
                        itemStyle: {
                            borderRadius: [0, 20, 20, 0],
                        }
                    },
                    itemStyle: {
                        borderRadius: [0, 0, 0, 0],
                        color: new echarts.graphic.LinearGradient(
                            0,
                            0,
                            1,
                            0,
                            [
                                {
                                    offset: 0,
                                    color: '#0E487A', // 0% 处的颜色
                                },
                                {
                                    offset: 1,
                                    color: '#0CABFD', // 100% 处的颜色
                                },
                            ],
                            false
                        ),
                    },
                },
                {
                    name: '背景',
                    type: 'bar',
                    barWidth: 20,
                    barGap: '-100%',
                    data: this.state.chartMaxCount,
                    itemStyle: {
                        color: 'rgba(11,62,118, 0.5)',
                        // borderRadius: [30, 30, 30, 30],
                    },
                    cursor: "default",
                },
            ],
        }

    }

    /**
     * 初始化chart
     */
    init = () => {
        this.departmentChart = echarts.init(document.getElementById("department_left_chart"));
    }

    /**
     * 工单监控点击事件
     * @param index
     * @param item
     */
    itemClick = (index, item) => {
        this.props.navigate("/home/workorder/detail", {state: {workorderId: item.id}})
        // const itemDom = document.getElementById(item.id);
        // const parentDom = document.getElementById("workorder_monitor_list");
        // let top = itemDom.offsetTop - parentDom.scrollTop + parentDom.offsetTop
        // console.log(top)
        // if (top <= 32) {
        //     top -= 60
        // }
        // if (top > 32 && top <= 113) {
        //     top -= 20
        // }
        // if (top > 113 && top <= 147) {
        //     top -= 10
        // }
        //
        // if (top >= 147 && top <= 194) {
        //     top -= -5
        // }
        //
        // if (top > 194 && top <= 400) {
        //     top -= -25
        // }
        //
        // this.workorderDetailDialogRef.current.show(top);
    }

    /**
     * 渲染工单进度字体颜色
     * @param progress
     * @returns {string}
     */
    renderWorkorderProgressColor = (progress) => {
        let result = {
            0: "#FF0000",
            1: "#00FFE1",
            5: "#F955FF",
            9: "#FFEF39",
            13: "#39A0FF",
            14: "#17C2FE",
            17: "#39FF71"
        }
        return result[progress] ?? "#FF0000";
    }

    /**
     * 移除工单监控动画
     */
    removeWorkorderMonitorTransition = () => {
        const moreWorkorder = document.getElementById("more_workorder");
        moreWorkorder.style.transition = "none"
    }

    /**
     * 添加工单监控动画
     */
    addWorkorderMonitorTransition = () => {
        const moreWorkorder = document.getElementById("more_workorder");
        moreWorkorder.style.transition = "all 1s ease-in-out"
    }

    render() {
        return (<div className={styles.department_left_content}>
            <div className={styles.workorder_monitor_box}>
                <div className={styles.workorder_monitor_title} id="workorder_monitor_title" onClick={() => {
                    console.log(this.props.newWorkorder);
                }}>
                    工单动态监控
                </div>
                {
                    this.props.newWorkorder.length > 0 ?
                        <div className={styles.workorder_monitor_list} id="workorder_monitor_list">
                            <div className={styles.moreWorkorder} id="more_workorder"
                                 style={this.props.newRequestWorkorder.length > 0 ? {marginTop: 0} : null}>
                                {this.props.newRequestWorkorder.map((item, index) => <div
                                    className={styles.workorder_monitor_item}
                                    id={item.id}
                                    key={item.id}
                                    onClick={() => this.itemClick(index, item)}>
                                    <div className={styles.workorder_monitor_item_title}>
                            <span
                                className={styles.workorder_monitor_item_title_name}>{item?.clientDepartmentName}</span>
                                        <span className={styles.workorder_monitor_item_title_type}
                                              style={{color: this.renderWorkorderProgressColor(item?.progress)}}>{RenderWorkorderProgress(item?.progress)}</span>
                                    </div>
                                    <div className={styles.workorder_monitor_create_handle_name}>
                                        <span
                                            className={styles.workorder_monitor_create_name}>发起人：{item?.creatorName}</span>
                                        <span
                                            className={styles.workorder_monitor_handle_name}>处理人：{item?.handlerName}</span>
                                    </div>
                                    <div className={styles.workorder_monitor_create_time}>
                                        <span>发起时间：{item?.createTime}</span>
                                    </div>
                                </div>)}
                            </div>
                            {this.props.newWorkorder.map((item, index) => <div className={styles.workorder_monitor_item}
                                                                               id={item.id}
                                                                               key={item.id}
                                                                               onClick={() => this.itemClick(index, item)}>
                                <div className={styles.workorder_monitor_item_title}>
                            <span
                                className={styles.workorder_monitor_item_title_name}>{item?.clientDepartmentName}</span>
                                    <span className={styles.workorder_monitor_item_title_type}
                                          style={{color: this.renderWorkorderProgressColor(item?.progress)}}>{RenderWorkorderProgress(item?.progress)}</span>
                                </div>
                                <div className={styles.workorder_monitor_create_handle_name}>
                                    <span
                                        className={styles.workorder_monitor_create_name}>发起人：{item?.creatorName}</span>
                                    <span
                                        className={styles.workorder_monitor_handle_name}>处理人：{item?.handlerName}</span>
                                </div>
                                <div className={styles.workorder_monitor_create_time}>
                                    <span>发起时间：{item?.createTime}</span>
                                </div>
                            </div>)}
                            {/*{this.props.newWorkorder.length < 0 ? <RenderEmptyData/> : null}*/}
                        </div>
                        : <RenderEmptyData/>
                }

                <EdgeBorder/>
            </div>
            <div className={styles.department_left_chart_box}>
                <div id="department_left_chart" className={styles.department_left_chart}/>
                {this.state.departmentWorkorderQuestionDataSource.length > 0 ? null :
                    <div className={styles.department_left_chart_empty}>
                        <RenderEmptyData/>
                    </div>}
                <EdgeBorder/>
            </div>
        </div>)
    }
}

export default withNavigation(DepartmentLeftContent)
