import React, {Component} from "react";
import styles from "./index.module.css";
import PropTypes from "prop-types"
import * as echarts from "echarts";
import ManuFacturerApi from "../../../../../api/manufacturer";
class DeviceFailureEcharts extends Component {

    static defaultProps = {
        refId:'',
        point:0
    }

    faultChart;
    faultChartRef = React.createRef();
    state = {
        device_failure_data:{},
        faultChance: []
    }

    componentDidMount() {
        this.init();
        this.requestFaultChance(this.props.refId) // 获取设备故障类型数据
    }
    componentWillUnmount() {
        // 销毁图表实例
        this.faultChart.dispose(); // 销毁图表实例
    }
    /**
     * 获取设备故障类型数据
     * @param id
     */
    requestFaultChance = (id = null) => {
        ManuFacturerApi.pointdetail(id).then(res=> {
            if (res.status === 20000) {
                res.data.map(item => {
                    if (item.type === 0){
                        this.setState({device_failure_data:item},()=>{
                            // 默认选择第一项
                            if(item.lists.length > 1){
                                this.props.onUpdate({
                                    name: item.lists[0].name,
                                    deviceTypeId: item.lists[0].refId,
                                    refId: item.lists[0].id
                                })
                            }
                            this.faultChart.setOption(this.pieOptions(this.state.device_failure_data))
                            this.addClickHandler()
                        })
                    }
                })
            }
        })
    }

    /**
     * 构造饼图图表配置项
     */
    pieOptions = (data) => (
        {
            color: ['#366fff','#ee6666', '#fac858', '#90ca74', '#d48265', '#91c7ae'],
            title : {
                //text: data.type === 0 ? "设备故障类型" : data.type === 1 ? "工单响应情况" : "工单满意情况",
                x:"0%",
                y:'5%',
                textStyle:{
                    fontSize: 12,
                    fontWeight: 200,
                    color: '#fff'
                },
            },
            tooltip : {
                trigger: "item",
                formatter: "{b} : {c} ({d}%)"
            },
            calculable : true,
            series : [
                {
                    //name:'访问来源',
                    type:'pie',
                    radius : '50%',
                    center: ['45%', '50%'],

                    label: {
                        alignTo: 'edge',
                        formatter: '{d}%\n{name|{b}}',
                        minMargin: 1,
                        edgeDistance: 10,
                        lineHeight: 15,
                        color: "#FFFFFF",
                        rich: {

                        }
                    },

                    data:data.lists.map(item=>{
                        return {
                            id: item.id,
                            refId: item.refId,
                            name: item.name,
                            value: item.num
                        }
                    }) || []
                }
            ]
        }
    )

    // 图表添加点击事件
    addClickHandler = () => {
        this.faultChart.on('click', (params) => {
           // 打印点击事件的参数
            this.props.onUpdate({
                name: params.data.name,
                deviceTypeId: params.data.refId,
                refId: params.data.id
            })
        });
    };
    init = () => {
        // 初始化图表实例
        this.faultChart = echarts.init(this.faultChartRef.current);

    }
    render() {
        return (
            <div className={styles.text_num_pie_echarts_box}>
                <div className={styles.text_num}>
                    <div className={styles.titTag}>{ "设备故障分"}</div>
                    <div className={styles.titNum}>{this.props.point}</div>
                    <div className={styles.tittip}>{ "设备故障"}分（总分70）</div>
                </div>
                {Object.keys(this.state.device_failure_data).length === 0 ?
                    <div ref={this.faultChartRef} className={styles.no_pie_echarts}/> :
                    <div ref={this.faultChartRef} className={styles.pie_echarts}/>}

            </div>
        )
    }
}

DeviceFailureEcharts.propTypes = {
    refId: PropTypes.string,
    point: PropTypes.number,
}

export default DeviceFailureEcharts;