import {get} from "../../https";

const OrganizationApi = {
    /**
     * 查询服务机构数量
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    findOrganizationNumber: params => get("/user/department/statistics/count", params),

    /**
     * 查询机构详情
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    findOrganizationDetail: params => get("/user/department", params),

    /**
     * 查询机构平均积分
     * @param organizationId
     * @returns {Promise<AxiosResponse<any>>}
     */
    findOrganizationAvgScore: organizationId => get(`/user/department/${organizationId}/points-avg`)
}

export default OrganizationApi;
