import React, {useEffect, useRef, useState} from 'react';
import PropTypes from "prop-types"
import styles from "./index.module.css";

import FactuerTitle from "../FactuerTitle";
import {Col, Row} from "antd";
import TextNum from "../TextNum";
import TitleNumInfo from "../TitleNumInfo";
import CaseEcharts from "../CaseEcharts";
import SpecimanuDialog from "../SpecimanuDialog";
const ManuFacturerItem = ({PointData}) => {

    const SpecimanuDialogRef = useRef( null);

    const hideSpecimanu = () => {
        if(SpecimanuDialogRef.current){
            SpecimanuDialogRef.current.show()
        }
    }

    return(
        <div className={styles.manu_facturer_item}>
           <FactuerTitle title={PointData.name} id={PointData.refId} />
            <div className={styles.text_num_box}>
                <Row gutter={16}>
                    <Col span={6}><TextNum Title={"已录入设备类型量"} Num={PointData.deviceTypeNum}/></Col>
                    <Col span={6}><TextNum Title={"已录入设备总量"} Num={PointData.deviceNum}/></Col>
                    <Col span={6}><TextNum Title={"已出故障工单总量"} Num={PointData.faultWorkorderNum}/></Col>
                    <Col span={6}><TextNum Title={"已出故障设备总量"} Num={PointData.faultDeviceNum}/></Col>
                </Row>
            </div>
            <div className={styles.grade_fault_box}>
                <Row>
                    <Col span={12}>
                        <div className={styles.grade}>
                            <img src={require("../../../../../asset/pfico.png")} alt="评分"/>
                            <div className={styles.text_num} onClick={hideSpecimanu}>
                                <TitleNumInfo Num={String(PointData.totalPoint?.toFixed(0))} Title={"厂商评分"} Tag={"评分说明"}/>
                            </div>
                            <SpecimanuDialog ref={SpecimanuDialogRef}/>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className={styles.fault}>
                            <img src={require("../../../../../asset/gzico.png")} alt="故障"/>
                            <div className={styles.text_num}>
                                <TitleNumInfo Num={ (PointData.faultRate * 100).toFixed(2) + '%'} Title={"故障率"}/>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
            <CaseEcharts refId={PointData.refId}/>
        </div>
    )
}

ManuFacturerItem.propTypes = {
    PointData: PropTypes.object,
}

export default ManuFacturerItem;