import React, {Component} from 'react';
import styles from "./index.module.css"
import defaultAvatar from "../../../../../asset/defaultAvatar.jpeg"
import EventBus from "../../../../../utils/EventBus";
import DepartmentApi from "../../../../../api/department/DepartmentApi";
import CustomerApi from "../../../../../api/customer/CustomerApi";
import OrganizationApi from "../../../../../api/organization/OrganizationApi";
import AccountApi from "../../../../../api/account/AccountApi";
import DeviceApi from "../../../../../api/device/DeviceApi";
import WorkorderApi from "../../../../../api/workorder/WorkorderApi";
import RenderAccountWorkState from "../../../../../components/account/RenderAccountWorkState";
import {withNavigation} from "../../../../../utils/WithPlusClass";
import RenderLoading from "../../../../../components/loading/RenderLoading";
import RenderEmptyData from "../../../../../components/empty/RenderEmptyData";

/**
 * @author       : AngelBeats
 * @date         : 2023-03-20 14:21:39
 * @description  : 区域监控左边内容
 */
class AreaLeftContent extends Component {

    state = {
        departmentNumber: "加载中...",
        customerNumber: "加载中...",
        serviceDepartmentNumber: "加载中...",
        accountNumber: "加载中...",
        deviceNumber: "加载中...",
        workorderNumber: "加载中...",
        engineerList: [],
        loading: false
    }

    componentDidMount() {
        this.requestAllData();
        EventBus.on("toggleAreaCountyData", ({cityName}) => {
            if (cityName === "赣州市") {
                this.requestAllData();
                return;
            }
            this.requestAllData(cityName);
        })
    }

    /**
     * 请求所有数据
     * @param cityName
     */
    requestAllData = (cityName = null) => {
        this.requestDepartmentNumber(cityName);
        this.requestCustomerNumber(cityName);
        this.requestServiceDepartmentNumber(cityName);
        this.requestAccountNumber(cityName);
        this.requestDeviceNumber(cityName);
        this.requestWorkorderNumber(cityName);

        this.requestAllEngineerList(cityName);
    }

    /**
     * 查询单位数量
     * @param county
     */
    requestDepartmentNumber = (county = null) => {
        DepartmentApi.findDepartmentNumber({city: "赣州市", county}).then(res => {
            this.setState({departmentNumber: res.data})
        }).catch(err => {
            console.error("request department number failed", err)
        })
    }

    /**
     * 查询客户数量
     * @param county
     */
    requestCustomerNumber = (county = null) => {
        CustomerApi.findCustomerTotal({city: "赣州市", county}).then(res => {
            this.setState({
                customerNumber: res.data
            })
        }).catch(err => {
            console.error("request customer number failed", err)
        })
    }

    /**
     * 查询服务商数量
     * @param county
     */
    requestServiceDepartmentNumber = (county = null) => {
        OrganizationApi.findOrganizationNumber({city: "赣州市", county}).then(res => {
            this.setState({
                serviceDepartmentNumber: res.data
            })
        }).catch(err => {
            console.error("request service department number failed", err)
        })
    }

    /**
     * 查询工程师数量
     * @param county
     */
    requestAccountNumber = (county = null) => {
        AccountApi.findAccountNumber({city: "赣州市", county}).then(res => {
            this.setState({
                accountNumber: res.data
            })
        }).catch(err => {
            console.error("request account number failed", err)
        })
    }

    /**
     * 查询设备数量
     * @param county
     */
    requestDeviceNumber = (county = null) => {
        DeviceApi.findDeviceTotal({city: "赣州市", county}).then(res => {
            this.setState({
                deviceNumber: res.data
            })
        }).catch(err => {
            console.error("request device number failed", err)
        })
    }

    /**
     * 查询工单数量
     * @param county
     */
    requestWorkorderNumber = (county = null) => {
        WorkorderApi.findWorkorderNumber({city: "赣州市", county}).then(res => {
            this.setState({
                workorderNumber: res.data
            })
        }).catch(err => {
            console.error("request workorder number failed", err)
        })
    }

    /**
     * 查询所有工程师
     * @param county
     */
    requestAllEngineerList = (county = null) => {
        this.setState({loading: true})
        AccountApi.findAllAccount({city: "赣州市", county}).then(res => {
            this.setState({engineerList: res.data})
        }).catch(err => {
            console.error("request all engineer list failed", err)
        }).finally(() => {
            this.setState({loading: false})
        })
    }

    /**
     * 查看工程师详情
     * @param id
     */
    previewEngineerDetail = id => {
        this.props.navigate("/home/engineer-detail", {state: {accountId: id}})
    }

    render() {
        const areaState = this.state;
        return (<div className={styles.left_content_box}>
            <div className={styles.system_info}>
                <div className={styles.system_item}>
                    <span className={styles.system_item_label}>
                        <img src={require("../../../../../asset/area/area_department_icon.png")} alt=""
                             className={styles.system_item_icon}/>
                        已接入单位：
                    </span>
                    <span className={styles.system_item_value}>
                        {areaState.departmentNumber}
                    </span>
                </div>
                <div className={styles.system_item}>
                    <span className={styles.system_item_label}>
                        <img src={require("../../../../../asset/area/area_customer_icon.png")} alt=""
                             className={styles.system_item_icon}/>
                        已接入客户：
                    </span>
                    <span className={styles.system_item_value}>
                        {areaState.customerNumber}
                    </span>
                </div>
                <div className={styles.system_item}>
                    <span className={styles.system_item_label}>
                        <img src={require("../../../../../asset/area/area_service_icon.png")} alt=""
                             className={styles.system_item_icon}/>
                        已接入服务商：
                    </span>
                    <span className={styles.system_item_value}>
                        {areaState.serviceDepartmentNumber}
                    </span>
                </div>
                <div className={styles.system_item}>
                    <span className={styles.system_item_label}>
                        <img src={require("../../../../../asset/area/area_engineer_icon.png")} alt=""
                             className={styles.system_item_icon}/>
                        已接入工程师：
                    </span>
                    <span className={styles.system_item_value}>
                        {areaState.accountNumber}
                    </span>
                </div>
                <div className={styles.system_item}>
                    <span className={styles.system_item_label}>
                        <img src={require("../../../../../asset/area/area_device_icon.png")} alt=""
                             className={styles.system_item_icon}/>
                        已接入设备：
                    </span>
                    <span className={styles.system_item_value}>
                        {areaState.deviceNumber}
                    </span>
                </div>
                <div className={styles.system_item}>
                    <span className={styles.system_item_label}>
                        <img src={require("../../../../../asset/area/area_workorder_icon.png")} alt=""
                             className={styles.system_item_icon}/>
                        已处理工单：
                    </span>
                    <span className={styles.system_item_value}>
                        {areaState.workorderNumber}
                    </span>
                </div>
            </div>
            <div className={styles.system_engineer}>
                <div className={styles.system_engineer_title}>
                    <span>本地工程师</span>
                </div>
                <div className={styles.system_engineer_content}>
                    {areaState.engineerList.map(item => <div className={styles.system_engineer_item} key={item.id}
                                                             onClick={() => this.previewEngineerDetail(item.id)}>
                        <img src={item?.avatar ? item.avatar : defaultAvatar} alt=""
                             className={styles.system_engineer_avatar}/>
                        <div className={styles.system_engineer_info}>
                            <div className={styles.system_engineer_name_state}>
                                <span className={styles.system_engineer_name}>{item?.name}</span>
                                <span
                                    className={styles.system_engineer_state}>{RenderAccountWorkState(item?.situation)}</span>
                            </div>
                            <div className={styles.system_engineer_department}>
                                <span className={styles.system_engineer_department_label}>所属机构：</span>
                                <span
                                    className={styles.system_engineer_department_value}>{item?.departments[0]?.name ?? "-"}</span>
                            </div>
                        </div>
                    </div>)}
                    {areaState.engineerList.length === 0 && !this.state.loading ? <RenderEmptyData/> : null}
                    <RenderLoading isLoading={this.state.loading}/>
                </div>
            </div>
        </div>)
    }
}

export default withNavigation(AreaLeftContent);
