import axios from "axios";
import "../config";

//设置默认请求地址
const _axios = axios.create({
    baseURL: global.config.url,
    timeout: 6000 * 10
});

//添加请求头
_axios.defaults.headers.post["Content-Type"] = "application/json;charset=UTF-8";

/**
 * 封装get请求
 * @param url
 * @param params
 */
export function get(url, params) {
    return _axios.get(url, {params});
}

/**
 * 封装delete请求
 * @param url
 * @param data
 */
export function remove(url, data) {
    return _axios.delete(url, {data});
}

/**
 * 封装put请求
 * @param url
 * @param params
 */
export function patch(url, params) {
    return _axios.patch(url, params);
}

/**
 * 封装post请求
 * @param url
 * @param params
 */
export function post(url, params) {
    return _axios.post(url, params);
}

/**
 * 请求拦截器
 */
_axios.interceptors.request.use(
    config => {
        //设置请求时的token
        config.headers.token = localStorage.getItem("Token");
        return config;
    },
    error => {
        console.log(error);
        return Promise.reject(error);
    }
);

/**
 * 响应拦截器
 */
_axios.interceptors.response.use(
    response => {
        if (response.data.status === 20000) {
            return response.data;
        }
        if (response.data.status === 40300) {
            window.location.replace("/login");
            localStorage.clear();
        }
        return Promise.reject(response.data);
    }, error => {
        console.log(error);
        if (!!error.response) {
            return Promise.reject(error.response);
        } else {
            return Promise.reject({status: 50400, desc: "网络异常", data: "网络异常"});
        }
    }
);
