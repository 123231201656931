import React, {Component} from "react";
import styles from "./index.module.css";
import PropTypes from "prop-types"
import * as echarts from "echarts";
import ManuFacturerApi from "../../../../../api/manufacturer";
import {getOneDecimalPlace} from "../../../../../utils/tool";

class ResponseStatusEcharts extends Component {

    static defaultProps = {
        refId: "",
        type: 1,
        titTag: "",
        tittip: "",
        point: 0,
    }

    responsesChart;
    responsesChartRef = React.createRef();
    state = {
        work_order_responses: [],
        point: "",
        colorData: [
            ['#366fff', '#ee6666', '#fac858', '#90ca74', '#d48265', '#91c7ae'],
            ['#ee6666', '#fac858', '#90ca74', '#d48265', '#91c7ae', '#366fff'],
            ['#fac858', '#90ca74', '#d48265', '#91c7ae', '#366fff', '#ee6666'],
            ['#90ca74', '#d48265', '#91c7ae', '#366fff', '#ee6666', '#fac858']
        ]
    }

    componentWillUnmount() {
        // 销毁图表实例
        this.responsesChart.dispose(); // 销毁图表实例
    }

    componentDidMount() {
        this.init();
        this.requestFaultChance(this.props.refId) // 获取设备故障类型数据
    }

    /**
     * 获取设备故障类型数据
     * @param id
     */
    requestFaultChance = (id = null) => {
        ManuFacturerApi.pointdetail(id).then(res => {
            if (res.status === 20000) {
                res.data.map(item => {
                    if (item.type === this.props.type) {
                        this.setState({
                            point: item.point,
                        })
                        this.setState({work_order_responses: item}, () => {
                            this.responsesChart.setOption(this.pieOptions(this.state.work_order_responses))
                        })
                    }
                })
            }
        })
    }

    /**
     * 构造饼图图表配置项
     */
    pieOptions = (data) => (

        {
            color: this.state.colorData[this.props.type],
            title: {
                // text: data.type === 0 ? "设备故障类型" : data.type === 1 ? "工单响应情况" : "工单满意情况",
                x: "0%",
                y: '5%',
                textStyle: {
                    fontSize: 12,
                    fontWeight: 200,
                    color: '#fff'
                },
            },
            tooltip: {
                trigger: 'item',
                formatter: "{b} : {c} ({d}%)"
            },
            calculable: true,
            series: [
                {
                    type: 'pie',
                    radius: '40%',
                    center: ['50%', '60%'],
                    label: {
                        alignTo: 'edge',
                        formatter: '{percent|{d}%}\n{name|{b}}', // 使用定义好的样式
                        minMargin: 5,
                        edgeDistance: 10,
                        lineHeight: 15,
                        color: "#FFFFFF",
                        rich: {
                            percent: {
                                fontSize: 14,
                            },
                            name: {
                                fontSize: 12
                            }
                        },
                    },
                    labelLine: {
                        length: 20,
                        length2: 0,
                        //maxSurfaceAngle: 200
                    },

                    data: data.lists.map(item => {
                        return {
                            name: item.name,
                            value: item.num
                        }
                    }) || []
                }
            ]
        }
    )

    init = () => {
        // 初始化图表实例
        this.responsesChart = echarts.init(this.responsesChartRef.current);

    }

    render() {
        return (
            <div className={styles.text_num_pie_echarts_box}>
                <div className={styles.text_num}>
                    <div className={styles.titTag}>{this.props.titTag}</div>
                    <div
                        className={styles.titNum}>{this.state.point === 0 || this.state.point ? getOneDecimalPlace(this.state.point) : this.props.point}</div>
                    <div className={styles.tittip}>{this.props.tittip}分（总分{this.props.point}）</div>
                </div>
                {Object.keys(this.state.work_order_responses).length === 0 ?
                    <div ref={this.responsesChartRef} className={styles.no_pie_echarts}/> :
                    <div ref={this.responsesChartRef} className={styles.pie_echarts}/>}

            </div>
        )
    }
}

ResponseStatusEcharts.propTypes = {
    refId: PropTypes.string,
    type: PropTypes.number,
    titTag: PropTypes.string,
    tittip: PropTypes.string,
    point: PropTypes.number,
}

export default ResponseStatusEcharts;