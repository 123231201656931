import React, {useEffect, useRef, useState} from 'react';

import styles from "./CustomerLogin.module.css"
import {Button, Col, Form, Input, message, Row} from "antd";
import {KeyOutlined, ProfileOutlined, UserOutlined} from "@ant-design/icons";
import axios from "axios";
import {useNavigate} from "react-router";

/**
 * @author       : AngelBeats
 * @date         : 2023-05-05 08:53:01
 * @description  : 客户单位登录界面
 */
const CustomerLogin = () => {

    const formRef = useRef();
    const navigate = useNavigate()

    const [loading, setLoading] = useState(false);
    const [base64Image, setBase64Image] = useState("");
    const [captchaId, setCaptchaId] = useState("");

    useEffect(() => {
        getCaptcha();
    }, [])

    /**
     * 显示loading
     */
    const showLoading = () => setLoading(true)

    /**
     * 隐藏loading
     */
    const closeLoading = () => setLoading(false)

    /**
     * 获取验证码
     */
    const getCaptcha = () => {
        axios.get(`${global.config.url}/customer/captcha/login/base64`, {
            params: {
                height: 44,
                width: 180
            }
        }).then(res => {
            setBase64Image(res.data.data.base64Image);
            setCaptchaId(res.data.data.captchaId)
        }).catch(error => {
            console.warn("Get captcha failed", error);
        });
    }

    /**
     * 登录
     */
    const handle = data => {
        showLoading();
        data.captchaId = captchaId;
        data.username = data.username.trim();
        data.password = data.password.trim();
        axios.patch(`${global.config.url}/customer/department/login`, data).then((response) => {
            if (response.data.status === 20000) {
                localStorage.setItem("Token", response.data.data.token);
                navigate(`/home/department/${response.data.data.id}`, {state: {showBack: true}});
            } else {
                switch (response.data.status) {
                    case 40000:
                        formRef.current.setFieldsValue({captchaValue: ""});
                        formRef.current.setFields([{name: "username", errors: [response.data.data]}]);
                        break;
                    case 40307:
                        // 密码错误
                        formRef.current.setFieldsValue({captchaValue: ""});
                        formRef.current.setFields([{name: "password", errors: ["密码错误"]}]);
                        break;
                    case 40306:
                        formRef.current.setFieldsValue({captchaValue: ""});
                        formRef.current.setFields([{name: "username", errors: [response.data.data]}]);
                        break;
                    case 40400:
                        formRef.current.setFieldsValue({captchaValue: ""});
                        formRef.current.setFields([{name: "captchaValue", errors: [response.data.data]}]);
                        break;
                    case 40403:
                        // 账户被禁用
                        formRef.current.setFieldsValue({captchaValue: ""});
                        formRef.current.setFields([{name: "username", errors: [response.data.data]}]);
                        break;
                    default:
                        formRef.current.setFieldsValue({captchaValue: ""});
                        message.error({
                            content: response.data.data, key: "login"
                        });
                        break;
                }
                closeLoading();
                // 重新获取验证码
                getCaptcha();
            }
        }).catch(() => {
            closeLoading();
            message.error({key: "loginError", content: "未知错误,请刷新浏览器重试！"});
            // 重新获取验证码
            getCaptcha();
        });
    }

    return (<div className={styles.customer_login_page}>
        <Row>
            <Col span={11}>
                <div className={styles.customer_login_left}>
                    <img src={require("../../asset/loginImg/customer/customer_login_img.png")} alt=""
                         className={styles.customer_login_left_img}/>
                </div>
            </Col>
            <Col span={13}>
                <div className={styles.customer_login_right}>

                    <Form ref={formRef} onFinish={handle} layout={"vertical"}>
                        <div className={styles.welcome_login}>
                            欢迎登录
                        </div>
                        <Row>
                            <Col span={24}>
                                <Form.Item name="username" rules={[{required: true, message: "请输入账号"}]}>
                                    <Input disabled={loading} placeholder="请输入账号" prefix={<UserOutlined/>}/>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item name="password" required rules={[{required: true, message: "请输入密码"}]}>
                                    <Input.Password disabled={loading} prefix={<KeyOutlined/>} placeholder="请输入密码"/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row justify="space-between" gutter={[16]}>
                            <Col xs={{span: 24}} lg={{span: 14}}>
                                <Form.Item name="captchaValue" rules={[{required: true, message: "请输入验证码"}]}
                                           className={styles.captcha_value}>
                                    <Input disabled={loading} prefix={<ProfileOutlined/>} placeholder="请输入验证码"/>
                                </Form.Item>
                            </Col>
                            <Col xs={{span: 24}} lg={{span: 10}}>
                                <img src={base64Image} alt="验证码(点击刷新)" className={styles.verify_image}
                                     onClick={getCaptcha}/>
                            </Col>
                        </Row>
                        <Button type="primary" htmlType="submit" loading={loading} block>登录</Button>
                    </Form>
                </div>
            </Col>
        </Row>
    </div>);
};

export default CustomerLogin;
