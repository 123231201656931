import React, {Component} from 'react';

import {withNavigation} from "../../../utils/WithPlusClass";
import PageTitle from "../../../components/title/PageTitle";

import styles from "./index.module.css"
import DepartmentLeftContent from "./components/left/DepartmentLeftContent";
import DepartmentCenterContent from "./components/center/DepartmentCenterContent";
import DepartmentRightContent from "./components/right/DepartmentRightContent";
import DeviceApi from "../../../api/device/DeviceApi";
import WorkorderApi from "../../../api/workorder/WorkorderApi";

/**
 * @author       : AngelBeats
 * @date         : 2023-03-21 17:07:13
 * @description  : 单位下子单位详情页面
 */
class DepartmentChildDetail extends Component {

    state = {
        deviceData: [],
        newWorkorder: [],
        newRequestWorkorder: [],
        lastRequestTime: "",
    }
    workorderLeftContentRef = React.createRef();

    timer = null;

    componentDidMount() {
        // this.requestAllDepartmentDevice();
        this.requestNewWorkorder();
    }

    /**
     * 查询单位下的所有设备
     */
    requestAllDepartmentDevice = () => {
        DeviceApi.findAllDepartmentDevice({customerDepartmentIds: this.props.location.state.departmentId}).then(res => {
            this.setState({deviceData: res.data})
        }).catch(err => {
            console.error("request all department device failed", err)
        })
    }

    /**
     * 查询设备工单数
     * @param deviceIds
     */
    requestDeviceWorkorderNumber = (deviceIds) => {
        WorkorderApi.findDeviceWorkorderNumber(deviceIds).then(res => {
            console.log(res)
        }).catch(err => {
            console.error("request device workorder number failed", err)
        })
    }

    /**
     * 查询最新工单
     */
    requestNewWorkorder = (county = null, city = "赣州市") => {
        WorkorderApi.findNewWorkorder({county, city}).then(res => {
            let d = new Date();
            let time = `${d.getFullYear()}-${(d.getMonth() + 1).toString().padStart(2, 0)}-${d.getDate().toString().padStart(2, 0)} ${d.getHours().toString().padStart(2, 0)}:${d.getMinutes().toString().padStart(2, 0)}:${d.getSeconds().toString().padStart(2, 0)}`
            this.setState({newWorkorder: res.data, lastRequestTime: time})
            this.timer = setInterval(() => {
                this.requestRefreshNewWorkorder(county, city);
            }, 15000)
        }).catch(err => {
            console.error(err)
        })
    }

    /**
     * 查询最新工单
     */
    requestRefreshNewWorkorder = (county, city) => {
        WorkorderApi.findNewWorkorder({county, city, createTime: this.state.lastRequestTime}).then(res => {
            let d = new Date();
            let time = `${d.getFullYear()}-${(d.getMonth() + 1).toString().padStart(2, 0)}-${d.getDate().toString().padStart(2, 0)} ${d.getHours().toString().padStart(2, 0)}:${d.getMinutes().toString().padStart(2, 0)}:${d.getSeconds().toString().padStart(2, 0)}`

            if (res.data.length > 0) {
                this.workorderLeftContentRef.current.addWorkorderMonitorTransition();
            }

            this.setState({
                newRequestWorkorder: [...res.data, ...this.state.newRequestWorkorder],
                lastRequestTime: time
            }, () => {
                setTimeout(() => {
                    this.workorderLeftContentRef.current.removeWorkorderMonitorTransition();
                    this.setState({
                        newWorkorder: [...this.state.newRequestWorkorder, ...this.state.newWorkorder],
                        newRequestWorkorder: []
                    })
                }, 8000)
            })
        }).catch(err => {
            console.error(err)
        })
    }

    render() {
        return (<div className={styles.department_page}>
            <PageTitle backIcon title="赣州市信创数字化区域监控中心"/>
            <div className={styles.department_content}>
                <DepartmentLeftContent newWorkorder={this.state.newWorkorder}
                                       newRequestWorkorder={this.state.newRequestWorkorder}
                                       ref={this.workorderLeftContentRef}/>
                <DepartmentCenterContent departmentChild/>
                <DepartmentRightContent/>
            </div>
        </div>)
    }
}

export default withNavigation(DepartmentChildDetail)
