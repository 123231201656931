import React, {Component} from 'react';
import DeviceApi from "../../../api/device/DeviceApi";
import WorkorderApi from "../../../api/workorder/WorkorderApi";

import {withNavigation} from "../../../utils/WithPlusClass";

import styles from "./index.module.css"
import PageTitle from "../../../components/title/PageTitle";
import DepartmentApi from "../../../api/department/DepartmentApi";
import EdgeBorder from "../../../components/box/EdgeBorder";
import RenderEmptyData from "../../../components/empty/RenderEmptyData";

/**
 * @author       : AngelBeats
 * @date         : 2023-04-10 17:08:38
 * @description  : 单位设备列表
 */
class DepartmentDeviceList extends Component {

    state = {
        deviceNumber: 0,
        deviceRepairNumber: 0,
        deviceData: [],
        department: {}
    }

    componentDidMount() {
        this.requestDeviceNumber();
        this.requestDeviceRepairNumber();
        this.requestAllDepartmentDevice();
        this.requestDepartmentDetail();
    }

    /**
     * 获取设备总数
     */
    requestDeviceNumber = () => {
        DeviceApi.findDeviceTotal({customerDepartmentId: this.props.location.state.departmentId}).then(res => {
            this.setState({deviceNumber: res.data})
        }).catch(err => {
            console.error("request device number failed", err)
        })
    }

    /**
     * 获取设备总维护数量
     */
    requestDeviceRepairNumber = () => {
        WorkorderApi.findWorkorderNumber({
            logicType: 'smp',
            clientDepartmentId: this.props.location.state.departmentId
        }).then(res => {
            this.setState({deviceRepairNumber: res.data})
        }).catch(err => {
            console.error("request device repair number", err)
        })
    }

    /**
     * 查询单位下的所有设备
     */
    requestAllDepartmentDevice = () => {
        DeviceApi.findAllDepartmentDevice({customerDepartmentIds: this.props.location.state.departmentId}).then(res => {
            res.data.forEach(item => item.count = 0)
            this.setState({deviceData: res.data}, () => {
                this.requestDeviceWorkorderNumber(res.data.map(item => item.id))
            })
        }).catch(err => {
            console.error("request all department device failed", err)
        })
    }

    /**
     * 查询设备工单数
     * @param deviceIds
     */
    requestDeviceWorkorderNumber = (deviceIds) => {
        WorkorderApi.findDeviceWorkorderNumber(deviceIds).then(res => {
            let {deviceData} = this.state
            deviceData.forEach(device => {
                res.data.forEach(count => {
                    if (device.id === count.name) {
                        device.count = count.value
                    }
                })
            })
            this.setState({deviceData})
        }).catch(err => {
            console.error("request device workorder number failed", err)
        })
    }

    /**
     * 查询单位详情
     */
    requestDepartmentDetail = () => {
        DepartmentApi.findDepartmentDetail(this.props.location.state.departmentId).then(res => {
            this.setState({department: res.data})
        }).catch(err => {
            console.error("request department detail failed", err)
        })
    }

    /**
     * 查看设备详情
     * @param item
     */
    previewDeviceDetail = (item) => {
        this.props.navigate("/home/device-detail", {state: {deviceId: item.id}})
    }

    /**
     * 补齐盒子 填充flex布局
     */
    fillUpBox = () => {
        if (this.state.deviceData.length % 3 === 2) {
            return <>
                <div className={styles.child_device_list_item}
                     style={{backgroundColor: "none", opacity: 0, cursor: "auto"}}/>
            </>
        }
    }

    render() {
        const {deviceNumber, deviceRepairNumber} = this.state

        return (<div className={styles.department_page}>
            <PageTitle backIcon title="赣州市信创数字化区域监控中心"/>
            <div className={styles.department_device_content}>
                <div className={styles.department_name}>{this.state.department.name}</div>
                <div className={styles.department_device_data}>
                    <div className={styles.department_device_data_item}>
                        <img src={require("../../../asset/department/department_device_icon.png")} alt=""
                             className={styles.department_device_data_item_img}/>
                        <div className={styles.department_device_data_detail}>
                            <span className={styles.department_device_value}>{deviceNumber}</span>
                            <span className={styles.department_device_label}>当前设备总数</span>
                        </div>
                    </div>
                    <div className={styles.department_device_data_item}>
                        <img src={require("../../../asset/department/department_device_repair_icon.png")} alt=""
                             className={styles.department_device_data_item_img}/>
                        <div className={styles.department_device_data_detail}>
                            <span className={styles.department_device_value}>{deviceRepairNumber}</span>
                            <span className={styles.department_device_label}>设备总维护次数</span>
                        </div>
                    </div>
                    <div className={styles.department_device_data_item}>
                        <img src={require("../../../asset/department/department_ava_repair_icon.png")} alt=""
                             className={styles.department_device_data_item_img}/>
                        <div className={styles.department_device_data_detail}>
                        <span className={styles.department_device_value}>
                            {
                                deviceNumber === 0 ? <span style={{fontSize: 16}}>本单位未录入设备</span>
                                    : (deviceRepairNumber / deviceNumber).toFixed(3)}
                        </span>
                            <span className={styles.department_device_label}>设备维护故障次数/台</span>
                        </div>
                    </div>
                </div>
                <div className={styles.child_device_list}>
                    <div className={styles.child_device_list_box}>
                        {this.state.deviceData.map(item => <div className={styles.child_device_list_item} key={item.id}
                                                                onClick={() => this.previewDeviceDetail(item)}>
                            <div className={styles.child_device_list_content}>
                                <div className={styles.device_department_customer}>
                                    <span
                                        className={styles.device_list_value}>所属单位：{item.customer?.department?.name}</span>
                                    <span className={styles.device_list_value}>所属客户：{item.customer?.name}</span>
                                </div>
                                <div className={styles.device_department_customer}>
                                    <span className={styles.device_list_value}>设备名称：{item.name}</span>
                                    <span className={styles.device_list_value}>维护次数：{item.count}</span>
                                </div>
                                <div className={styles.device_department_customer}>
                                    <span className={styles.device_list_value}>设备型号：{item.model}</span>
                                    <span className={styles.device_list_value}>门牌号码：{item?.storey === "" ? "-" : item.storey}</span>
                                </div>
                            </div>
                        </div>)}
                        {this.state.deviceData.length === 0 ? <RenderEmptyData/> : null}
                        {this.fillUpBox()}
                    </div>
                    <EdgeBorder/>
                </div>
            </div>
        </div>)
    }
}

export default withNavigation(DepartmentDeviceList)
