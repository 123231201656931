import ReactDOM from "react-dom/client";
import {renderRoutes} from "./route";
import "./config"
import "antd/dist/reset.css";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import "./index.css"

import {legacyLogicalPropertiesTransformer, StyleProvider} from "@ant-design/cssinjs";
import {ConfigProvider} from "antd";
import zhCN from 'antd/locale/zh_CN';

global.AMap = AMap;

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<StyleProvider hashPriority="high" transformers={[legacyLogicalPropertiesTransformer]}>
    <ConfigProvider locale={zhCN}>
        {renderRoutes()}
    </ConfigProvider>
</StyleProvider>);
