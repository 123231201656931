import React, {Component} from 'react';
import styles from "./index.module.css";

export default class SpecimanuDialog extends Component {

    state = {
        open: false
    }

    /**
     * 开启弹窗
     */
    show = () => this.setState({open: true})

    /**
     * 关闭弹窗
     */
    close = () => {
        this.setState({ open: false }, () => {
            this.setState({ open: false })
         });
    }

    render() {
        return (<>
            {
                this.state.open ? <div className={styles.dialog_view} onClick={this.close}>
                    <div className={styles.device_insert_manufacturer_box} onClick={(e) => e.stopPropagation()}>
                        <div className={styles.dialog_title}>厂商评分：</div>
                        <p>1.每个厂商的总评分为100分，由三部分组成“设备故障分”、“工单响应分”、“工单满意度”。</p>
                        <p>2.设备故障分总分为70分，计算设备故障工单所占设备总量的比重，进行对应扣分，如比重为30%，则设备故障分为70*（1-30%）=49分</p>
                        <p>3.工单响应分总分为20分，计算超时工单所占设备关联所有工单的比重，进行对应扣分。如上。</p>
                        <p>4.工单满意度总分为10分，计算满意和不满意工单所占设备关联所有工单的比重（对应占比为1:2），如比重为20%和10%，则相加总比重为20%，则工单满意分为10*（1-20%）=8分</p>
                    </div>
                </div> : null
            }
        </>)
    }
}
