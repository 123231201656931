import React, {Component} from 'react';

import styles from "./index.module.css"
import EdgeBorder from "../../../../../components/box/EdgeBorder";
import {withNavigation} from "../../../../../utils/WithPlusClass";
import DepartmentApi from "../../../../../api/department/DepartmentApi";
import ChildDetail from "../child/ChildDetail";
import WorkorderApi from "../../../../../api/workorder/WorkorderApi";
import CustomerApi from "../../../../../api/customer/CustomerApi";
import AccountApi from "../../../../../api/account/AccountApi";
import DeviceApi from "../../../../../api/device/DeviceApi";

/**
 * @author       : AngelBeats
 * @date         : 2023-03-22 10:00:30
 * @description  : 单位页面中间内容
 */
class DepartmentCenterContent extends Component {

    state = {
        departmentId: "",
        departmentList: [],
        departmentIds: [],
        deviceData: [],
        department: {},
        workorderTotal: 0,
        customerTotal: 0,
        accountNumber: 0,
        workorderAverageReceiveTime: 0,
        deviceNumber: 0,
        deviceRepairNumber: 0,
    }

    componentDidMount() {
        this.setState({departmentId: this.props.departmentId}, () => {
            this.requestAll();
        })
    }

    componentWillReceiveProps(newProps) {
        if (newProps.departmentId !== this.state.departmentId) {
            this.setState({departmentId: newProps.departmentId}, () => {
                this.requestAll();
            })
        }
    }

    /**
     * 所有请求
     */
    requestAll = () => {
        this.requestDepartmentTree();
        this.requestDepartmentDetail();
        this.requestWorkorderTotal();
        this.requestCustomerTotal();
        this.requestAccountNumber();
        this.requestWorkorderAverageReceiveTime();
        this.requestDeviceNumber();
        this.requestDeviceRepairNumber();
        this.requestAllDepartmentDevice();
    }

    /**
     * 查询工单总数
     */
    requestWorkorderTotal = () => {
        WorkorderApi.findWorkorderNumber({clientDepartmentId: this.state.departmentId}).then(res => {
            this.setState({workorderTotal: res.data})
        }).catch(err => {
            console.error("request workorder total failed", err)
        })
    }
    /**
     * 查询客户总数
     */
    requestCustomerTotal = () => {
        CustomerApi.findCustomerTotal({departmentId: this.state.departmentId}).then(res => {
            this.setState({customerTotal: res.data})
        }).catch(err => {
            console.error("request customer total failed", err)
        })
    }

    /**
     * 查询工程师总数
     */
    requestAccountNumber = () => {
        AccountApi.findAccountNumber({customerDepartmentId: this.state.departmentId}).then(res => {
            this.setState({
                accountNumber: res.data
            })
        }).catch(err => {
            console.error("request account number failed", err)
        })
    }

    /**
     * 平均接单时长
     */
    requestWorkorderAverageReceiveTime = () => {
        WorkorderApi.findWorkorderAverageReceiveTime({
            accountId: 0,
            clientDepartmentId: this.state.departmentId
        }).then(res => {
            let data = (Number(res.data) / 3600).toFixed(1)
            this.setState({
                workorderAverageReceiveTime: data
            })
        }).catch(err => {
            console.error("request workorder average receive time", err)
        })
    }

    /**
     * 获取设备总数
     */
    requestDeviceNumber = () => {
        DeviceApi.findDeviceTotal({customerDepartmentId: this.state.departmentId}).then(res => {
            this.setState({deviceNumber: res.data})
        }).catch(err => {
            console.error("request device number failed", err)
        })
    }

    /**
     * 获取设备总维护数量
     */
    requestDeviceRepairNumber = () => {
        WorkorderApi.findWorkorderNumber({
            logicType: 'smp',
            clientDepartmentId: this.state.departmentId
        }).then(res => {
            this.setState({deviceRepairNumber: res.data})
        }).catch(err => {
            console.error("request device repair number", err)
        })
    }

    /**
     * 查询单位详情
     */
    requestDepartmentDetail = () => {
        DepartmentApi.findDepartmentDetail(this.state.departmentId).then(res => {
            this.setState({department: res.data})
        }).catch(err => {
            console.error("request department detail failed", err)
        })
    }

    /**
     * 加载更多单位
     * @param item
     */
    moreDepartment = (item) => {
        if (this.state.departmentIds.includes(item.id)) {
            this.requestChildIds(item.id);
            return;
        }
        if (item.parentId === "0") {
            this.setState({departmentIds: []}, () => {
                this.setState({departmentIds: [...this.state.departmentIds, item.id]})
            });
            return;
        }
        this.setState({departmentIds: [...this.state.departmentIds, item.id]})
    }

    /**
     * 查询单位树
     */
    requestDepartmentTree = () => {
        DepartmentApi.findDepartmentTree({parentIds: this.state.departmentId}).then(async (res) => {
            console.log(res)

            // const departmentIds = res.data[0]?.children ? res.data[0]?.children.map(item => item.id) : [];
            const departmentIds = this.requestAllDepartmentIds(res.data[0]?.children ? res.data[0]?.children : []);
            const customerDepartmentDeviceNumberList = await this.requestCustomerDepartmentDeviceNumber(departmentIds)

            const deptCount = {}
            customerDepartmentDeviceNumberList.forEach(v => deptCount[v.name] = v.value)
            this.compareCusDept(res.data[0], deptCount)
            this.sumChildrenDeviceCount(res.data[0])
            this.setState({departmentList: res.data[0].children ?? []})
        }).catch(err => {
            console.error("request department tree failed", err)
        })
    }

    /**
     * 查询所有单位ID
     * @param data
     * @param departmentIds
     */
    requestAllDepartmentIds = (data, departmentIds = []) => {
        data.forEach(item => {
            departmentIds.push(item.id)
            if (!!item.children) {
                this.requestAllDepartmentIds(item.children, departmentIds)
            }
        })
        return departmentIds;
    }

    /**
     * 数据转换
     * @param dept
     * @param deptCount
     */
    compareCusDept = (dept, deptCount) => {
        dept.count = deptCount[dept.id] === undefined ? 0 : deptCount[dept.id]
        if (dept.children !== undefined && dept.children !== null && dept.children.length > 0) {
            dept.children.forEach(v => this.compareCusDept(v, deptCount))
        }
    }

    /**
     * 拼接父单位设备数量
     * @param dept
     * @returns {*}
     */
    sumChildrenDeviceCount = (dept) => {
        let count = dept.count
        if (dept.children !== undefined && dept.children !== null && dept.children.length > 0) {
            for (let child of dept.children) {
                count += this.sumChildrenDeviceCount(child)
            }
            dept.count = count
            return count
        } else {
            return count;
        }
    }

    /**
     *  请求客户单位设备数量
     * @param customerDepartmentIds
     */
    requestCustomerDepartmentDeviceNumber = async (customerDepartmentIds) => {
        if (customerDepartmentIds.length > 0) {
            const {data} = await DeviceApi.findAllDeviceCount(customerDepartmentIds).catch(err => {
                console.error("request customer department device number failed", err)
                return [];
            });
            return data
        }

        return []

    }

    /**
     * 根据父单位id 查询所有子单位id
     * @param parentId
     */
    requestChildIds = (parentId) => {
        DepartmentApi.findChildId({parentIds: parentId}).then(res => {
            let data = [];
            this.state.departmentIds.forEach((item, index) => {
                if (!res.data.includes(item)) {
                    data.push(item)
                }
            })
            this.setState({
                departmentIds: data
            })
        }).catch(err => {
            console.error("request child ids failed", err)
        })
    }

    /**
     * 查询单位设备
     * @param id
     */
    previewDevice = (id) => {
        this.props.navigate("/home/department/device", {state: {departmentId: id}});
    }

    /**
     * 查询单位详情
     * @param id
     */
    previewDetail = (id) => {
        this.props.navigate(`/home/department/${id}`);
    }

    /**
     * 查询单位下的所有设备
     */
    requestAllDepartmentDevice = () => {
        DeviceApi.findAllDepartmentDevice({customerDepartmentIds: this.state.departmentId}).then(res => {
            res.data.forEach(item => item.count = 0)
            this.setState({deviceData: res.data}, () => {
                this.requestDeviceWorkorderNumber(res.data.map(item => item.id))
            })
        }).catch(err => {
            console.error("request all department device failed", err)
        })
    }

    /**
     * 查询设备工单数
     * @param deviceIds
     */
    requestDeviceWorkorderNumber = (deviceIds) => {
        WorkorderApi.findDeviceWorkorderNumber(deviceIds).then(res => {
            let {deviceData} = this.state
            deviceData.forEach(device => {
                res.data.forEach(count => {
                    if (device.id === count.name) {
                        device.count = count.value
                    }
                })
            })
            this.setState({deviceData})
        }).catch(err => {
            console.error("request device workorder number failed", err)
        })
    }

    /**
     * 渲染单位树
     */
    renderDepartmentTree = (departmentList) => {
        return departmentList.map(item => {
            let pl = 100 / 1920 * (39 + (item.weight - 1) * 10) + "vw"
            let l = 100 / 1920 * (20 + (item.weight - 1) * 10) + "vw"
            if (item.children) {
                return <div className={`${styles.department_child_item} ${styles.being_child}`} key={item.id}
                            style={item.weight === 1 ? {marginTop: 8} : {
                                marginTop: 0,
                                paddingTop: 10,
                                paddingBottom: 10,
                            }}>
                    <div className={styles.department_child_item_parent}>
                        <span className={styles.department_child_name}
                              style={this.state.departmentIds.includes(item.id) ? {
                                  paddingTop: 0,
                                  paddingBottom: 8,
                                  paddingLeft: pl
                              } : {paddingLeft: pl}}>
                            {item.name}
                            <span className={styles.department_child_item_plus} style={{left: l}}
                                  onClick={() => this.moreDepartment(item)}>{this.state.departmentIds.includes(item.id) ? "-" : "+"}</span>
                        </span>
                        <span className={styles.department_child_device_num}>{item.count}</span>
                        <span className={styles.department_child_device_list}
                              onClick={() => this.previewDevice(item.id)}>查看设备</span>
                        <span className={styles.department_child_device_detail}
                              onClick={() => this.previewDetail(item.id)}>单位详情</span>
                    </div>
                    {this.state.departmentIds.includes(item.id) ? this.renderDepartmentTree(item.children) : null}
                </div>
            } else {
                return <div className={styles.department_child_item} key={item.id}
                            style={item.weight === 1 ? {marginTop: 8} : {
                                marginTop: 0,
                                paddingTop: 10,
                                paddingBottom: 10
                            }}>
                    <span className={styles.department_child_name}
                          style={this.state.departmentIds.includes(item.id) && item.weight === 1 ? {
                              paddingTop: 0,
                              paddingBottom: 8,
                              paddingLeft: pl
                          } : {paddingLeft: pl}}>{item.name}</span>
                    <span className={styles.department_child_device_num}>{item.count}</span>
                    <span className={styles.department_child_device_list}
                          onClick={() => this.previewDevice(item.id)}>查看设备</span>
                    <span className={styles.department_child_device_detail}
                          onClick={() => this.previewDetail(item.id)}>单位详情</span>
                </div>
            }
        })
    }

    render() {
        const {
            department,
            workorderTotal,
            customerTotal,
            accountNumber,
            workorderAverageReceiveTime,
            deviceNumber,
            deviceRepairNumber
        } = this.state

        return (<div className={styles.department_center_box}>
            <div className={styles.page_title}>
                <span>{department?.name ?? "-"}</span>
            </div>
            <div className={styles.department_workorder_data}>
                <div className={styles.department_workorder_data_item}>
                    <img src={require("../../../../../asset/department/department_workorder_total_icon.png")} alt=""
                         className={styles.department_workorder_data_img}/>
                    <div className={styles.department_workorder_data_detail}>
                        <span className={styles.department_workorder_data_value}>{workorderTotal}</span>
                        <span className={styles.department_workorder_data_label}>工单总数</span>
                    </div>
                </div>
                <div
                    className={`${styles.department_workorder_data_item} ${styles.department_workorder_data_customer}`}>
                    <img src={require("../../../../../asset/department/department_customer_icon.png")} alt=""
                         className={styles.department_workorder_data_img}/>
                    <div className={styles.department_workorder_data_detail}>
                        <span className={styles.department_workorder_data_value}>{customerTotal}</span>
                        <span className={styles.department_workorder_data_label}>接入客户数</span>
                    </div>
                </div>
                <div
                    className={`${styles.department_workorder_data_item}  ${styles.department_workorder_data_engineer}`}>
                    <img src={require("../../../../../asset/department/department_engineer_icon.png")} alt=""
                         className={styles.department_workorder_data_img}/>
                    <div className={styles.department_workorder_data_detail}>
                        <span className={styles.department_workorder_data_value}>{accountNumber}</span>
                        <span className={styles.department_workorder_data_label}>接入工程师数</span>
                    </div>
                </div>
                <div
                    className={`${styles.department_workorder_data_item}  ${styles.department_workorder_data_response_time}`}>
                    <img src={require("../../../../../asset/department/department_response_time_icon.png")} alt=""
                         className={styles.department_workorder_data_img}/>
                    <div className={styles.department_workorder_data_detail}>
                        <span
                            className={styles.department_workorder_data_value}>{workorderAverageReceiveTime + " / h"}</span>
                        <span className={styles.department_workorder_data_label}>工单平均响应时间</span>
                    </div>
                </div>
            </div>

            <div className={styles.department_device_data}>
                <div className={styles.department_device_data_item}>
                    <img src={require("../../../../../asset/department/department_device_icon.png")} alt=""
                         className={styles.department_device_data_item_img}/>
                    <div className={styles.department_device_data_detail}>
                        <span className={styles.department_device_value}>{deviceNumber}</span>
                        <span className={styles.department_device_label}>当前设备总数</span>
                    </div>
                </div>
                <div className={styles.department_device_data_item}>
                    <img src={require("../../../../../asset/department/department_device_repair_icon.png")} alt=""
                         className={styles.department_device_data_item_img}/>
                    <div className={styles.department_device_data_detail}>
                        <span className={styles.department_device_value}>{deviceRepairNumber}</span>
                        <span className={styles.department_device_label}>设备总维护次数</span>
                    </div>
                </div>
                <div className={styles.department_device_data_item}>
                    <img src={require("../../../../../asset/department/department_ava_repair_icon.png")} alt=""
                         className={styles.department_device_data_item_img}/>
                    <div className={styles.department_device_data_detail}>
                        <span
                            className={styles.department_device_value}>{deviceNumber === 0 ? <span
                            style={{fontSize: 16}}>本单位未录入设备</span> : (deviceRepairNumber / deviceNumber).toFixed(3)}</span>
                        <span className={styles.department_device_label}>设备维护故障次数/台</span>
                    </div>
                </div>
            </div>

            <div className={styles.department_child_box}>
                {
                    this.state.departmentList.length === 0 ? <ChildDetail deviceData={this.state.deviceData}/> : <>
                        <div className={styles.department_child_title}>
                            <span className={styles.department_child_name} style={{paddingLeft: 0}}>单位名称</span>
                            <span className={styles.department_child_device_num}>设备数量</span>
                            <span className={styles.department_child_device_list}>查看设备列表</span>
                            <span className={styles.department_child_device_detail}>查看单位详情</span>
                        </div>
                        <div className={styles.department_child_content}>
                            {this.renderDepartmentTree(this.state.departmentList)}
                        </div>
                    </>
                }

                <EdgeBorder/>
            </div>
        </div>)
    }
}

export default withNavigation(DepartmentCenterContent)
