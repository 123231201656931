import React from 'react';

import styles from "./index.module.css"

/**
 * @author       : AngelBeats
 * @date         : 2023-03-16 09:13:31
 * @description  : 盒子边缘角
 */
const EdgeBorder = () => {
    return (<>
        <span className={styles.card_top_left_corner}/>
        <span className={styles.card_top_right_corner}/>
        <span className={styles.card_bottom_left_corner}/>
        <span className={styles.card_bottom_right_corner}/>
    </>);
};

export default EdgeBorder;
