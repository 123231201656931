import React, {Component} from 'react';
import styles from "./index.module.css"
import PageTitle from "../../../components/title/PageTitle";
import WorkorderNumerical from "./components_county/numerical/WorkorderNumerical";
import WorkorderLeftContent from "./components_county/left/WorkorderLeftContent";
import WorkorderCenterContent from "./components_county/center/WorkorderCenterContent";
import WorkorderRightContent from "./components_county/right/WorkorderRightContent";
import WorkorderApi from "../../../api/workorder/WorkorderApi";
import EventBus from "../../../utils/EventBus";

/**
 * @author       : AngelBeats
 * @date         : 2023-03-20 13:42:30
 * @description  : 工作调度页面
 */
export default class WorkorderPage extends Component {

    workorderLeftContentRef = React.createRef();

    state = {
        newWorkorder: [],
        newRequestWorkorder: [],
        lastRequestTime: "",
    }
    timer = null;

    componentDidMount() {

    }

    componentWillUnmount() {
        // 销毁定时器
        clearInterval(this.timer)
    }

    /**
     * 查询最新工单
     * ！！！！！废弃 子组件自己请求  留着以防万一
     */
    requestNewWorkorder = (county = null, city = "赣州市") => {
        WorkorderApi.findNewWorkorder({county, city}).then(res => {
            console.log(res)
            let d = new Date();
            let time = `${d.getFullYear()}-${(d.getMonth() + 1).toString().padStart(2, 0)}-${d.getDate().toString().padStart(2, 0)} ${d.getHours().toString().padStart(2, 0)}:${d.getMinutes().toString().padStart(2, 0)}:${d.getSeconds().toString().padStart(2, 0)}`
            this.setState({newWorkorder: res.data, lastRequestTime: time})
            this.timer = setInterval(() => {
                this.requestRefreshNewWorkorder(county, city);
            }, 15000)
        }).catch(err => {
            console.error(err)
        })
    }

    /**
     * 查询更新最新工单
     * ！！！！！废弃  留着以防万一
     */
    requestRefreshNewWorkorder = (county, city) => {
        WorkorderApi.findNewWorkorder({county, city, createTime: this.state.lastRequestTime}).then(res => {
            let d = new Date();
            let time = `${d.getFullYear()}-${(d.getMonth() + 1).toString().padStart(2, 0)}-${d.getDate().toString().padStart(2, 0)} ${d.getHours().toString().padStart(2, 0)}:${d.getMinutes().toString().padStart(2, 0)}:${d.getSeconds().toString().padStart(2, 0)}`

            if (res.data.length > 0) {
                this.workorderLeftContentRef.current.addWorkorderMonitorTransition();
            }

            this.setState({
                newRequestWorkorder: [...res.data, ...this.state.newRequestWorkorder],
                lastRequestTime: time
            }, () => {
                setTimeout(() => {
                    this.workorderLeftContentRef.current.removeWorkorderMonitorTransition();
                    this.setState({
                        newWorkorder: [...this.state.newRequestWorkorder, ...this.state.newWorkorder],
                        newRequestWorkorder: []
                    })
                }, 8000)
            })
        }).catch(err => {
            console.error(err)
        })
    }

    render() {
        return (<div className={styles.workorder_page}>
            <PageTitle title="赣州市信创数字化工作调度中心"/>
            <div className={styles.workorder_page_content}>
                <WorkorderNumerical/>
                <div className={styles.left_center_right}>
                    <WorkorderLeftContent newWorkorder={this.state.newWorkorder}
                                          newRequestWorkorder={this.state.newRequestWorkorder}
                                          ref={this.workorderLeftContentRef}/>
                    <WorkorderCenterContent/>
                    <WorkorderRightContent/>
                </div>
            </div>
        </div>)
    }
}
