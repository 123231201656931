
import React from "react";
import {withNavigation} from "../../../utils/WithPlusClass";
import styles from "./index.module.css";
import Slider from 'react-slick';
import PageTitle from "../../../components/title/PageTitle";
import FeviceTypeScoresItem from "./components/FeviceTypeScoresItem";
import {Col, Row} from "antd";
import TitleNumInfo from "./components/TitleNumInfo";
import TextNum from "./components/TextNum";
import ManuFacturerApi from "../../../api/manufacturer";
import Logos from "./components/FactuerTitle/logos";
import SpecificationDialog from "./components/SpecificationDialog";

class FeviceTypeScores extends React.Component {

    state = {
        pointData:[],
        aLLfeveiceData:[],
        feveiceData:[],
        newfeveiceData:[],
        id:this.props.location.state.id
    }

    getPoint = () => {
        ManuFacturerApi.point({type:0,refId:this.props.location.state.id}).then(res => {
            this.setState({
                pointData:res.data[0]
            })
        })
    }

    requestfeveiceTypeScores = () => {
        ManuFacturerApi.types({manufacturer:this.state.id}).then(res => {
            this.setState({
                aLLfeveiceData:res.data
            },() => {
                this.requestfeveicePointScores()
            })
        })
    }
    requestfeveicePointScores = () => {
        ManuFacturerApi.point({type:1}).then(res => {
            this.setState({
                feveiceData:res.data
            },()=>{
                let { aLLfeveiceData,feveiceData} = this.state
                let alreadyId = aLLfeveiceData.map(item => {
                    return item.id
                })
                // 根据已经统计的设备类型ID，从全部设备类型中筛选出未统计的
                let newfeveiceData = feveiceData.filter(item => {
                    return alreadyId.includes(item.refId)
                })
                this.setState({
                    newfeveiceData:newfeveiceData
                })

            })
        })

    }

    componentDidMount = () => {
        this.getPoint()
        this.requestfeveiceTypeScores()
    }

    render() {
        const settings = {
            dots: false, // 是否显示分页器
            infinite: false, // 是否循环播放
            slidesToShow: 2, // 一次显示多少个
            slidesToScroll: 1, // 一次滚动几个
            autoplay: false, // 是否自动播放
            autoplaySpeed: 5000, // 自动播放速度
            pauseOnHover: true,// 鼠标悬停
            arrows: false, // 是否显示箭头

        };
        return (
            <div className={styles.fevice_type_page}>
                <PageTitle title="赣州市信创数字化设备监控中心" backIcon/>
                <div className={styles.caseinit_box}>
                    <Row>
                        <Col span={13}>
                            <div className={styles.caseinit_left}>
                                <div className={styles.caseinit_logo}>
                                    <Logos title={this.state.pointData.name}/>
                                </div>
                                <div className={styles.caseinit_grade}>
                                    <img src={require("../../../asset/pfico.png")} alt="评分"/>
                                    <div className={styles.text_num}>
                                        <TitleNumInfo Num={this.state.pointData.totalPoint} Title={"厂商评分"} />
                                    </div>
                                </div>
                                <div className={styles.caseinit_fault}>
                                    <img src={require("../../../asset/gzico.png")} alt="故障"/>
                                    <div className={styles.text_num}>
                                        <TitleNumInfo Num={(this.state.pointData.faultRate * 100).toFixed(2) + '%'} Title={"故障率"}/>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col span={11}>
                            <div className={styles.text_num_box}>
                                <Row gutter={16}>
                                    <Col span={6}><TextNum Title={"已录入设备类型量"} Num={this.state.pointData.deviceTypeNum}/></Col>
                                    <Col span={6}><TextNum Title={"已录入设备总量"} Num={this.state.pointData.deviceNum}/></Col>
                                    <Col span={6}><TextNum Title={"已出故障工单总量"} Num={this.state.pointData.faultWorkorderNum}/></Col>
                                    <Col span={6}><TextNum Title={"已出故障设备总量"} Num={this.state.pointData.faultDeviceNum}/></Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className={styles.fevice_type_box}>
                    <Slider {...settings}>
                        {this.state.newfeveiceData.map((item, index) =>{return <FeviceTypeScoresItem feveiceData={item} key={index} />})}
                        {/*<FeviceTypeScoresItem title={"超越微型计算机-申泰DT3010-L33"}/>*/}
                    </Slider>
                </div>
            </div>
        )
    }
}

export default withNavigation(FeviceTypeScores);