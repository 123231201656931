import React, {useEffect, useState} from 'react';
import styles from "./index.module.css"
import WorkorderApi from "../../../../../api/workorder/WorkorderApi";
import EventBus from "../../../../../utils/EventBus";

/**
 * @author       : AngelBeats
 * @date         : 2023-03-20 14:00:36
 * @description  : 工单指标项
 */
const WorkorderNumerical = () => {

    const [workorderTotalNum, setWorkorderTotalNum] = useState("加载中...");
    const [workorderTheDispatchNum, setWorkorderTheDispatchNum] = useState("加载中...");
    const [workorderTheReceiveNum, setWorkorderTheReceiveNum] = useState("加载中...");
    const [workorderTheFinishNum, setWorkorderTheFinishNum] = useState("加载中...");
    const [workorderFinishNum, setWorkorderFinishNum] = useState("加载中...");
    const [workorderEvaluatedNum, setWorkorderEvaluatedNum] = useState("加载中...");
    const [workorderTimeoutNum, setWorkorderTimeoutNum] = useState("加载中...");

    useEffect(() => {
        requestAllWorkorderNum();
        EventBus.on("toggleCountyData", ({cityName}) => {
            if (cityName === "赣州市") {
                requestAllWorkorderNum();
                return;
            }
            requestAllWorkorderNum(cityName)
        });
        EventBus.on("toggleAreaCountyData", ({cityName}) => {
            if (cityName === "赣州市") {
                requestAllWorkorderNum();
                return;
            }
            requestAllWorkorderNum(cityName)
        })
    }, [])

    /**
     * 调取所有接口
     */
    const requestAllWorkorderNum = (cityName = null) => {
        requestWorkorderTotalNum(cityName);
        requestWorkorderTheDispatchNum(cityName);
        requestWorkorderTheReceiveNum(cityName);
        requestWorkorderTheFinishNum(cityName);
        requestWorkorderFinishNum(cityName);
        requestWorkorderEvaluatedNum(cityName);
        requestWorkorderTimeoutNum(cityName);
    }

    /**
     * 获取工单总数
     */
    const requestWorkorderTotalNum = (county = null) => {
        WorkorderApi.findWorkorderNumber({city: "赣州市", county}).then(res => {
            setWorkorderTotalNum(res.data)
        }).catch(err => {
            console.error("request workorder total num failed", err)
        })
    }

    /**
     * 获取工单待指派数
     */
    const requestWorkorderTheDispatchNum = (county = null) => {
        WorkorderApi.findUnassignedWorkorderNumber({city: "赣州市", converted: 0, county}).then(res => {
            setWorkorderTheDispatchNum(res.data)
        }).catch(err => {
            console.error("request workorder the dispatch num failed", err)
        })
    }

    /**
     * 获取待接收工单
     */
    const requestWorkorderTheReceiveNum = (county = null) => {
        WorkorderApi.findWorkorderNumber({city: "赣州市", progress: 5, county}).then(res => {
            setWorkorderTheReceiveNum(res.data)
        }).catch(err => {
            console.error("request workorder the receive failed", err)
        })
    }

    /**
     * 获取待完成工单
     */
    const requestWorkorderTheFinishNum = (county = null) => {
        WorkorderApi.findWorkorderNumber({city: "赣州市", progress: 13, county}).then(res => {
            setWorkorderTheFinishNum(res.data)
        }).catch(err => {
            console.error("request workorder the finish failed", err)
        })
    }

    /**
     * 获取已完成工单
     */
    const requestWorkorderFinishNum = (county = null) => {
        WorkorderApi.findWorkorderNumber({city: "赣州市", progress: 17, county}).then(res => {
            setWorkorderFinishNum(res.data)
        }).catch(err => {
            console.error("request workorder finish failed", err)
        })
    }

    /**
     * 获取已评价工单
     */
    const requestWorkorderEvaluatedNum = (county = null) => {
        WorkorderApi.findWorkorderEvaluatedNumber({city: "赣州市", county}).then(res => {
            setWorkorderEvaluatedNum(res.data)
        }).catch(err => {
                console.error("request workorder evaluated num failed", err)
            }
        )
    }

    /**
     * 获取超期工单
     */
    const requestWorkorderTimeoutNum = (county = null) => {
        WorkorderApi.findDelayedWorkorder({city: "赣州市", county}).then(res => {
            setWorkorderTimeoutNum(res.data)
        }).catch(err => {
            console.error("request workorder timeout failed", err)
        })
    }

    return (<div className={styles.workorder_numerical_box}>
        <div className={`${styles.numerical_item} ${styles.numerical_workorder_total}`}>
            <span
                className={`${styles.item_value} ${styles.numerical_workorder_total_value}`}>{workorderTotalNum}</span>
            <span className={styles.item_label}>工单总数</span>
        </div>
        <div className={`${styles.numerical_item} ${styles.numerical_workorder_dispatch}`}>
            <span
                className={`${styles.item_value} ${styles.numerical_workorder_dispatch_value}`}>{workorderTheDispatchNum}</span>
            <span className={styles.item_label}>待指派</span>
        </div>
        <div className={`${styles.numerical_item} ${styles.numerical_workorder_receive}`}>
            <span
                className={`${styles.item_value} ${styles.numerical_workorder_receive_value}`}>{workorderTheReceiveNum}</span>
            <span className={styles.item_label}>待接收</span>
        </div>
        <div className={`${styles.numerical_item} ${styles.numerical_workorder_finish}`}>
            <span
                className={`${styles.item_value} ${styles.numerical_workorder_finish_value}`}>{workorderTheFinishNum}</span>
            <span className={styles.item_label}>待完成</span>
        </div>
        <div className={`${styles.numerical_item} ${styles.numerical_workorder_already_finish}`}>
            <span
                className={`${styles.item_value} ${styles.numerical_workorder_already_finish_value}`}>{workorderFinishNum}</span>
            <span className={styles.item_label}>已完成</span>
        </div>
        <div className={`${styles.numerical_item} ${styles.numerical_workorder_evaluate}`}>
            <span
                className={`${styles.item_value} ${styles.numerical_workorder_evaluate_value}`}>{workorderEvaluatedNum}</span>
            <span className={styles.item_label}>已评价</span>
        </div>
        <div className={`${styles.numerical_item} ${styles.numerical_workorder_timeout}`}>
            <span
                className={`${styles.item_value} ${styles.numerical_workorder_timeout_value}`}>{workorderTimeoutNum}</span>
            <span className={styles.item_label}>超期工单</span>
        </div>
    </div>);
};

export default WorkorderNumerical;
