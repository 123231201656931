import React, {Component} from "react";
import styles from "./index.module.css";
import PropTypes from "prop-types"
import * as echarts from "echarts";
import ManuFacturerApi from "../../../../../api/manufacturer";
import {getOneDecimalPlace, shuffleArray} from "../../../../../utils/tool";

class CaseEcharts extends Component {

    static defaultProps = {
        refId: ""
    }

    faultChart;
    responsesChart;
    satisfactionChart;
    faultChartRef = React.createRef();
    responsesChartRef = React.createRef();
    satisfactionChartRef = React.createRef();

    state = {
        device_failure_data: {}, // 设备故障情况 type 0
        work_order_responses: {}, // 工单响应情况 type 1
        work_order_satisfaction: {}, // 工单满意情况 type 2
        colorData: [
            ['#366fff', '#ee6666', '#fac858', '#90ca74', '#d48265', '#91c7ae'],
            ['#ee6666', '#fac858', '#90ca74', '#d48265', '#91c7ae', '#366fff'],
            ['#fac858', '#90ca74', '#d48265', '#91c7ae', '#366fff', '#ee6666',]
        ]
    }

    componentDidMount() {
        this.init();
        this.requestFaultChance(this.props.refId)
        // 监听窗口大小变化
        window.addEventListener('resize', this.handleResize);

    }

    componentWillUnmount() {
        // 移除事件监听器
        window.removeEventListener('resize', this.handleResize);
        // 销毁图表实例
        this.faultChart.dispose(); // 销毁图表实例
        this.responsesChart.dispose(); // 销毁图表实例
        this.satisfactionChart.dispose(); // 销毁图表实例
    }

    handleResize = () => {
        if (this.faultChart) {
            this.faultChart.resize();
        }
        if (this.responsesChart) {

            this.responsesChart.resize();

        }
        if (this.satisfactionChart) {

            this.satisfactionChart.resize();
        }
    };

    /**
     * 获取设备故障类型数据
     * @param refId
     */
    requestFaultChance = (refId) => {
        ManuFacturerApi.pointdetail(refId).then(res => {
            if (res.status === 20000) {
                res.data.forEach(item => {
                    console.log(item.point);
                    if (item.type === 0) {
                        this.setState({device_failure_data: item}, () => {
                            this.faultChart.setOption(this.pieOptions(0, this.state.device_failure_data))
                            this.setState({device_failure_data: item})
                        })
                    } else if (item.type === 1) {
                        this.setState({work_order_responses: item}, () => {
                            this.responsesChart.setOption(this.pieOptions(1, this.state.work_order_responses))
                            this.setState({work_order_responses: item})
                        })
                    } else if (item.type === 2) {
                        this.setState({work_order_satisfaction: item}, () => {
                            this.satisfactionChart.setOption(this.pieOptions(2, this.state.work_order_satisfaction))
                            this.setState({work_order_satisfaction: item})
                        })
                    }
                })

            }

        })
    }

    /**
     * 构造饼图图表配置项
     */
    pieOptions = (index, data) => (

        {

            title: {
                //text: data.type === 0 ? "设备故障类型" : data.type === 1 ? "工单响应情况" : "工单满意情况",
                x: "0%",
                y: '0%',
                textStyle: {
                    fontSize: 12,
                    fontWeight: 200,
                    color: '#fff'
                },
            },
            color: this.state.colorData[index],
            tooltip: {
                trigger: 'item',
            },
            calculable: true,
            series: [
                {
                    //name:'访问来源',
                    type: 'pie',
                    radius: '60%',
                    center: ['50%', '45%'],
                    label: {
                        alignTo: 'edge',
                        formatter: '{d}%\n{name|{b}}',
                        minMargin: 1,
                        edgeDistance: 10,
                        lineHeight: 15,
                        color: "#FFFFFF",
                        rich: {}
                    },
                    data: data.lists.map(item => {
                        return {
                            name: item.name,
                            value: item.num
                        }
                    })
                }
            ]
        }
    )

    init = () => {
        // 初始化图表实例
        this.faultChart = echarts.init(this.faultChartRef.current);
        this.responsesChart = echarts.init(this.responsesChartRef.current);
        this.satisfactionChart = echarts.init(this.satisfactionChartRef.current);

    }

    render() {
        return (
            <div className={styles.case_echarts_box}>
                <div className={styles.title}>设备故障情况</div>
                <div className={styles.text_num_pie_echarts_box}>
                    <div className={styles.text_num}>
                        <div className={styles.titTag}>设备故障分</div>
                        <div className={styles.titNum}>
                            {
                                this.state.device_failure_data.point === 0 || this.state.device_failure_data.point
                                    ? getOneDecimalPlace(this.state.device_failure_data.point)
                                    : 70
                            }
                        </div>
                        <div className={styles.tittip}>设备故障分（总分70）</div>
                    </div>
                    {Object.keys(this.state.work_order_satisfaction).length === 0 ?
                        <div ref={this.faultChartRef} className={styles.no_pie_echarts}/> :
                        <div ref={this.faultChartRef} className={styles.pie_echarts}/>
                    }

                </div>
                <div className={styles.title}>工单响应情况</div>
                <div className={styles.text_num_pie_echarts_box}>
                    <div className={styles.text_num}>
                        <div className={styles.titTag}>工单响应分</div>
                        <div className={styles.titNum}>
                            {
                                this.state.work_order_responses?.point === 0 || this.state.work_order_responses.point
                                    ? getOneDecimalPlace(this.state.work_order_responses.point)
                                    : 20
                            }
                        </div>
                        <div className={styles.tittip}>工单响应分（总分20）</div>
                    </div>
                    {Object.keys(this.state.work_order_responses).length === 0 ?
                        <div ref={this.responsesChartRef} className={styles.no_pie_echarts}/> :
                        <div ref={this.responsesChartRef} className={styles.pie_echarts}/>
                    }
                </div>
                <div className={styles.title}>工单满意情况</div>
                <div className={styles.text_num_pie_echarts_box}>
                    <div className={styles.text_num}>
                        <div className={styles.titTag}>工单满意分</div>
                        <div className={styles.titNum}>
                            {
                                this.state.work_order_satisfaction.point === 0 || this.state.work_order_satisfaction.point
                                    ? getOneDecimalPlace(this.state.work_order_satisfaction.point)
                                    : 10
                            }
                        </div>
                        <div className={styles.tittip}>工单满意分（总分10）</div>
                    </div>
                    {Object.keys(this.state.work_order_satisfaction).length === 0 ?
                        <div ref={this.satisfactionChartRef} className={styles.no_pie_echarts}/> :
                        <div ref={this.satisfactionChartRef} className={styles.pie_echarts}/>
                    }
                </div>
            </div>
        )
    }
}

CaseEcharts.propTypes = {
    refId: PropTypes.string,
}

export default CaseEcharts;