import React from 'react';
import {Spin} from "antd";
import styles from "./index.module.css"

/**
 * @author       : AngelBeats
 * @date         : 2023-04-23 09:47:33
 * @description  : 渲染loading加载提示
 */
const RenderLoading = (props) => {
    return (<>
        {
            props.isLoading ? <div className={styles.loading_box} style={{height: props.height ? props.height : ""}}>
                <Spin/>
            </div> : null
        }
    </>);
};

export default RenderLoading;
