import {get} from "../../https";

const StorehouseApi = {

    /**
     * 获取所有仓库
     * @returns {Promise<AxiosResponse<any>>}
     */
    requestAllStorehouse: () => get("/repository/repositories"),

    /**
     * 查询库存数量
     * @returns {Promise<AxiosResponse<any>>}
     */
    findStockNumber: params => get("/repository/repository-stock/sum", params),

    /**
     * 查询备件总价值
     * @returns {Promise<AxiosResponse<any>>}
     */
    findStockWorth: params => get("/repository/repository-stock/sum-price", params),

    /**
     * 查询所有备件
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    findAllStockList: params => get("/repository/repository-stock", params),

    /**
     * 查询仓库详情
     * @param id
     * @returns {Promise<AxiosResponse<any>>}
     */
    findStorehouseDetail: id => get(`/repository/repository/${id}`),

    /**
     * 查询仓库数量
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    findStorehouseNumber: params => get("/repository/repository/count", params)

}

export default StorehouseApi
