import React from 'react';
import styles from "./index.module.css"

/**
 * @author       : AngelBeats
 * @date         : 2023-04-21 14:41:18
 * @description  : 渲染空数据
 */
const RenderEmptyData = (props) => {
    return (<div className={styles.empty_box}>
        <div>
            <img src={require("../../asset/empty_data_icon.png")} alt="" width={props.width ? props.width : ""}/>
            <p style={{width: "100%", textAlign: "center"}}>{props?.title ?? "暂无数据..."}</p>
        </div>
    </div>);
};

export default RenderEmptyData;
