import React, {Component} from 'react';
import {withNavigation} from "../../../../utils/WithPlusClass";
import styles from "./index.module.css"
import PageTitle from "../../../../components/title/PageTitle";
import RenderFileView from "../../../../components/file/RenderFileView";
import {message, Steps} from "antd";
import RenderWorkorderProgress from "../../../../components/workorder/RenderWorkorderProgress";
import RenderWorkorderServiceType from "../../../../components/workorder/RenderWorkorderServiceType";
import WorkorderApi from "../../../../api/workorder/WorkorderApi";
import AccountApi from "../../../../api/account/AccountApi";

/**
 * @author       : AngelBeats
 * @date         : 2023-03-17 11:07:40
 * @description  : 工单详情页面
 */
class WorkorderDetail extends Component {

    state = {
        customer: {},
        deviceLogs: [],
        deviceWorkorderList: [],
        workorder: {},
        accountData: {}
    }

    componentDidMount() {
        if (this.props?.location?.state?.workorderId === undefined) {
            message.error("无工单信息!")
            return;
        }
        this.requestWorkorderInfo();
    }

    /**
     * 查询工单详情
     */
    requestWorkorderInfo = () => {
        WorkorderApi.findWorkorderInfo(this.props.location.state.workorderId).then(res => {
            this.setState({workorder: res.data});
            this.requestAccountDetail(res.data.creatorId)
        }).catch(err => {
            console.error("request workorder info failed", err)
        })
    }

    /**
     * 渲染工单日志
     * @param logs
     */
    renderWorkorderLogs = logs => logs.map(item => ({
        title: <div style={{color: "#BEC3FF", fontSize: "14px"}}>{item.log}</div>,
        description: <div style={{color: "#BEC3FF", fontSize: "12px"}}>{item.createTime}</div>
    }));

    /**
     * 获取工程师详情
     * @param id
     */
    requestAccountDetail = id => {
        AccountApi.findAccountDetail({accountId: id}).then(res => {
            this.setState({accountData: res.data})
        }).catch(err => {
            console.error("request account detail failed", err)
        })
    }

    render() {
        const {workorder} = this.state
        return (<div className={styles.workorder_detail_page}>
            <PageTitle title="赣州市信创数字化工作调度中心" backIcon/>
            <div className={styles.workorder_detail_main}>
                <div className={`${styles.workorder_detail_box} ${styles.device_manufacturer_info}`}>
                    <div className={`${styles.workorder_detail_content}`}>
                        <div className={styles.workorder_detail_item}>
                            <span className={styles.workorder_detail_item_label}>
                                <img
                                    src={require("../../../../asset/workorder/workorder_detail_creator_icon.png")}
                                    alt="" className={styles.workorder_detail_item_icon}/>
                                创建人:
                            </span>
                            <span
                                className={styles.workorder_detail_item_value}> {this.state.workorder.origin === "customer" ? "本工单由客户发起" : this.state.accountData?.name ?? "-"}</span>
                        </div>
                    </div>
                    <div className={styles.workorder_detail_content}>
                        <div className={styles.workorder_detail_item}>
                            <span className={styles.workorder_detail_item_label}>
                                <img
                                    src={require("../../../../asset/workorder/workorder_detail_create_time_icon.png")}
                                    alt="" className={styles.workorder_detail_item_icon}/>
                                创建时间:
                            </span>
                            <span
                                className={styles.workorder_detail_item_value}>{workorder?.createTime ?? "-"}</span>
                        </div>
                    </div>
                    <div className={styles.workorder_detail_content}>
                        <div className={styles.workorder_detail_item}>
                            <span className={styles.workorder_detail_item_label} style={{width: "auto"}}>
                                <img
                                    src={require("../../../../asset/workorder/workorder_detail_origin_icon.png")}
                                    alt="" className={styles.workorder_detail_item_icon}/>
                                来源:
                            </span>
                            <span
                                className={styles.workorder_detail_item_value}>{workorder.origin === "default" ? "客服报修" : "扫码报修"}</span>
                        </div>
                    </div>
                </div>
                <div className={styles.workorder_detail_box}>
                    <div className={styles.workorder_detail_content}>
                        <div className={styles.workorder_detail_item}>
                            <span className={styles.workorder_detail_item_label}>
                                <img src={require("../../../../asset/device/detail/device_detail_customer_icon.png")}
                                     alt="" className={styles.workorder_detail_item_icon}/>
                                客户姓名:
                            </span>
                            <span className={styles.workorder_detail_item_value}>{workorder?.clientName ?? "-"}</span>
                        </div>
                        <div className={styles.workorder_detail_item}>
                            <span className={styles.workorder_detail_item_label}>
                                <img src={require("../../../../asset/device/detail/device_detail_name_icon.png")}
                                     alt="" className={styles.workorder_detail_item_icon}/>
                                当前进度:
                            </span>
                            <span
                                className={styles.workorder_detail_item_value}>{RenderWorkorderProgress(workorder?.progress)}</span>
                        </div>
                        <div className={styles.workorder_detail_item}>
                            <span className={styles.workorder_detail_item_label}>
                                <img src={require("../../../../asset/device/detail/device_detail_expire_time_icon.png")}
                                     alt="" className={styles.workorder_detail_item_icon}/>
                                合同名称:
                            </span>
                            <span
                                className={styles.workorder_detail_item_value}>{workorder?.resourceName ?? "-"}</span>
                        </div>
                        <div className={styles.workorder_detail_item}>
                            <span className={styles.workorder_detail_item_label}>
                                <img
                                    src={require("../../../../asset/device/detail/device_detail_descriptions_icon.png")}
                                    alt="" className={styles.workorder_detail_item_icon}/>
                                问题描述:
                            </span>
                            <span
                                className={`${styles.workorder_detail_item_value} ${styles.workorder_detail_descriptions}`}>{workorder?.description ?? "-"}</span>
                        </div>
                        <div className={styles.workorder_detail_item}>
                            <span className={styles.workorder_detail_item_label}>
                                <img
                                    src={require("../../../../asset/device/detail/device_detail_file_icon.png")}
                                    alt="" className={styles.workorder_detail_item_icon}/>
                                工单附件:
                            </span>
                            <span
                                className={`${styles.workorder_detail_item_value} ${styles.workorder_detail_item_file}`}>
                                <RenderFileView file={workorder?.files ?? []}/>
                            </span>
                        </div>
                    </div>
                    <div className={styles.workorder_detail_content}>
                        <div className={styles.workorder_detail_item}>
                            <span className={styles.workorder_detail_item_label}>
                                <img
                                    src={require("../../../../asset/device/detail/device_detail_customer_department_icon.png")}
                                    alt="" className={styles.workorder_detail_item_icon}/>
                                客户单位:
                            </span>
                            <span
                                className={styles.workorder_detail_item_value}>{workorder?.clientDepartmentName ?? "-"}</span>
                        </div>
                        <div className={styles.workorder_detail_item}>
                            <span className={styles.workorder_detail_item_label}>
                                <img src={require("../../../../asset/device/detail/device_detail_type_icon.png")}
                                     alt="" className={styles.workorder_detail_item_icon}/>
                                计划时间:
                            </span>
                            <span
                                className={styles.workorder_detail_item_value}>{workorder?.deadLine ?? "-"}</span>
                        </div>
                        <div className={styles.workorder_detail_item}>
                            <span className={styles.workorder_detail_item_label}>
                                <img src={require("../../../../asset/device/detail/device_detail_address_icon.png")}
                                     alt="" className={styles.workorder_detail_item_icon}/>
                                单位地址:
                            </span>
                            <span
                                className={styles.workorder_detail_item_value}>{workorder?.clientDepartmentProvince + "-" + workorder?.clientDepartmentCity + "-" + workorder?.clientDepartmentCounty + "-" + workorder?.clientDepartmentAddress ?? "-"}</span>
                        </div>
                        <div className={styles.workorder_detail_item}>
                            <span className={styles.workorder_detail_item_label}>
                                <img
                                    src={require("../../../../asset/device/detail/device_detail_descriptions_icon.png")}
                                    alt="" className={styles.workorder_detail_item_icon}/>
                                处理结果:
                            </span>
                            <span
                                className={`${styles.workorder_detail_item_value} ${styles.workorder_detail_descriptions}`}>{workorder?.result ?? "-"}</span>
                        </div>
                        <div className={styles.workorder_detail_item}>
                            <span className={styles.workorder_detail_item_label}>
                                <img
                                    src={require("../../../../asset/device/detail/device_detail_file_icon.png")}
                                    alt="" className={styles.workorder_detail_item_icon}/>
                                处理附件:
                            </span>
                            <span
                                className={`${styles.workorder_detail_item_value} ${styles.workorder_detail_item_file}`}>
                                <RenderFileView file={workorder?.resultFiles ?? []}/>
                            </span>
                        </div>
                    </div>
                    <div className={styles.workorder_detail_content}>
                        <div className={styles.workorder_detail_item}>
                            <span className={styles.workorder_detail_item_label}>
                                <img src={require("../../../../asset/device/detail/device_detail_id_icon.png")}
                                     alt="" className={styles.workorder_detail_item_icon}/>
                                工单编号:
                            </span>
                            <span
                                className={styles.workorder_detail_item_value}>{workorder?.id ?? "-"}</span>
                        </div>
                        <div className={styles.workorder_detail_item}>
                            <span className={styles.workorder_detail_item_label}>
                                <img
                                    src={require("../../../../asset/device/detail/device_detail_install_time_icon.png")}
                                    alt="" className={styles.workorder_detail_item_icon}/>
                                预约时间:
                            </span>
                            <span
                                className={styles.workorder_detail_item_value}>{workorder?.appointmentTime ?? "-"}</span>
                        </div>
                        <div className={styles.workorder_detail_item}>
                            <span className={styles.workorder_detail_item_label}>
                                <img
                                    src={require("../../../../asset/device/detail/device_detail_install_time_icon.png")}
                                    alt="" className={styles.workorder_detail_item_icon}/>
                                服务类型:
                            </span>
                            <span
                                className={styles.workorder_detail_item_value}>{RenderWorkorderServiceType(workorder?.type ?? "-")}</span>
                        </div>
                        <div className={styles.workorder_detail_item}>
                            <span className={styles.workorder_detail_item_label}>
                                <img
                                    src={require("../../../../asset/device/detail/device_detail_install_time_icon.png")}
                                    alt="" className={styles.workorder_detail_item_icon}/>
                                客户电话:
                            </span>
                            <span
                                className={styles.workorder_detail_item_value}>{workorder?.clientCellphone ?? "-"}</span>
                        </div>
                        {/*<div className={styles.workorder_detail_item}>*/}
                        {/*    <span className={styles.workorder_detail_item_label}>*/}
                        {/*        <img src={require("../../../../asset/device/detail/device_detail_log_icon.png")}*/}
                        {/*             alt="" className={styles.workorder_detail_item_icon}/>*/}
                        {/*        日志信息:*/}
                        {/*    </span>*/}
                        {/*    <span className={`${styles.workorder_detail_item_value} ${styles.workorder_detail_log}`}>*/}
                        {/*         <Steps*/}
                        {/*             progressDot*/}
                        {/*             current={8}*/}
                        {/*             size="small"*/}
                        {/*             direction="vertical"*/}
                        {/*             className={styles.workorder_detail_step}*/}
                        {/*             items={this.state.deviceLogs}/>*/}
                        {/*    </span>*/}
                        {/*</div>*/}

                    </div>
                </div>
                <div className={styles.device_detail_workorder}>
                    <div className={styles.device_detail_workorder_title}>
                        工单日志
                    </div>
                    {workorder.logs ? <span className={`${styles.workorder_detail_log}`}>
                                 <Steps
                                     progressDot
                                     current={8}
                                     size="small"
                                     direction="vertical"
                                     className={styles.workorder_detail_step}
                                     items={this.renderWorkorderLogs(workorder.logs)}/>
                    </span> : null}
                </div>
            </div>
        </div>)
    }
}

export default withNavigation(WorkorderDetail)
