import React from "react";
import styles from "./index.module.css";
import FactuerTitle from "../FactuerTitle";
import {Col, Row} from "antd";
import PropTypes from "prop-types"
import TextNum from "../TextNum";
import TitleNumInfo from "../TitleNumInfo";
import ResponseStatusEcharts from "../ResponseStatusEcharts";
import DeviceFailureEcharts from "../DeviceFailureEcharts";
import IssueDetailEcharts from "../IssueDetailEcharts";
import ManuFacturerApi from "../../../../../api/manufacturer";
import SpecificationDialog from "../SpecificationDialog";

class FeviceTypeScoresItem extends React.Component{

    static defaultProps = {
        feveiceData: {},

    }
    state = {
        device_failure_point:70,
     }

    updateIssueDetailEchartsRef = React.createRef();
    SpecificationDialogRef = React.createRef();
    componentDidMount() {
        let data = {
            deviceTypeId: this.props.feveiceData.refId,
        }
        this.updateDeviceFailureEcharts(data)//初始化默认数据
        this.getsdasdasd(this.props.feveiceData.refId)
    }

    getsdasdasd = (id) => {
        ManuFacturerApi.pointdetail(id).then(res=> {
            if (res.status === 20000) {
                res.data.map(item => {
                    if (item.type === 0){
                        this.setState({device_failure_point:item.point})
                    }
                })
            }
        })
    }

    updateDeviceFailureEcharts = (data) => {
        ManuFacturerApi.faultdetail(data.deviceTypeId,{refId: data.refId}).then(res=> {
            if (res.status === 20000) {
                this.updateIssueDetailEchartsRef.current.updateEcharts(data.name, res.data)
            }
        })
    }

    showshuoming = () => {
        this.SpecificationDialogRef.current.show()

    }

    render() {
        return (
            <div className={styles.fevice_type_scores_item}>
                <FactuerTitle title={this.props.feveiceData.name}/>

                <Row gutter={16}>
                    <Col className="gutter-row" span={12}>
                        <div className={styles.text_num_box}>
                            <Row gutter={16}>

                                <Col span={8}><TextNum Title={"已录入设备总量"} Num={this.props.feveiceData.deviceNum}/></Col>
                                <Col span={8}><TextNum Title={"已出故障设备总量"}
                                                       Num={this.props.feveiceData.faultDeviceNum}/></Col>
                                <Col span={8}><TextNum Title={"已出故障工单总量"} Num={this.props.feveiceData.faultWorkorderNum}/></Col>
                            </Row>
                            <div className={styles.grade_fault_box}>
                                <div className={styles.grade}>
                                    <img src={require("../../../../../asset/shebei.png")} alt="评分"/>
                                    <div className={styles.text_num} onClick={this.showshuoming}>
                                        <TitleNumInfo Num={this.props.feveiceData.totalPoint?.toFixed(0)} Title={"设备类型评分"}
                                                      Tag={"评分说明"}/>
                                        <SpecificationDialog ref={this.SpecificationDialogRef}/>
                                    </div>
                                </div>
                                <div className={styles.fault}>
                                    <img src={require("../../../../../asset/gzico.png")} alt="故障"/>
                                    <div className={styles.text_num}>
                                        <TitleNumInfo Num={(this.props.feveiceData.faultRate * 100).toFixed(2) + '%' } Title={"故障率"}/>
                                    </div>
                                </div>

                            </div>
                            <div className={styles.text_echarts}>
                                <div className={styles.titleline}>工单响应情况</div>
                                <ResponseStatusEcharts refId={this.props.feveiceData.refId} type={1} point={20} titTag={"工单响应分"} tittip={"工单响应度"} />
                            </div>
                        </div>

                    </Col>
                    <Col className="gutter-row" span={12}>

                        <div className={styles.device_response_box}>
                            <div className={styles.titleline}>设备故障情况</div>
                            <DeviceFailureEcharts onUpdate={this.updateDeviceFailureEcharts} point={this.state.device_failure_point} refId={this.props.feveiceData.refId} />
                            <IssueDetailEcharts ref={this.updateIssueDetailEchartsRef}/>
                            <div className={styles.titleline}>工单满意情况</div>
                            <ResponseStatusEcharts refId={this.props.feveiceData.refId} type={2} point={10} titTag={"工单满意分"} tittip={"工单满意度"}/>
                        </div>

                    </Col>
                </Row>

            </div>
        )
    }

}

FeviceTypeScoresItem.propTypes = {
    feveiceData:PropTypes.object,
}

export default FeviceTypeScoresItem