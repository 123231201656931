const workorderProgress = (progress) => {

    const result = {
        0: "驳回",
        1: "待指派",
        5: "待领取",
        9: "待处理",
        13: "处理中",
        14: "挂起中",
        17: "已完成"
    }

    return result[progress] ?? "-"

}

export default workorderProgress;
