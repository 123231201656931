const feedbackScore = (score) => {
    const result = {
        0: "待回访",
        1: "不满意",
        2: "满意",
        3: "非常满意",
    }

    return result[score] ?? "正在评价中"
}

export default feedbackScore;
