import React, {Component} from 'react';
import PageTitle from "../../../../components/title/PageTitle";
import {withNavigation} from "../../../../utils/WithPlusClass";

import styles from "./index.module.css"
import DetailLeftContent from "./components/DetailLeftContent";
import DetailCenterContent from "./components/DetailCenterContent";
import DetailRightContent from "./components/DetailRightContent";
import DetailPersonSituation from "./components/DetailPersonSituation";

/**
 * @author       : AngelBeats
 * @date         : 2023-03-14 10:13:53
 * @description  : 工程师详情
 */
class EngineerDetail extends Component {

    componentDidMount() {
        // console.log(this.props)
    }

    render() {
        const {accountId} = this.props.location.state
        return (<div className={styles.detail_view}>
            <PageTitle title="赣州市信创数字化运维监控中心" backIcon/>
            <div className={styles.detail_view_content}>
                <div>
                    <DetailLeftContent accountId={accountId}/>
                </div>
                <div style={{marginLeft: 16}}>
                    <div style={{display: "flex"}}>
                        <DetailCenterContent accountId={accountId}/>
                        <DetailRightContent accountId={accountId}/>
                    </div>
                    <div>
                        <DetailPersonSituation accountId={accountId}/>
                    </div>
                </div>
            </div>
        </div>)
    }
}

export default withNavigation(EngineerDetail)

