const workorderServiceType = (type) => {

    const result = {
        "rjwh": "软件维护",
        "tyjwh-ty": "通用机维护（天玥）",
        "tyjwh-cyst": "通用机维护（超越申泰）",
        "gcoawh": "国产OA维护",
        "xtrjwh": "系统软件维护",
        "zyjwh": "专用机维护",
        "zyjwh-cyst-DT3000-L12": "专用机维护（超越申泰 DT3000-L12）",
        "zyjwh-cyst-DT3010-L12": "专用机维护（超越申泰 DT3010-L12）",
        "tyjwh": "通用机维护",
        "tyjwh-ty-TR1192": "通用机维护（天玥 TR1192）",
        "tyjwh-cystDT-3010-F": "通用机维护（超越申泰DT-3010-F）",
        "tyjwh-cystDT-3010-L": "通用机维护（超越申泰DT-3010-L）",
        "dyjwh": "打印机维护",
        "dyjwh-gdtsmy-OEM200M": "打印机维护（光电通扫描仪 OEM200M）",
        "dyjwh-gdtbsytj-OEM3000DN": "打印机维护（光电通白色一体机 OEM3000DN）",
        "dyjwh-gdthsytj-OEP400DN": "打印机维护（光电通黑色一体机 OEP400DN）",
        "dyjwh-gdt-OEP102D": "打印机维护（光电通 OEP102D）",
        "dyjwh-bt-M7105DN": "打印机维护（奔图 M7105DN）",
        "yjaz": "硬件安装",
        "xtxj": "系统巡检",
        "ldcl": "漏洞处理",
        "dyjhf": "打印机换粉",
        "yjhsxh": "硬件回收销毁",
        "zzwjxh": "纸质文件销毁",
        "others-customer": "其他客户发起",
        "wzwh": "网站维护",
    }

    return result[type] ?? "-"

}

export default workorderServiceType;
