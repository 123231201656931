import React, {Component} from 'react';
import styles from "./index.module.css"
import {withNavigation} from "../../../../../utils/WithPlusClass";
import DepartmentApi from "../../../../../api/department/DepartmentApi";
import EventBus from "../../../../../utils/EventBus";
import RenderLoading from "../../../../../components/loading/RenderLoading";
import WorkorderApi from "../../../../../api/workorder/WorkorderApi";
import RenderEmptyData from "../../../../../components/empty/RenderEmptyData";

/**
 * @author       : AngelBeats
 * @date         : 2023-03-20 15:55:03
 * @description  : 区域监控右侧内容
 */
class AreaRightContent extends Component {

    state = {
        departmentList: [],
        loading: false
    }

    componentDidMount() {
        this.requestAllDepartment();
        EventBus.on("toggleAreaCountyData", ({cityName}) => {
            if (cityName === "赣州市") {
                this.requestAllDepartment();
                return;
            }
            this.requestAllDepartment(cityName);
        })
    }

    componentWillUnmount() {
        EventBus.off("toggleAreaCountyData", () => {
        })
    }

    /**
     * 跳转单位页面
     * @param id
     */
    previewDepartmentPage = id => {
        this.props.navigate(`/home/department/${id}`)
    }

    /**
     * 查询所有单位
     * @param county
     */
    requestAllDepartment = (county = null) => {
        this.setState({loading: true})
        DepartmentApi.findAllDepartment({city: "赣州市", county}).then(res => {
            res.data.forEach(item => item.count = 0)
            this.setState({departmentList: res.data}, () => {
                this.requestDepartmentWorkorderNumber(res.data.map(item => item.id))
            })
        }).catch(err => {
            console.error("request all department failed", err)
        }).finally(() => {
            this.setState({loading: false})
        })
    }

    /**
     * 查询单位工单数量
     * @param departmentIds
     */
    requestDepartmentWorkorderNumber = (departmentIds) => {
        WorkorderApi.findDepartmentWorkorderNumber(departmentIds).then(res => {
            let {departmentList} = this.state;
            departmentList.forEach(dept => {
                res.data.forEach(deptCount => {
                    if (dept.id === deptCount.name) {
                        dept.count = deptCount.value
                    }
                })
            })
            this.setState({departmentList: [...departmentList]})
        }).catch(err => {
            console.error("request department workorder number failed", err)
        })
    }

    render() {
        const {departmentList} = this.state
        return (<div className={styles.area_right_content}>
            <div className={styles.department_list}>
                <div className={styles.department_list_title}>
                    <span>单位列表</span>
                    <span>已发工单</span>
                </div>
                <div className={styles.department_list_content}>
                    {departmentList.map(item => <div className={styles.department_item}
                                                     onClick={() => this.previewDepartmentPage(item.id)} key={item.id}>
                        <span className={styles.department_name}>
                            {item?.name ?? "-"}
                        </span>
                        <span className={styles.department_workorder}>{item?.count}</span>
                    </div>)}
                    {departmentList.length === 0 && !this.state.loading ? <RenderEmptyData/> : null}
                    <RenderLoading isLoading={this.state.loading}/>
                </div>
            </div>
        </div>)
    }
}

export default withNavigation(AreaRightContent)
