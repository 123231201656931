import PropTypes from "prop-types"
import styles from "./index.module.css"
const TitleNumInfo = ({Title,Num,Tag}) => {

    return(
        <div className={styles.text_num}>
            <div className={styles.title}>
                {Title}
                {Tag ? <span className={styles.tag}>{Tag}</span> : <></>}
            </div>
            <div className={styles.num}>{Num}</div>

        </div>
    )
}

TitleNumInfo.propTypes = {
    Title: PropTypes.string,
    Num: PropTypes.string,
    Tag : PropTypes.string
}

export default TitleNumInfo;