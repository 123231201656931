import React, {Component} from 'react';
import {withNavigation} from "../../../../../utils/WithPlusClass";

import styles from "./index.module.css"
import RenderEmptyData from "../../../../../components/empty/RenderEmptyData";

/**
 * @author       : AngelBeats
 * @date         : 2023-03-23 08:58:13
 * @description  : 单位子单位详情
 */
class ChildDetail extends Component {

    /**
     * 查看设备详情
     * @param item
     */
    previewDeviceDetail = (item) => {
        this.props.navigate("/home/device-detail", {state: {deviceId: item.id}})
    }

    render() {
        return (<div className={styles.child_device_list}>
            {this.props.deviceData.length > 0 ? this.props.deviceData.map(item => <div
                className={styles.child_device_list_item} key={item.id}>
                <div className={styles.child_device_list_content} onClick={() => this.previewDeviceDetail(item)}>
                    <div className={styles.device_department_customer}>
                        <span className={styles.device_list_value}>所属单位：{item.customer?.department?.name}</span>
                        <span className={styles.device_list_value}>所属客户：{item.customer?.name}</span>
                    </div>
                    <div className={styles.device_department_customer}>
                        <span className={styles.device_list_value}>设备名称：{item.name}</span>
                        <span className={styles.device_list_value}>维护次数：{item.count}</span>
                    </div>
                    <div className={styles.device_department_customer}>
                        <span className={styles.device_list_value}>设备型号：{item.model}</span>
                        <span
                            className={styles.device_list_value}>门牌号码：{item?.storey === "" ? "-" : item.storey}</span>
                    </div>
                </div>
                {/*<div className={styles.detail}>*/}
                {/*    详情*/}
                {/*</div>*/}
            </div>) : <RenderEmptyData/>}
        </div>)
    }
}

export default withNavigation(ChildDetail)
