import React, {Component} from 'react';
import styles from "./index.module.css"
import * as echarts from "echarts";
import EventBus from "../../../../../utils/EventBus";

/**
 * @author       : AngelBeats
 * @date         : 2023-03-20 15:26:54
 * @description  : 工单监控中间内容
 */
export default class WorkorderCenterContent extends Component {

    chart = null;

    // 当前选中的区县
    selectCity = "all";

    state = {
        gdGeoCoordMap: {
            "章贡区": [114.93872, 25.851367],
            "南康区": [114.756933, 25.661721],
            "赣县区": [115.118461, 25.995432],
            "信丰县": [114.930893, 25.38023],
            "大余县": [114.362243, 25.395937],
            "上犹县": [114.540537, 25.794284],
            "崇义县": [114.307348, 25.687911],
            "安远县": [115.392328, 25.134591],
            "定南县": [115.03267, 24.774277],
            "全南县": [114.531589, 24.742651],
            "宁都县": [116.018782, 26.472054],
            "于都县": [115.411198, 25.955033],
            "兴国县": [115.439495, 26.519303],
            "会昌县": [115.791158, 25.599125],
            "寻乌县": [115.651399, 24.954136],
            "石城县": [116.342249, 26.326582],
            "瑞金市": [116.034854, 25.875278],
            "龙南市": [114.792657, 24.90476]
        },
        mapColor: {
            "border": "#2A458C",
            "mapBg": "#062041",
            "selectArea": "#00FAFF",
            "firstOutsideBorderColor": "#00FAFF"
        },
        selectMapAreaIcon: [undefined, undefined],
        selectAreaIconWidth: 0,
        selectAreaCityName: "赣州市"
    };

    componentDidMount() {
        this.init();
    }

    componentWillUnmount() {
        // 销毁map
        this.chart.dispose()
    }

    /**
     * 地图配置项
     */
    option = () => ({
        geo: [
            {
                map: "map",
                aspectScale: 1,
                zoom: 0.7,
                layoutCenter: ["50%", "50%"],
                layoutSize: "140%",
                show: true,
                roam: false,
                itemStyle: {
                    // 最上层边框样式
                    borderColor: this.state.mapColor.firstOutsideBorderColor,
                    borderWidth: 5,
                    // shadowColor: "#6FFDFF",
                    // shadowOffsetY: 0,
                    // shadowBlur: 10,
                    // areaColor: "rgba(29,85,139,.5)"
                },
                emphasis: {
                    // areaColor: "rgba(29,85,139,.6)"
                    label: {
                        show: true,
                        color: this.state.mapColor.border
                    },
                    itemStyle: {
                        color: "#9F2121FF"
                    },
                    color: "#fff"
                }
            },
            {
                map: "map",
                aspectScale: 1,
                zoom: 0.7,
                layoutCenter: ["50%", "50%"],
                layoutSize: "140%",
                show: true,
                roam: false,
                itemStyle: {
                    // 最上层边框颜色
                    borderColor: this.state.mapColor.firstOutsideBorderColor,
                    borderWidth: 5,
                    // shadowColor: "#8cd3ef",
                    // shadowOffsetY: 10,
                    // shadowBlur: 120,
                    // 每一块选中颜色
                    areaColor: this.state.mapColor.border
                },
                emphasis: {
                    areaColor: this.state.mapColor.border,
                    label: {
                        color: this.state.mapColor.border,
                        show: true,
                    },
                    itemStyle: {
                        color: this.state.mapColor.border
                    },
                    color: this.state.mapColor.border
                }
            },
            // 重影
            {
                type: "map",
                map: "map",
                zlevel: -1,
                aspectScale: 1,
                zoom: 0.7,
                layoutCenter: ["50%", "50.7%"],
                layoutSize: "140%",
                roam: false,
                silent: true,
                itemStyle: {
                    borderWidth: 4,
                    borderColor: {
                        type: "linear",
                        x: 0,
                        y: 0,
                        x2: 0,
                        y2: 1,
                        // 第二层边框颜色
                        colorStops: [
                            {
                                offset: 0,
                                color: this.state.mapColor.border // 0% 处的颜色
                            },
                            {
                                offset: 0.8,
                                color: this.state.mapColor.border // 0% 处的颜色
                            },
                            {
                                offset: 1,
                                color: this.state.mapColor.border // 50% 处的颜色
                            }
                        ]
                    },
                    // shadowColor: "rgba(172, 122, 255,0.5)",
                    // shadowOffsetY: 5,
                    // shadowBlur: 15,
                    areaColor: this.state.mapColor.border
                }
            },
            {
                type: "map",
                map: "map",
                zlevel: -2,
                aspectScale: 1,
                zoom: 0.7,
                layoutCenter: ["50%", "51.4%"],
                layoutSize: "140%",
                roam: false,
                silent: true,
                itemStyle: {
                    // 第三层边框样式
                    borderWidth: 5,
                    borderColor: this.state.mapColor.border,
                    // shadowColor: "rgba(65, 214, 255,0.6)",
                    // shadowOffsetY: 5,
                    // shadowBlur: 15,
                    areaColor: this.state.mapColor.border
                }
            },
            {
                type: "map",
                map: "map",
                zlevel: -3,
                aspectScale: 1,
                zoom: 0.7,
                layoutCenter: ["50%", "52.1%"],
                layoutSize: "140%",
                roam: false,
                silent: true,
                itemStyle: {
                    // 第四层边框样式
                    borderWidth: 6,
                    borderColor: this.state.mapColor.border,
                    // shadowColor: "rgba(29, 111, 165,0.5)",
                    // shadowOffsetY: 15,
                    // shadowBlur: 8,
                    areaColor: this.state.mapColor.border
                }
            }
        ],
        series: [
            {
                name: "赣州市数据",
                type: "map",
                map: "map", // 自定义扩展图表类型
                aspectScale: 1,
                roam: false,
                zoom: 0.7, // 缩放
                showLegendSymbol: true,
                label: {
                    show: true,
                    color: "#fff",
                    fontSize: "15px"
                },
                itemStyle: {
                    areaColor: {
                        type: "linear",
                        x: 1200,
                        y: 0,
                        x2: 0,
                        y2: 0,
                        // 最上层背景颜色
                        colorStops: [
                            {
                                offset: 0,
                                color: this.state.mapColor.mapBg // 0% 处的颜色
                            },
                            {
                                offset: 1,
                                color: this.state.mapColor.mapBg // 50% 处的颜色
                            }
                        ],
                        global: true // 缺省为 false
                    },
                    borderColor: "#01E2E9",
                    borderWidth: 0.5,
                },
                emphasis: {
                    itemStyle: {
                        // 鼠标经过每一块颜色
                        show: true,
                        color: "#fff",
                        areaColor: this.state.mapColor.mapBg,
                        borderWidth: 3,
                        borderColor: this.state.mapColor.selectArea
                    },
                    label: {
                        show: true,
                        color: "#fff"
                    }
                },
                // 选中的快样式
                select: {
                    label: {
                        color: "#fff"
                    },
                    itemStyle: {
                        color: this.state.mapColor.mapBg,
                        borderColor: this.state.mapColor.selectArea,
                        borderWidth: 3
                    }
                },
                layoutCenter: ["50%", "50%"],
                layoutSize: "140%",
                markPoint: {
                    symbol: "none"
                },
                data: this.cityJsonData().features.map((item) => {
                    return {
                        name: item.properties.name
                    };
                })
            },
            {
                tooltip: {
                    show: false
                },
                type: "effectScatter",
                coordinateSystem: "geo",
                rippleEffect: {
                    brushType: "stroke"
                },
                showEffectOn: "render",
                // 每一块小点样式
                itemStyle: {
                    color: {
                        type: "radial",
                        x: 0.5,
                        y: 0.5,
                        r: 0.5,
                        colorStops: [
                            {
                                offset: 0,
                                color: "#D7C420"
                            },
                            {
                                offset: 0.6,
                                color: "rgba(234,197,15,0.74)"
                            },
                            {
                                offset: 0.85,
                                color: "transparent"
                            },
                            {
                                offset: 1,
                                color: "#FFF"
                            }
                        ],
                        global: false // 缺省为 false
                    }
                },
                label: {
                    color: "#fff"
                },
                symbol: "circle",
                symbolSize: 18,
                data: this.convertData(this.cityJsonData().features.map((item) => {
                    return {
                        name: item.properties.name
                    };
                })),
                zlevel: 1
            },
            {
                name: "赣州市",
                coordinateSystem: "geo",
                type: "custom",
                renderItem: (params, api) => {
                    return {
                        type: "image",
                        style: {
                            image: require("../../../../../asset/selec_map_icon.png"),
                            x: api.coord(this.state.selectMapAreaIcon)[0] - 8,
                            y: api.coord(this.state.selectMapAreaIcon)[1] - 45,
                            width: this.state.selectAreaIconWidth
                        },
                        offset: [80, 80]
                    };
                },
                data: [{name: this.state.selectAreaCityName, value: [...this.state.selectMapAreaIcon, undefined]}],
                symbolSize: function (val) {
                    return val[2] / 10;
                },
                showEffectOn: "render",
                rippleEffect: {
                    brushType: "stroke"
                },
                emphasis: {
                    scale: true
                },
                label: {
                    formatter: "{b}",
                    position: "right",
                    show: true
                },
                zlevel: 1
            },
        ]
    });

    /**
     * 数据转换
     * @param data
     * @returns {*[]}
     */
    convertData = (data) => {
        let res = [];
        for (let i = 0; i < data.length; i++) {
            let geoCoord = this.state.gdGeoCoordMap[data[i].name];
            if (geoCoord) {
                res.push({
                    name: data[i].name,
                    value: geoCoord.concat(data[i].value)
                });
            }
        }
        return res;
    };

    /**
     * 区县数据
     * @returns {*}
     */
    cityJsonData = () => {
        return require("../../../../../asset/GanZhouCity.json");
    };

    /**
     * 地图点击事件
     * @param item
     */
    onChartClick = (item) => {
        if (item.seriesType === "effectScatter") {
            return;
        }
        if (item.seriesType === "custom") {
            this.setState({
                selectAreaCityName: item.data.name
            }, () => {
                this.chart.setOption(this.option());
                this.selectCity = item.data.name
            })
            return;
        }
        if (this.selectCity === item.data.name) {
            this.setState({
                mapColor: {
                    "border": "#2A458C",
                    "mapBg": "#062041",
                    "selectArea": "#00FAFF",
                    "firstOutsideBorderColor": "#00FAFF",
                },
                selectMapAreaIcon: [undefined, undefined],
                selectAreaIconWidth: 0,
                selectAreaCityName: "赣州市"
            }, () => {
                this.chart.setOption(this.option());
                this.selectCity = "all"
            })
            EventBus.emit("toggleCountyData", {cityName: "赣州市"})
            return;
        }

        this.setState({
            mapColor: {
                "border": "#2A458C",
                "mapBg": "#062041",
                "selectArea": "#00FAFF",
                "firstOutsideBorderColor": "#2A458C",
            },
            selectAreaCityName: item.data.name,
            selectMapAreaIcon: this.state.gdGeoCoordMap[item.data.name],
            selectAreaIconWidth: 15
        }, () => {
            this.chart.setOption(this.option());
            this.selectCity = item.data.name
        })
        EventBus.emit("toggleCountyData", {cityName: item.data.name})
    }

    /**
     * 返回市级
     */
    mapBack = () => {
        if (this.selectCity !== "all") {
            this.chart.dispose();
            this.setState({
                mapColor: {
                    "border": "#2A458C",
                    "mapBg": "#062041",
                    "selectArea": "#00FAFF",
                    "firstOutsideBorderColor": "#00FAFF",
                },
                selectMapAreaIcon: [undefined, undefined],
                selectAreaIconWidth: 0,
                selectAreaCityName: "赣州市"
            }, () => {
                this.chart.setOption(this.option());
                this.selectCity = "all"
            })
            this.init();
            EventBus.emit("toggleCountyData", {cityName: "赣州市"});
        }

    }

    /**
     * 初始化地图
     */
    init = () => {
        this.chart = echarts.init(document.getElementById("workorder_map"), null, {devicePixelRatio: 3});
        echarts.registerMap("map", this.cityJsonData());
        this.chart.setOption(this.option());
        this.chart.on("click", this.onChartClick);
    };

    render() {
        return (<div className={styles.workorder_map_chart_page}>
            <div className={styles.workorder_map_chart_title_box}>
                <div className={styles.workorder_map_chart_title}>{this.state.selectAreaCityName}</div>
                <div className={styles.workorder_chart_label}>
                    <span className={styles.workorder_chart_now_value}>当前展示</span>
                    <span className={styles.workorder_chart_data_value}>数据</span>
                </div>
            </div>
            <div className={styles.map_back} onClick={this.mapBack}>
                返回市级界面
                <img src={require("../../../../../asset/map_back_icon.png")} alt="" className={styles.map_back_icon}/>
            </div>
            <div id="workorder_map" style={{width: "100%", height: "100%"}}/>
        </div>)
    }
}
