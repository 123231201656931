import React, {Component} from 'react';
import styles from "./css/dialog.module.css";

/**
 * @author       : AngelBeats
 * @date         : 2023-04-11 09:36:16
 * @description  : 接入厂商弹窗
 */
export default class DeviceInsertManufacturer extends Component {

    state = {
        open: false
    }

    /**
     * 开启弹窗
     */
    show = () => this.setState({open: true})

    /**
     * 关闭弹窗
     */
    close = () => this.setState({open: false})

    render() {
        return (<>
            {
                this.state.open ? <div className={styles.dialog_view} onClick={this.close}>
                    <div className={styles.dialog_title}>接入厂商</div>
                    <div className={styles.device_insert_manufacturer_box} onClick={(e) => e.stopPropagation()}>
                        <img src={require("../../../../asset/device/qlrj_logo.png")} alt=""/>
                        <img src={require("../../../../asset/device/gdt_logo.png")} alt=""/>
                        <img src={require("../../../../asset/device/mp_logo.png")} alt=""/>
                        <img src={require("../../../../asset/device/skww_logo.png")} alt=""/>
                        <img src={require("../../../../asset/device/qax_logo.png")} alt=""/>
                        <img src={require("../../../../asset/device/zf_logo.png")} alt=""/>
                        <img src={require("../../../../asset/device/cyst_logo.png")} alt=""/>
                        <img src={require("../../../../asset/device/rdjc_logo.png")} alt=""/>
                    </div>
                </div> : null
            }
        </>)
    }
}
