import React, {Component} from 'react';
import {Collapse, message, Segmented} from 'antd';

import styles from "./css/index.module.css"
import "./css/index.css"
import "./css/EngineerList.css"

import defaultAvatar from "../../../../asset/defaultAvatar.jpeg";
import defaultDepartmentMaker from "../../../../asset/engineer/storehouse_icon.png";
import defaultDepartmentLogo from "../../../../asset/defaultDepartmentLogo.svg"
import engineerOnline from "../../../../asset/engineer/engineer_online_icon.png";
import engineerOffline from "../../../../asset/engineer/engineer_offline_icon.png";

import AccountApi from "../../../../api/account/AccountApi";
import WorkorderApi from "../../../../api/workorder/WorkorderApi";

import PageTitle from "../../../../components/title/PageTitle";
import {withNavigation} from "../../../../utils/WithPlusClass";
import {RightOutlined} from "@ant-design/icons";
import EngineerOrder from "./components/EngineerOrder";
import DepartmentList from "./components/DepartmentList";
import EngineerDetailDialog from "./dialog/EngineerDetailDialog";
import StorehouseApi from "../../../../api/storehouse/StorehouseApi";
import EdgeBorder from "../../../../components/box/EdgeBorder";
import dayjs from "dayjs";

/**
 * @author       : AngelBeats
 * @date         : 2023-03-10 08:32:15
 * @description  : 工程师地图
 */
class EngineerMap extends Component {

    engineerDetailDialogRef = React.createRef();

    map;
    cluster;
    makersDialog;
    makerDialog;

    // 刷新工程师地图定时器
    refreshEngineerTimer;

    state = {
        isShowEngineerOrder: false,
        isShowAllDepartment: false,
        isQueryAll: true,
        // 工作中用户
        workingAccountList: [],
        // 忙碌中用户
        busyingAccountList: [],
        // 休息中用户
        restingAccountList: [],
        // 所有用户机构
        accountDepartmentList: [],
        accountNumber: undefined,
        departmentWorkorderNumber: undefined,
        accountCount: "-",
    }

    componentDidMount() {
        this.initMap();
        this.renderPoints(false);
        this.requestAllDepartment();
        window.engineerGroupClick = (id) => {
            this.engineerDetailDialogRef.current.show(id)
        }
        this.refreshEngineerTimer = setInterval(() => {
            this.destroyMarkerCluster();
            this.renderPoints(this.state.isQueryAll);
            // this.requestAllDepartment();
        }, 15000)
    }

    componentWillUnmount() {
        clearInterval(this.refreshEngineerTimer);
        this.refreshEngineerTimer = null;
    }

    /**
     * 初始化地图
     */
    initMap = () => {
        this.map = new global.AMap.Map("device-map-container", {
            pitch: 0,
            viewMode: "3D",
            terrain: true,
            zoom: 15,
            resizeEnable: true,
            zooms: [4.6, 18],
            center: [114.973172, 25.817861],
            mapStyle: "amap://styles/darkblue"
        });
    }

    /**
     * 销毁聚合点
     */
    destroyMarkerCluster = () => {
        this.cluster.setMap(null);
    }

    /**
     * 查询用户积分
     */
    requestAccountIntegration = (accountId) => {
        AccountApi.queryUserScore(accountId).then(res => {
            let accountIntegration = document.getElementById("account-integration");
            accountIntegration.innerHTML = res.data;
        }).catch(err => {
            console.error("Request account integration failed", err)
        })

    }

    /**
     * 获取用户完成工单总数
     */
    requestWorkorderTotal = (accountId) => {
        WorkorderApi.findWorkorderNumber({handlerId: accountId, progress: 17}).then(res => {
            console.log(res)
            let finishWorkorder = document.getElementById("finish-workorder");
            finishWorkorder.innerHTML = res.data
        }).catch(err => {
            console.error("Request workorder total", err)
        })
    }

    /**
     * 获取用户单日完成工单数
     */
    requestWorkorderTodayTotal = (accountId) => {
        let time = new Date();
        WorkorderApi.findWorkorderNumber({
            handlerId: accountId,
            startTime: `${time.getFullYear()}-${(time.getMonth() + 1).toString().padStart(2, 0)}-${time.getDate().toString().padStart(2, 0)} 00:00:00`,
            endTime: `${time.getFullYear()}-${(time.getMonth() + 1).toString().padStart(2, 0)}-${time.getDate().toString().padStart(2, 0)} 23:59:59`
        }).then(res => {
            let workorderTodayTotal = document.getElementById("today-finish-workorder");
            workorderTodayTotal.innerHTML = res.data
        }).catch(err => {
            console.error("Request workorder today total", err)
        })
    }

    /**
     * 获取用户工单好评率
     */
    requestWorkorderGoodReview = (accountId) => {
        WorkorderApi.findWorkorderGoodReview({handler: accountId}).then(res => {
            console.log(res)
            let workorderGoodReview = document.getElementById("workorder-good-review");
            workorderGoodReview.innerHTML = res.data.toFixed(2) * 100 + "%"
        }).catch(err => {
            console.error("Request workorder total", err)
        })
    }

    /**
     * 查询用户正在进行中的工单
     * @param accountId
     */
    requestOngoingWorkorder = (accountId) => {
        WorkorderApi.ongoingWorkorder(accountId).then(res => {
            let customerDepartment = document.getElementById("customer-department");
            let customerName = document.getElementById("customer-name");
            let address = document.getElementById("address");
            customerDepartment.innerHTML = res.data?.clientDepartmentName ?? "---";
            customerName.innerHTML = res.data?.clientName ?? "---";
            address.innerHTML = res.data?.clientDepartmentAddress ?? "---";
        }).catch(err => {
            console.error("Request ongoing workorder failed", err)
        })
    }

    /**
     * 渲染工程师列表聚合点弹窗
     * @param item
     */
    renderAssemblyDialog = (item) => {
        let content = `<div class="engineer-list-box">
               <div class="engineer-list-title">工程师列表</div>
               <div class="engineer-list">
                  ${item.clusterData.map(itemY => `<div class="engineer-list-item" onclick="engineerGroupClick('${itemY.location.account}')">
                        <img src="${itemY?.avatar ? itemY.avatar : defaultAvatar}" class="engineer-avatar-img">
                        <div class="engineer-name-department">
                            <p class="engineer-list-name" style="display: flex;justify-content: space-between">
                                ${itemY?.name}
                                <span style="${itemY.isOffline ? 'color:#C0C0C0' : 'color:#fff'}">[ ${itemY.isOffline ? "离线" : "在线"} ]</span>
                            </p>
                            <p class="engineer-list-department">${itemY?.department?.name}</p>
                        </div>
                    </div>`).join("")}
               </div>
        </div>`
        this.makersDialog = new global.AMap.InfoWindow({
            content,
            offset: new global.AMap.Pixel(38, 30),
            closeWhenClickMap: false
        })
        this.makersDialog.open(this.map, item.clusterData[0].lnglat);
    }

    /**
     * 渲染单个工程师详情弹窗
     * @returns {string}
     */
    renderDialog = (item) => {
        if (item.clusterData.length === 1) {
            let content = `<div class='engineer-box'>
            <div class="engineer-department">
              <span class="department-name">${item?.clusterData[0]?.department?.name}</span>
            </div>
            <div class="engineer-info">
              <img src="${item?.clusterData[0].avatar ? item?.clusterData[0].avatar : defaultAvatar}" alt=""/>
              <div class="engineer-name-mark">
                 <p class="engineer-name">${item?.clusterData[0].name}</p>
                 <p class="engineer-mark"><span>积分：</span><span id="account-integration">---</span></p>
              </div>
            </div>
            <p class="today-work text-style"><span>今日完成工单：</span><span id="today-finish-workorder">--</span></p>
            <p class="total-work text-style"><span>总计完成工单：</span><span id="finish-workorder">---</span></p>
            <p class="praise text-style"><span>工单好评率：</span><span id="workorder-good-review">---</span></p>
            <div class="engineer-department now-work">
              <span class="department-name">当前工单</span>
            </div>
            <p class="customer-department text-style"><span>客户单位：</span><span id="customer-department">---</span></p>
            <p class="customer-name text-style"><span>客户姓名：</span><span id="customer-name">---</span></p>
            <p class="address text-style"><span>详细地址：</span><span id="address">---</span></p>
            <div class="detail" id="engineer-detail">详情</div>
        </div>`
            this.makerDialog = new global.AMap.InfoWindow({
                content,
                offset: new global.AMap.Pixel(28, 0),
                closeWhenClickMap: false
            })
            this.requestAccountIntegration(item?.clusterData[0].location.account);
            this.requestWorkorderTotal(item?.clusterData[0].location.account);
            this.requestWorkorderTodayTotal(item?.clusterData[0].location.account);
            this.requestWorkorderGoodReview(item?.clusterData[0].location.account);
            this.requestOngoingWorkorder(item?.clusterData[0].location.account);
            this.makerDialog.open(this.map, item.lnglat);
            const engineerDetail = document.getElementById("engineer-detail");
            engineerDetail.addEventListener("click", () => {
                this.props.navigate("/home/engineer-detail", {state: {accountId: item?.clusterData[0].location.account}})
            })
        } else {
            this.renderAssemblyDialog(item);
        }

    }

    /**
     * 自定义用户机构 maker 点
     * @param item
     */
    renderDepartmentMaker = item => `<div class="maker-box">
                              <img src="${defaultDepartmentMaker}">
                              <div class="department-maker-name">
                                <img src="${item?.logo ? item.logo : defaultDepartmentLogo}" alt="" class="department-maker-logo">
                                <span>${item?.name}</span>
                              </div>
                        </div>`

    /**
     * 自定义机构详情弹窗
     * @param item
     * @returns {string}
     */
    renderDepartmentDetailDialog = item => `<div class="engineer_department_box" style="padding-bottom: 3.5vh">
                        <div class="engineer_department_title">
                            <div class="engineer_department_logo_box"><img src="${item.logo ? item.logo : defaultDepartmentLogo}" alt="" class="engineer_department_logo"></div>
                            <div class="department_info_box">
                                <span class="department_name_span">${item?.name}</span>
                                <span class="department_engineer_num">
                                    <img src="${require("../../../../asset/engineer/engineer_num_icon.png")}" alt="" class="department_engineer_icon">
                                    <span id="department_engineer_num">工程师数：-</span>
                                </span>
                            </div>
                        </div>
                        <div class="department_work_item_box">
                            <span class="department_work_item">
                                <img src="${require("../../../../asset/engineer/workorder_good_icon.png")}" class="department_engineer_icon" alt="">
                                <span id="department_workorder_evaluate">工单好评率: -</span>
                            </span>
                            <span class="department_work_item">
                                <img src="${require("../../../../asset/engineer/workorder_good_icon.png")}" class="department_engineer_icon" alt="">
                                <span id="department_workorder_number">完成工单总数: -</span>
                            </span>
                            <span class="department_work_item">
                                <img src="${require("../../../../asset/engineer/storehouse_num_icon.png")}" class="department_engineer_icon" alt="">
                                <span id="department_storehouse_number">仓库数量: -</span>
                            </span>
                        </div>
                        <div class="engineer_department_detail" id="engineer_department_detail">详情</div>
                </div>`

    /**
     * 自定义非聚合点样式
     * @param item
     */
    renderMaker = (item) => {
        let content = `<div class="maker-box">
                          <img src="${item?.data[0]?.isOffline ? engineerOffline : engineerOnline}" style="width: 70px;">
                          <span class="maker-name">${item?.data[0].name}</span>
                        </div>`
        item.marker.setContent(content)
    }

    /**
     * 自定义聚合点样式
     * @private
     * @param item
     */
    _renderClusterMarker = function (item) {
        let content = `<div class="maker-box">
                          <img src="${engineerOnline}" style="width: 70px;">
                          <span class="maker-name" style="left: 22px">${item?.count}位</span>
                        </div>`
        item.marker.setContent(content)
    };

    /**
     * 获取工程师位置信息 并标点
     */
    renderPoints = (queryAll = true) => {
        AccountApi.requestAccountLocation().then(response => {
            let accountData = []
            let restingAccountList = []
            let workingAccountList = []
            let busyingAccountList = []
            response.data.forEach(item => {
                if (item.location !== null) {
                    if (queryAll) {
                        accountData.push(item)
                    }

                    if (!queryAll && (dayjs().unix() - 300) - dayjs(item.location.createTime).unix() < 0) {
                        accountData.push(item);
                    }
                }
                if (item.situation === 0) {
                    restingAccountList.push(item)
                }
                if (item.situation === 1) {
                    workingAccountList.push(item)
                }
                if (item.situation === 2) {
                    busyingAccountList.push(item)
                }
            })
            this.setState({
                restingAccountList,
                workingAccountList,
                busyingAccountList,
                accountCount: response.data.length
            })
            this.cluster = new global.AMap.MarkerCluster(
                this.map,
                accountData.map(item => ({
                    lnglat: [item.location.longitude, item.location.latitude],
                    isOffline: (dayjs().unix() - 300) - dayjs(item.location.createTime).unix() > 0,
                    ...item
                })),
                {
                    gridSize: 80,
                    renderClusterMarker: this._renderClusterMarker, // 自定义聚合点样式
                    renderMarker: this.renderMaker // 自定义非聚合点样式
                }
            );
            this.cluster.on("click", this.renderDialog);

        }).catch(error => console.warn("Request points failed.", error));
    };

    /**
     * 根据经纬度查询位置
     */
    findAddress = (address) => {
        if (address.includes(undefined)) {
            message.error("暂无该工程师坐标信息!")
            return;
        }
        this.map.setZoomAndCenter(18, address);
    }

    /**
     * 渲染工程师列表头部
     * @param img
     * @param content
     * @returns {JSX.Element}
     */
    renderPanelHeader = (img, content) => <>
        <img src={img} alt="" className={styles.working_img}/>
        <span className={styles.panel_header_title}>{content}</span>
    </>

    /**
     * 渲染工程师列表箭头
     * @param props
     */
    renderExpandIcon = (props) => {
        if (props.panelKey === "1") {
            return <>
                <span className={styles.list_item_engineer_num}>{this.state.workingAccountList.length}</span>
                <RightOutlined rotate={props.isActive ? 90 : 0} style={{color: "#FFFFFF"}}/>
            </>
        }
        if (props.panelKey === "2") {
            return <>
                <span className={styles.list_item_engineer_num}>{this.state.busyingAccountList.length}</span>
                <RightOutlined rotate={props.isActive ? 90 : 0} style={{color: "#FFFFFF"}}/>
            </>
        }
        if (props.panelKey === "3") {
            return <>
                <span className={styles.list_item_engineer_num}>{this.state.restingAccountList.length}</span>
                <RightOutlined rotate={props.isActive ? 90 : 0} style={{color: "#FFFFFF"}}/>
            </>
        }
    }

    /**
     * 展示工程师排行
     */
    showEngineerOrder = () => {
        this.setState({
            isShowEngineerOrder: !this.state.isShowEngineerOrder
        })
    }

    /**
     * 点击查看所有机构
     */
    previewAllDepartment = () => {
        let allDepartment = document.getElementById("all-department");
        allDepartment.style.transition = "all 0.2s ease-in-out";
        if (this.state.isShowAllDepartment) {
            allDepartment.style.rotate = "0deg";
            this.setState({
                isShowAllDepartment: false
            });
            return;
        }
        allDepartment.style.rotate = "90deg";
        this.setState({
            isShowAllDepartment: true
        });
    }

    /**
     * 查询所有用户机构  并地图标点
     */
    requestAllDepartment = () => {
        AccountApi.requestAccountAllDepartment().then(res => {
            this.setState({accountDepartmentList: res.data})
            let markerList = [];
            let lnglats = [];

            res.data.forEach(item => {
                lnglats.push(item)
                if (item.longitude !== null && item.latitude !== null) {
                    let marker = new global.AMap.Marker({
                        position: [item.longitude, item.latitude],
                        content: this.renderDepartmentMaker(item),
                        offset: [-30, -20]
                    })
                    markerList.push(marker)
                }
            })
            this.map.add(markerList)

            let infoWindow = new global.AMap.InfoWindow({offset: new global.AMap.Pixel(-5, -10)});

            const markerClick = async (e, item) => {
                infoWindow.setContent(e.target.content);
                infoWindow.open(this.map, e.target.getPosition());

                await this.requestAccountNumber(item.id)
                await this.requestDepartmentWorkorderNumber(item.id)
                await this.requestDepartmentSatisfaction(item.id)
                await this.requestDepartmentStorehouseNumber(item.id)

                let engineerDepartmentDetail = document.getElementById("engineer_department_detail");
                engineerDepartmentDetail.addEventListener("click", () => {
                    this.props.navigate("/home/department-detail", {state: {departmentId: item.id}})
                })
            }
            for (let i = 0; i < lnglats.length; i++) {
                if (lnglats[i].location !== null) {
                    let marker = new global.AMap.Marker({
                        position: [lnglats[i].longitude, lnglats[i].latitude],
                        map: this.map,
                        content: this.renderDepartmentMaker(lnglats[i]),
                        offset: [-30, -20]
                    });
                    marker.content = this.renderDepartmentDetailDialog(lnglats[i]);
                    marker.on('click', (e) => markerClick(e, lnglats[i]));
                }
            }
        }).catch(err => {
            console.error("request all account department failed", err)
        })
    }

    /**
     * 请求工程师数量
     * @param id 单位id
     */
    requestAccountNumber = async (id) => {
        const {data} = await AccountApi.findAccountNumber({departmentId: id}).catch(err => {
            console.error("request account number failed", err)
        })
        if (document.getElementById("department_engineer_num")) {
            let engineerNum = document.getElementById("department_engineer_num");
            engineerNum.innerHTML = `工程师数量:  ${data}位`
        }
    }

    /**
     * 查询单位工单好评率
     * @param id
     */
    requestDepartmentSatisfaction = async (id) => {
        const {data} = await WorkorderApi.findDepartmentSatisfaction({department: id}).catch(err => {
            console.error("request department satisfaction failed", err)
        })
        if (document.getElementById("department_workorder_evaluate")) {
            let department_workorder_evaluate = document.getElementById("department_workorder_evaluate");
            department_workorder_evaluate.innerHTML = `工单好评率: ${(data / 100).toFixed(2)}%`
        }
    }

    /**
     * 请求单位工单数量
     * @param id
     */
    requestDepartmentWorkorderNumber = async (id) => {
        const {data} = await WorkorderApi.findWorkorderNumber({handlerDepartmentId: id}).catch(err => {
            console.error("request department workorder number failed", err)
        })
        if (document.getElementById("department_workorder_number")) {
            let department_workorder_number = document.getElementById("department_workorder_number");
            department_workorder_number.innerHTML = `完成工单总数: ${data}`
        }
    }

    /**
     * 查询单位仓库数量
     * @param id
     * @returns {Promise<void>}
     */
    requestDepartmentStorehouseNumber = async (id) => {
        const {data} = await StorehouseApi.findStorehouseNumber({department: id}).catch(err => {
            console.error("request department storehouse number failed", err)
        })
        if (document.getElementById("department_storehouse_number")) {
            let department_storehouse_number = document.getElementById("department_storehouse_number");
            department_storehouse_number.innerHTML = `仓库数量: ${data}`
        }
    }

    /**
     * 在线离线工程师切换
     * @param value
     */
    previewEngineer = value => {
        this.setState({isQueryAll: value === "全部"});
        this.destroyMarkerCluster();
        if (this.makerDialog){
            this.makerDialog.close();
        }
        if (this.makersDialog){
            this.makersDialog.close();
        }
        if (value === "全部") {
            this.renderPoints();
        }
        if (value === "在线") {
            this.renderPoints(false);
        }
    }

    render() {
        return <div style={{backgroundColor: "#001432"}}>
            <PageTitle title="赣州市信创数字化运维队伍中心" backIcon navigate={this.props.navigate}/>
            <div className={styles.engineer_map_box}>
                <div className={styles.map} id="device-map-container">
                    <div className={styles.engineer_order} onClick={this.showEngineerOrder}>
                        <img src={require("../../../../asset/engineer/order_icon.png")} alt=""
                             className={styles.engineer_order_img}/>
                        <span>工程师排行</span>
                    </div>
                    {this.state.isShowEngineerOrder ? <EngineerOrder/> : null}
                    <EdgeBorder/>
                </div>
                <div className={styles.sidebar}>
                    <div className={styles.sidebar_header}>
                        <div className={styles.icon_and_department_info}>
                            <img src={require("../../../../asset/engineer/department_icon.png")} alt=""
                                 className={styles.department_img}/>
                            <div className={styles.department_info}>
                                <span className={styles.department_name}>{`服务机构：${"所有"}`}</span>
                                <span
                                    className={styles.department_people_num}>{`机构服务人员：${this.state.accountCount}`}</span>
                            </div>
                        </div>
                        <div className={styles.sidebar_more} id="all-department" onClick={this.previewAllDepartment}/>
                    </div>
                    <div className={styles.engineer_list_box}>
                        <div className={styles.engineer_list_title}>
                            <img src={require("../../../../asset/engineer/enginner_list_icon.png")} alt=""
                                 className={styles.engineer_list_title_img}/>
                            <span>工程师列表</span>
                        </div>
                        <Collapse accordion bordered={false} expandIconPosition="end"
                                  expandIcon={this.renderExpandIcon}>
                            <Collapse.Panel
                                header={this.renderPanelHeader(require("../../../../asset/engineer/working_icon.png"), "工作中")}
                                key="1">
                                <div className={styles.limit_box}>
                                    {this.state.workingAccountList.map((item, index) => <div
                                        className={styles.engineer_item} key={index}
                                        onClick={() => this.findAddress([item.location?.longitude, item.location?.latitude])}>
                                        <img src={item?.avatar ? item.avatar : defaultAvatar} alt=""
                                             className={styles.engineer_item_avatar_img}/>
                                        <span className={styles.engineer_item_name}>{item.name}</span>
                                        <span
                                            className={styles.engineer_item_department}>{item?.department?.name}</span>
                                    </div>)}
                                </div>
                            </Collapse.Panel>
                            <Collapse.Panel
                                header={this.renderPanelHeader(require("../../../../asset/engineer/busying_icon.png"), "忙碌中")}
                                key="2">
                                <div className={styles.limit_box}>
                                    {this.state.busyingAccountList.map((item, index) => <div
                                        className={styles.engineer_item} key={index}
                                        onClick={() => this.findAddress([item.location?.longitude, item.location?.latitude])}>
                                        <img src={item?.avatar ? item.avatar : defaultAvatar} alt=""
                                             className={styles.engineer_item_avatar_img}/>
                                        <span className={styles.engineer_item_name}>{item.name}</span>
                                        <span
                                            className={styles.engineer_item_department}>{item?.department?.name}</span>
                                    </div>)}
                                </div>

                            </Collapse.Panel>
                            <Collapse.Panel
                                header={this.renderPanelHeader(require("../../../../asset/engineer/resting_icon.png"), "休息中")}
                                key="3">
                                <div className={styles.limit_box}>
                                    {this.state.restingAccountList.map((item, index) => <div
                                        className={styles.engineer_item} key={index}
                                        onClick={() => this.findAddress([item.location?.longitude, item.location?.latitude])}>
                                        <img src={item?.avatar ? item.avatar : defaultAvatar} alt=""
                                             className={styles.engineer_item_avatar_img}/>
                                        <span className={styles.engineer_item_name}>{item.name}</span>
                                        <span
                                            className={styles.engineer_item_department}>{item?.department?.name}</span>
                                    </div>)}

                                </div>
                            </Collapse.Panel>
                        </Collapse>
                    </div>
                    {this.state.isShowAllDepartment ?
                        <DepartmentList accountDepartmentList={this.state.accountDepartmentList}
                                        findAddress={this.findAddress}/> : null}
                </div>
            </div>
            <div style={{position: "absolute", top: 80, right: '50%', zIndex: 2}}>
                <Segmented options={['在线', '全部']} onChange={this.previewEngineer}/>
            </div>
            <EngineerDetailDialog ref={this.engineerDetailDialogRef}/>
            {/*<Button style={{position: "absolute", zIndex: 10, top: 50, right: 200}}*/}
            {/*        onClick={() => this.findAddress([118.117003, 24.581309])}>查询位置</Button>*/}
            {/*<Button style={{position: "absolute", zIndex: 10, top: 50, right: 350}}*/}
            {/*        onClick={() => this.findAddress([118.116966, 24.481262])}>查询位置2</Button>*/}
            {/*<Button style={{position: "absolute", zIndex: 10, top: 50, right: 500}}*/}
            {/*        onClick={() => this.findAddress([114.973138, 25.818042])}>查询位置3</Button>*/}
        </div>
    }
}

export default withNavigation(EngineerMap);
