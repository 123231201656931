import React, {Component} from 'react';
import PageTitle from "../../../components/title/PageTitle";
import {withNavigation} from "../../../utils/WithPlusClass";
import styles from "./index.module.css"
import DeviceApi from "../../../api/device/DeviceApi";
import WorkorderApi from "../../../api/workorder/WorkorderApi";
import CustomerApi from "../../../api/customer/CustomerApi";
import AccountApi from "../../../api/account/AccountApi";
import StorehouseApi from "../../../api/storehouse/StorehouseApi";
import EdgeBorder from "../../../components/box/EdgeBorder";

/**
 * @author       : AngelBeats
 * @date         : 2023-03-13 09:06:03
 * @description  : 首页
 */
class HomePage extends Component {

    testRef2 = React.createRef();

    state = {
        deviceTotal: 0,
        deviceRepairNumber: 0,
        workorderTotal: 0,
        customerTotal: 0,
        organizationNumber: 0,
        accountNumber: 0,
        stockNumber: [0, 0, 0, 0, 0, 0],
        stockWorth: [0, 0, 0, 0, 0, 0]
    }

    componentDidMount() {
        this.requestDeviceTotal();
        this.requestDeviceRepairNumber();
        this.requestWorkorderTotal();
        this.requestCustomerTotal();
        this.requestOrganizationNumber();
        this.requestAccountNumber();
        this.requestStockNumber();
        this.requestStockWorth();
    }

    /**
     * 获取设备总数量
     */
    requestDeviceTotal = () => {
        DeviceApi.findDeviceTotal().then(res => {
            this.setState({
                deviceTotal: res.data
            })
        }).catch(err => {
            console.error("request device total failed", err)
        })
    }

    /**
     * 获取设备维护次数 ==> 信创总工单数
     */
    requestDeviceRepairNumber = () => {
        WorkorderApi.findWorkorderNumber({logicType: "smp"}).then(res => {
            this.setState({deviceRepairNumber: res.data})
        }).catch(err => {
            console.error("request device repair number failed", err)
        })
    }

    /**
     * 查询工单总数
     */
    requestWorkorderTotal = () => {
        WorkorderApi.findWorkorderNumber().then(res => {
            this.setState({workorderTotal: res.data})
        }).catch(err => {
            console.error("request workorder total failed", err)
        })
    }

    /**
     * 查询客户总数
     */
    requestCustomerTotal = () => {
        CustomerApi.findCustomerTotal().then(res => {
            this.setState({customerTotal: res.data})
        }).catch(err => {
            console.error("request customer total failed", err)
        })
    }

    /**
     * 查询机构总数
     */
    requestOrganizationNumber = () => {
        AccountApi.findOrganizationNumber().then(res => {
            this.setState({
                organizationNumber: res.data
            })
        }).catch(err => {
            console.error("request organization number failed", err)
        })
    }

    /**
     * 查询工程师总数
     */
    requestAccountNumber = () => {
        AccountApi.findAccountNumber().then(res => {
            this.setState({
                accountNumber: res.data
            })
        }).catch(err => {
            console.error("request account number failed", err)
        })
    }

    /**
     * 查询备件总数
     */
    requestStockNumber = () => {
        StorehouseApi.findStockNumber().then(res => {
            if (res.data.length < 6) {
                res.data = res.data.toString().padStart(6, 0)
            }
            if (res.data > 999999) {
                res.data = "999999";
            }
            this.setState({stockNumber: res.data.split("")})
        }).catch(err => {
            console.error("request stock number failed", err)
        })
    }

    /**
     * 查询备件总价值
     */
    requestStockWorth = () => {
        StorehouseApi.findStockWorth().then(res => {
            res.data = Math.ceil(res.data / 10000);
            if (res.data.length < 6) {
                res.data = res.data.toString().padStart(6, 0)
            }
            res.data = res.data.toString().padStart(6, 0)
            this.setState({
                stockWorth: res.data.split("")
            })
        }).catch(err => {
            console.error("request stock worth failed", err)
        })
    }

    /**
     * 跳转工程师地图页面
     */
    engineerMap = () => {
        this.props.navigate("/home/engineer-map")
    }

    /**
     * 跳转仓库地图界面
     */
    storehouseMap = () => {
        this.props.navigate("/home/storehouse-map")
    }

    /**
     * 跳转设备地图界面
     */
    deviceMap = () => {
        this.props.navigate("/home/device-map")
    }

    /**
     * 跳转工作调度页面
     */
    workorder = () => {
        this.props.navigate("/home/workorder")
    }

    /**
     * 跳转区域监控页面
     */
    previewArea = () => {
        this.props.navigate("/home/area")
    }

    render() {
        return (<div className={styles.home_page}>
            <PageTitle title="赣州市信创数字化运维监控中心" exit backIcon backFun={() => {
                this.props.navigate("/");
                localStorage.removeItem("Token");
                localStorage.removeItem("User");
            }}/>
            <div className={styles.top_card_box}>
                <div className={`${styles.top_card} ${styles.device_card}`} onClick={this.deviceMap}>
                    <div className={styles.card_title}>
                        <img src={require("../../../asset/home/card_top_right_img.png")} alt=""
                             className={styles.card_title_img}/>
                        <span className={styles.card_title_content}>设备监控</span>
                        <img src={require("../../../asset/home/card_top_left_img.png")} alt=""
                             className={styles.card_title_img}/>
                    </div>
                    <div className={styles.top_content_div}>
                        <div className={styles.top_card_item}>
                            <img src={require("../../../asset/home/device_total_num.png")} alt=""
                                 className={styles.card_img}/>
                            <span className={styles.top_card_num}>{this.state.deviceTotal}</span>
                            <span className={styles.top_card_content}>当前设备总数</span>
                        </div>
                        <div className={styles.top_card_line}/>
                        <div className={styles.top_card_item}>
                            <img src={require("../../../asset/home/device_card_control_img.png")} alt=""
                                 className={styles.card_img}/>
                            <span className={styles.top_card_num}>{this.state.deviceRepairNumber}</span>
                            <span className={styles.top_card_content}>设备总维护次数</span>
                        </div>
                    </div>
                    <EdgeBorder/>
                </div>
                <img src={require("asset/home/device_thumbnail.jpg")} alt="" className={`${styles.thumbnail} ${styles.device_thumbnail}`}/>
            </div>
            <div className={styles.other_card_box}>
                <div className={styles.left_card_box}>
                    <div className={`${styles.top_card} ${styles.card_top_move} ${styles.work_card}`} onClick={this.workorder}>
                        <div className={styles.card_title}>
                            <img src={require("../../../asset/home/card_top_right_img.png")} alt=""
                                 className={styles.card_title_img}/>
                            <span className={styles.card_title_content}>工作调度</span>
                            <img src={require("../../../asset/home/card_top_left_img.png")} alt=""
                                 className={styles.card_title_img}/>
                        </div>
                        <div className={styles.left_top_card_content}>
                            <div className={styles.left_top_card_item}>
                                <img src={require("../../../asset/home/card_workorder_total.png")} alt=""
                                     className={styles.left_top_card_img}/>
                                <div className={styles.left_top_card_item_content}>
                                    <p className={`${styles.top_card_num} ${styles.left_top_card_num}`}>{this.state.workorderTotal}</p>
                                    <p className={`${styles.top_card_content} ${styles.left_top_card_item_describe}`}>工单总数</p>
                                </div>
                            </div>
                            <div className={styles.left_top_card_item} style={{marginTop: "10px"}}>
                                <img src={require("../../../asset/home/card_customer_total.png")} alt=""
                                     className={styles.left_top_card_img}/>
                                <div className={styles.left_top_card_item_content}>
                                    <p className={`${styles.top_card_num} ${styles.left_top_card_num}`}>{this.state.customerTotal}</p>
                                    <p className={`${styles.top_card_content} ${styles.left_top_card_item_describe}`}>接入客户数</p>
                                </div>
                            </div>
                        </div>

                        <EdgeBorder/>
                    </div>
                    <div className={`${styles.top_card} ${styles.area_card}`} onClick={this.previewArea}>
                        <div className={styles.card_title}>
                            <img src={require("../../../asset/home/card_top_right_img.png")} alt=""
                                 className={styles.card_title_img}/>
                            <span className={styles.card_title_content}>区域监控</span>
                            <img src={require("../../../asset/home/card_top_left_img.png")} alt=""
                                 className={styles.card_title_img}/>
                        </div>
                        <div className={styles.top_content_div}>
                            <img src={require("../../../asset/home/map_img.png")} className={styles.card_map_img}/>
                        </div>

                        <EdgeBorder/>
                    </div>
                    <img src={require("asset/home/work_thumbnail.jpg")} alt="" className={`${styles.thumbnail} ${styles.work_thumbnail}`}/>
                    <img src={require("asset/home/area_thumbnail.jpg")} alt="" className={`${styles.thumbnail} ${styles.area_thumbnail}`}/>
                </div>
                <div className={styles.right_card_box}>
                    <div className={`${styles.top_card} ${styles.card_top_move} ${styles.storehouse_card}`} onClick={this.storehouseMap}>
                        <div className={styles.card_title}>
                            <img src={require("../../../asset/home/card_top_right_img.png")} alt=""
                                 className={styles.card_title_img}/>
                            <span className={styles.card_title_content}>仓库管理</span>
                            <img src={require("../../../asset/home/card_top_left_img.png")} alt=""
                                 className={styles.card_title_img}/>
                        </div>
                        <div className={styles.storehouse_div}>
                            <div className={styles.storehouse_num}>
                                <div className={styles.storehouse_num_title}>
                                    <span>备件总数</span>
                                </div>
                                <div className={styles.storehouse_num_content}>
                                    {this.state.stockNumber ? this.state.stockNumber.map((item, index) => {
                                        if ((index + 1) % 3 === 0 && (index + 1) !== this.state.stockNumber.length) {
                                            return <span key={index}>
                                                    <span className={styles.storehouse_num_content_item}
                                                          key={`,${index}`}>{item}</span>
                                                <span style={{padding: "8px 6px"}} key={`+${index}`}>,</span>
                                            </span>
                                        } else {
                                            return <span className={styles.storehouse_num_content_item}
                                                         key={`-${index}`}>{item}</span>
                                        }

                                    }) : null}
                                </div>
                            </div>
                            <div className={`${styles.storehouse_num} ${styles.storehouse_quota}`}>
                                <div className={styles.storehouse_num_title}>
                                    <span>备件总额 / 万</span>
                                </div>
                                <div className={styles.storehouse_num_content}>
                                    {this.state.stockWorth ? this.state.stockWorth.map((item, index) => {
                                        if ((index + 1) % 3 === 0 && (index + 1) !== this.state.stockWorth.length) {
                                            return <span key={`quota${index}`}>
                                                    <span className={styles.storehouse_num_content_item}
                                                          key={`,${index}`}>{item}</span>
                                                <span style={{padding: "8px 6px"}} key={`+${index}`}>,</span>
                                            </span>
                                        } else {
                                            return <span className={styles.storehouse_num_content_item}
                                                         key={`-${index}`}>{item}</span>
                                        }

                                    }) : null}
                                </div>
                            </div>
                            {/*<div className={styles.storehouse_quota}></div>*/}

                        </div>

                        <span className={styles.card_top_left_corner}/>
                        <span className={styles.card_top_right_corner}/>
                        <span className={styles.card_bottom_left_corner}/>
                        <span className={styles.card_bottom_right_corner}/>
                    </div>
                    <div className={`${styles.top_card} ${styles.engineer_card}`} onClick={this.engineerMap}>
                        <div className={styles.card_title}>
                            <img src={require("../../../asset/home/card_top_right_img.png")} alt=""
                                 className={styles.card_title_img}/>
                            <span className={styles.card_title_content}>运维队伍</span>
                            <img src={require("../../../asset/home/card_top_left_img.png")} alt=""
                                 className={styles.card_title_img}/>
                        </div>
                        <div className={styles.left_top_card_content}>
                            <div className={styles.left_top_card_item}>
                                <img src={require("../../../asset/home/ops_card_department_num.png")} alt=""
                                     className={styles.ops_div_img}/>
                                <div className={styles.left_top_card_item_content}>
                                    <p className={`${styles.ops_num}`}>{this.state.organizationNumber}</p>
                                    <p className={`${styles.ops_describe}`}>服务机构数量</p>
                                </div>
                            </div>
                            <div className={styles.ops_line}/>
                            <div className={styles.left_top_card_item} style={{marginTop: "10px"}}>
                                <img src={require("../../../asset/home/ops_card_engineer_num.png")} alt=""
                                     className={styles.ops_div_img}/>
                                <div className={styles.left_top_card_item_content}>
                                    <p className={`${styles.ops_num}`}>{this.state.accountNumber}</p>
                                    <p className={`${styles.ops_describe}`}>服务工程师数</p>
                                </div>
                            </div>
                        </div>
                        <EdgeBorder/>
                    </div>
                    <img src={require("asset/home/storehouse_thumbnail.jpg")} alt="" className={`${styles.thumbnail} ${styles.storehouse_thumbnail}`}/>
                    <img src={require("asset/home/engineer_thumbnail.jpg")} alt="" className={`${styles.thumbnail} ${styles.engineer_thumbnail}`}/>
                </div>
            </div>
        </div>)
    }
}

export default withNavigation(HomePage);
