import React, {Component} from 'react';
import styles from "./index.module.css"
import defaultDepartmentLogo from "../../../../../asset/defaultDepartmentLogo.svg"
import {message} from "antd";

/**
 * @author       : AngelBeats
 * @date         : 2023-03-14 08:46:39
 * @description  : 机构列表
 */
export default class DepartmentList extends Component {

    /**
     * 查找机构在地图上位置
     * @param item
     */
    findAddressToMap = (item) => {
        if (!item.longitude || !item.latitude) {
            message.error("暂无该机构坐标信息!")
            return;
        }
        this.props.findAddress([item.longitude, item.latitude])
    }

    render() {
        return (<div className={styles.department_list_view}>
            {this.props.accountDepartmentList.map(item => <div className={styles.department_list_item} key={item.id}
                                                               onClick={() => this.findAddressToMap(item)}>
                <img src={item.logo ? item.logo : defaultDepartmentLogo} alt=""
                     className={styles.department_list_item_logo}/>
                <span className={styles.department_list_item_name}>{item.name}</span>
            </div>)}

        </div>)
    }
}
