import React, {Component} from "react";
import styles from "./index.module.css";
import PropTypes from "prop-types"
import * as echarts from "echarts";
class IssueDetailEcharts extends Component {

    faultChart;
    faultChartRef = React.createRef();
    state = {
        faultChance: []
    }

    componentDidMount() {
        this.init();
    }
    componentWillUnmount() {
        // 销毁图表实例
        this.faultChart.dispose(); // 销毁图表实例
    }
    /**
     * 获取设备故障类型数据
     * @param data
     */
    requestFaultChance = (name = null,data = null) => {

        this.setState({faultChance: []}, () => {
           if(data.length > 0){
               this.faultChart.setOption(this.pieOptions({
                   titTag: `${name ? name + "_" : ""}具体问题种类比例`,
                   tittip:"",
                   list:data
               }))
           }
            this.setState({faultChance: data})
        })
    }
    updateEcharts = (name,data) => {
        if(data.length > 0){
            this.requestFaultChance(name,data)
        }else {
            this.requestFaultChance(name,[])
        }

    }

    /**
     * 构造饼图图表配置项
     */
    pieOptions = (data) => (
        {
            color: ['#366fff','#ee6666', '#fac858', '#90ca74', '#d48265', '#91c7ae'],
            title : {
                text: data.titTag,
                x:"0%",
                y:'5%',
                textStyle:{
                    fontSize: 12,
                    fontWeight: 200,
                    color: '#fff'
                },
            },
            tooltip : {
                trigger: 'item',
                formatter: "{b} : {c} ({d}%)"
            },
            legend: {
                type: 'scroll',
                orient: 'vertical',
                left: 0,
                top: 45,
                bottom: 0,
                textStyle:{
                    fontWeight: 200,
                    fontSize: 12,
                    color: '#fff'
                },
                data:data.list.map(item => item.name)
            },
            calculable : true,
            series : [
                {
                    type:'pie',
                    clockWise:true,
                    startAngle: 135,
                    center: ['70%', '55%'],
                    radius : [30, 60],
                    // avoidLabelOverlap: false,
                    label: {
                        show: false,
                        position: 'center',
                        formatter: '{name|{b}}\n{d}%',
                        rich: {
                            num: {
                                fontSize: 12,
                                color: '#fff'
                            }
                        },
                        textStyle: {
                            color: '#fff',
                            shadowBlur: 0,
                            shadowColor: 'transparent',
                            shadowOffsetX: 0,
                            shadowOffsetY: 0
                        }
                    },
                    emphasis: {
                        label: {
                            show: true,
                            fontSize: 16,
                        }
                    },
                    labelLine: {
                        show: false
                    },
                    data:data.list.map(item=>{
                        return {
                            name: item.name,
                            value: item.num
                        }
                    }) || []
                }
            ]
        }
    )

    init = () => {
        // 初始化图表实例
        this.faultChart = echarts.init(this.faultChartRef.current);

    }
    render() {
        return (
            <div className={styles.text_num_pie_echarts_box}>
                { this.state.faultChance.length > 0 ?
                    <div ref={this.faultChartRef} className={styles.pie_echarts}/> : <div ref={this.faultChartRef} className={styles.no_pie_echarts}/>
                }
            </div>
        )
    }
}

export default IssueDetailEcharts;